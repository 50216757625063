import React, { useState, useEffect, useRef, useReducer } from "react";
import {Box,Button,Dialog,DialogActions,
        DialogContent,DialogTitle , Checkbox} from "@mui/material";

const DeleteResourceDialog = ({
  header,
  bodyMessage,
  onCancel,
  onSave
}) => {
  const [deleteAssignment, setDeleteAssignment] = useState(false);

  useEffect(() => {
  }, []);

  return (
    <Dialog
      open={true}
      onClose={onCancel}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        {header}
      </DialogTitle>
      <DialogContent dividers>
        <Box style={{display:'flex',gap:'1rem',flexDirection:'column'}}>
          <Box>
            {bodyMessage}
          </Box>
          <Box>
            <Checkbox
              onChange={(e) => {
                setDeleteAssignment(e.target.checked);
              }}
              checked={deleteAssignment}
              sx={{ padding: '2px' }}
            /><span style={{fontSize:'12px'}}>Delete resource from the project</span>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>{onCancel()}}>Cancel</Button>
        <Button variant="contained" onClick={()=>{
          //console.log('deleteAssignment ',deleteAssignment);
          onSave(deleteAssignment);
        }}>
          Delete Resource
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteResourceDialog;
