import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";

const UpdateStatus = ({ open, onClose, updateReport }) => {
  const [reason, setReason] = useState("");
  const [error, setError] = useState("");

  const handleUpdate = () => {
    if (!reason) {
      setError("Complete this field");
      return;
    }
    updateReport(reason);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Update Daily Report</DialogTitle>
      <DialogContent>
        <Grid container gap={1} sx={{ width: "500px", padding: 1 }}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="status">Status</InputLabel>
              <Select labelId="status" value="Draft" label="Status" disabled>
                <MenuItem value="Draft">Draft</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="reason"
              label="Exception Reason"
              placeholder="Enter Reason"
              multiline
              maxRows={6}
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
                setError("");
              }}
              fullWidth
              helperText={error !== "" ? error: ""}
              error={error !== "" ? true: false}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" onClick={handleUpdate}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateStatus;
