import React from "react";
import { Droppable } from "react-beautiful-dnd";

const grid = 24;
const getListStyle = (isDraggingOver , additionalStyle) => ({
  background: isDraggingOver ? "lightblue" : "",
  padding: grid,
  maxWidth: "300px",
  minWidth: "300px",...( additionalStyle ? additionalStyle : {})
});

const DroppableBox = ({ id, children , additionalStyle}) => {
  return (
    <Droppable droppableId={id}>
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          style={getListStyle(snapshot.isDraggingOver , additionalStyle)}
          className="drop-resource-area-wrapper"
        >
          {children}
          {/* <div className="drop-resource-area">Drag and drop resource here</div> */}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default DroppableBox;
