import AppRoutes from './Routing/Routes';
import './App.css';
// import '@bryntum/gantt/gantt.material.css';

function App() {
  return (
    <>
      <AppRoutes />
    </>
  );
}

export default App;
