import React from "react";
import PropTypes from "prop-types";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const MuiSelect = ({ id, name, label, value, handleChange, options }) => {
  return (
    <FormControl fullWidth size="small" className="search-field">
      <InputLabel id="simple-select">{label}</InputLabel>
      <Select
        labelId="simple-select"
        id={id}
        name={name}
        value={value}
        label={label}
        placeholder="select value"
        onChange={handleChange}
      >
        <MenuItem value="">All</MenuItem>
        {options.map((o) => (
          <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

MuiSelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  options: PropTypes.array,
};

MuiSelect.defaultProps = {
  label: 'Select',
  value: '',
  handleChange: () => {},
  options: []
};

export default MuiSelect;
