import { DragHelper, StringHelper, DateHelper } from "@bryntum/gantt";

// Handles dragging unscheduled appointment from the grid onto the schedule
class Drag extends DragHelper {
  static get defaultConfig() {
    return {
      callOnFunctions: true,
      autoSizeClonedTarget: false,
      unifiedProxy: true,

      // Prevent removing proxy on drop, we adopt it for usage in the Schedule
      removeProxyAfterDrop: true,

      // Don't drag the actual row element, clone it
      cloneTarget: true,
      mode: "translateXY",
      // Only allow drops on the schedule area
      dropTargetSelector: ".lh-task-dropable-td",
      // Only allow drag of row elements inside on the unplanned grid
      targetSelector: ".leaf-drag",
    };
  }

  afterConstruct(config) {
    // Configure DragHelper with schedule's scrollManager to allow scrolling while dragging
    // this.scrollManager = this.schedule.scrollManager;
  }

  createProxy(grabbedElement, initialXY) {
    const { context, schedule, grid } = this,
      draggedAppointment = grid.getRecordFromElement(grabbedElement),
      durationInPixels = 150,
      proxy = document.createElement("div");

    proxy.style.cssText = "";

    proxy.style.width = `${durationInPixels}px`;
    proxy.style.height ="50px";

    // Fake an event bar
    proxy.classList.add(
      "b-sch-event-wrap",
      "b-sch-style-border",
      "b-unassigned-class",
      "b-sch-horizontal"
    );
    proxy.innerHTML = StringHelper.xss`
          <div class="b-sch-event b-has-content b-sch-event-withicon">
              <div class="b-sch-event-content">
                  <div>
                      <div>${draggedAppointment.name}</div>
                  </div>
              </div>
          </div>
      `;

    return proxy;
  }

  onDragStart({ context }) {
    const me = this,
      { grid } = me,
      { selectedRecords, store } = grid,
      appointment = grid.getRecordFromElement(context.grabbed);

    // save a reference to the appointments being dragged so we can access them later
    context.appointments = selectedRecords.slice();
    context.relatedElements = selectedRecords
      .sort((r1, r2) => store.indexOf(r1) - store.indexOf(r2))
      .map(
        (rec) => rec !== appointment && grid.rowManager.getRowFor(rec).element
      )
      .filter((el) => el);

    // schedule.enableScrollingCloseToEdges(schedule.timeAxisSubGrid);

    // Prevent tooltips from showing while dragging
    // schedule.features.eventTooltip.disabled = true;
  }
  // onDrag({ context }) {
  //   const { schedule } = this,
  //     { appointments, totalDuration } = context,
  //     // requiredRole = appointments[0].requiredRole,
  //     newStartDate = schedule.getDateFromCoordinate(
  //       context.newX,
  //       "round",
  //       false
  //     ),
  //     lastAppointmentEndDate =
  //       newStartDate &&
  //       DateHelper.add(
  //         newStartDate,
  //         totalDuration,
  //         appointments[0].durationUnit
  //       ),
  //     resourceDrop = context.target && schedule.resolveResourceRecord(context.target),
  //     calendar = resourceDrop?.calendar;

  //   // Only allow drops on the timeaxis
  //   context.valid =
  //     newStartDate &&
  //     // Ensure we don't break allowOverlap config
  //     (schedule.allowOverlap ||
  //       (schedule.isDateRangeAvailable(
  //         newStartDate,
  //         lastAppointmentEndDate,
  //         null,
  //         resourceDrop
  //       ) &&
  //         // Respect resourceDrop's working time
  //         (!calendar ||
  //           calendar.isWorkingTime(
  //             newStartDate,
  //             lastAppointmentEndDate,
  //             true
  //           ))));

  //   // Save reference to the resource so we can use it in onAppointmentDrop
  //   context.resourceDrop = resourceDrop;
  // }

  // Drop callback after a mouse up, take action and transfer the unplanned appointment to the real EventStore (if it's valid)
  // async onDrop({ context }) {
    // const me = this,
    //   { schedule } = me;

    //   console.log('*********** on drop task ', context);

    // // If drop was done in a valid location, set the startDate and transfer the task to the Scheduler event store
    // if (context.valid) {
    //   const { appointments, element, relatedElements = [], resourceDrop } = context,
    //     coordinate = DomHelper.getTranslateX(element),
    //     dropDate = schedule.getDateFromCoordinate(coordinate, "round", false),
    //     eventBarEls = [element, ...relatedElements];

    //     console.log('process drop here appointments*** ', appointments);

    //   // for (let i = 0; i < appointments.length; i++) {
    //     // We hand over the data + existing element to the Scheduler so it do the scheduling
    //     // await is used so that we have a reliable end date in the case of multiple event drag
    //     await schedule.scheduleEvent({
    //       eventRecord: appointments[0],
    //       // When dragging multiple appointments, add them back to back + assign to resource
    //       startDate: dropDate,
    //       // Assign to the doctor (resource) it was dropped on
    //       resourceRecord: resourceDrop,
    //       element: eventBarEls[0],
    //     });
    //   // }
    // }

    // schedule.disableScrollingCloseToEdges(schedule.timeAxisSubGrid);
    // schedule.features.eventTooltip.disabled = false;
  // }
}


// const handleDrop = async(contextObj) => {
//   console.log('Handle drop inside component', contextObj);
//   const { context } = contextObj;
//   if (context.valid) {
//     const { appointments, element, relatedElements = [], resourceDrop } = context;
//     const coordinate = DomHelper.getTranslateX(element);
//     const dropDate = scheduleRef.current.getDateFromCoordinate(coordinate, "round", false);
//     const eventBarEls = [element, ...relatedElements];

//     // api call
//     const saveObj = {
//       recObj: {
//         Date__c: new Date(dropDate).toUTCString(),
//         Contact__c: resourceDrop.id,
//         Scheduled_Hours__c: 8,
//         WBS__c: appointments[0].id
//       },
//     };

//     const queryData = `?token=${userData?.token}&instanceUrl=${userData?.instanceUrl}`;

//     try {
//       setLoading(true);
//       const saveRes = await saveSchedule(queryData, saveObj);
//       setLoading(false);
//       if (saveRes.status === 200) {
//         await scheduleRef.current.scheduleEvent({
//           eventRecord: appointments[0],
//           startDate: dropDate,
//           resourceRecord: resourceDrop,
//           element: eventBarEls[0],
//         });
//       }
//     } catch (error) {
//       setLoading(false);
//     }
//   }
// };

export { Drag };

