import React from "react";
import { useSelector } from "react-redux";

import GanttChart from "../Gantt";

const Home = (props) => {
  const {userData = {}} = useSelector((state) => state.userData);
  if(userData && userData.instanceUrl && userData.token && userData.projectId) {
    return <GanttChart />
  }
  return <div>Authenticating...</div>;
};

export default Home;
