import { Column } from "@bryntum/gantt";

export class ActualHoursColumn extends Column {

  static get $name() {
    return "ActualHours";
  }

  static get type() {
    return "actualHoursColumn";
  }

  static get isGanttColumn() {
    return true;
  }

  static get defaults() {
    return {
      // Set your default instance config properties here
      field: "Actual_Hours__c",
      text: "Actual Hours",
      editor: false,
      readOnly: true,
      cellCls: "b-actual-hours-column-cell",
      // htmlEncode: false,
    };
  }

  renderer(data) {
    const { record } = data;
    return record
      ? `${record.Actual_Hours__c || 0} hours`
      : "";
  }
}
