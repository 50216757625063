import { Column } from "@bryntum/gantt";

export class UtilizationColumn extends Column {

  static get $name() {
    return "Utilization";
  }

  static get type() {
    return "utilizationColumn";
  }

  static get isGanttColumn() {
    return true;
  }

  static get defaults() {
    return {
      // Set your default instance config properties here
      field: "Utilization__c",
      text: "Utilization",
      editor: false,
      readOnly: true,
      cellCls: "b-actual-utilization-column-cell",
      // htmlEncode: false,
    };
  }

  renderer(data) {
    const { record } = data;

    return record
      ? `${record.Utilization__c} %`
      : "";
  }
}
