import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { find, uniqBy, sortBy } from "lodash";
import axios from "axios";
import { fetchUrl } from "../../Urls";

const CreateCrew = ({ resourcesData, crewGroups, userData, setLoading, updateCrewGroup, openAddCrew, setOpenAddCrew, editCrew, setEditCrew }) => {
  // const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [accountable, setAccountable] = useState('');
  const [errorText, setErrorText] = useState('');
  const [errorTextAcc, setErrorTextAcc] = useState('');

  const handleOpen = () => {
    setOpenAddCrew(true);
  };
  const handleClose = () => {
    setOpenAddCrew(false);
    setName('');
    setAccountable("");
    setEditCrew(null);
  };

  const _saveCrew = async (dataObj) => {
    const reqBody = { recObj: { ...dataObj , Project__c : userData?.projectId} };
    setLoading(true);
    await axios
      .post(
        `${fetchUrl}/recSave/Crew__c?token=${userData?.token}&instanceUrl=${userData?.instanceUrl}`,
        reqBody
      )
      .then((res) => {
        setLoading(false);
        if (res.data.status === 200) {
          handleClose();
          updateCrewGroup({ label: dataObj.Name, id: res.data.retObj.id, value: res.data.retObj.id,accountable: res.data.Accountable_Resource__c });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleSave = () => {
    if(!name) {
      setErrorText("Please enter crew name.");
      return;
    }
    if(!accountable) {
      setErrorTextAcc("Please select accountable resource.");
      return;
    }
    if (editCrew) {
      // update existing
      _saveCrew({ Name: name, Id: editCrew.id, Accountable_Resource__c: accountable });
    } else {
      const findCrew = find(crewGroups, { label: name});
      if (findCrew) {
        setErrorText("Crew already exists.");
      } else {
        setErrorText("");
        _saveCrew({ Name: name, Accountable_Resource__c: accountable });
        handleClose();
      }
    }
  };

  useEffect(() => {
    if (editCrew) {
      setName(editCrew.label);
      setAccountable(editCrew.accountable);
    }
  }, [editCrew])

  const resourcesOptions = uniqBy(Object.values(resourcesData).map(a => a).flat(), "Contact__c");
  const sortedOptions = sortBy(resourcesOptions, ["Contact__r.Name"]);
  

  return (
    <div>
      <Button variant="outlined" startIcon={<AddIcon />} onClick={handleOpen}>
        Create new crew
      </Button>
      <Dialog open={openAddCrew} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{editCrew ? "Edit Crew" : "Create Crew"}</DialogTitle>
        <DialogContent>
          <Grid container item gap={2}>
            <TextField
              autoFocus
              margin="dense"
              id="crewName"
              label="Crew Name"
              fullWidth
              variant="standard"
              value={name}
              onChange={(e) => setName(e.target.value)}
              helperText={errorText}
              required
              error={errorText}
            />
            <FormControl fullWidth>
              <InputLabel id="acr">Accountable Resource</InputLabel>
              <Select
                labelId="acr"
                id="acr-select"
                label="Accountable Resource"
                value={accountable}
                onChange={(e) => {
                  setErrorTextAcc("");
                  setAccountable(e.target.value);
                }}
                error={errorTextAcc}
                required
              >
                {sortedOptions.map((r) => (
                  <MenuItem key={r.Contact__c} value={r.Contact__c}>
                    {r.Contact__r.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateCrew;
