import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tasks: [],
};

export const ganttDataSlice = createSlice({
  name: 'ganttData',
  initialState,
  reducers: {
    setTasksData: (state, action) => {
      state.tasks = action.payload;
    },
    resetTaskData: (state) => {
      state.tasks = [];
    },
  }
});

export const { setTasksData, resetTaskData } = ganttDataSlice.actions;

export default ganttDataSlice.reducer;
