import { CycleResolutionPopup } from "@bryntum/gantt";

export class CustomCycleResolutionPopup extends CycleResolutionPopup {
  getResolutions() {
      let res = this.schedulingIssue?.getResolutions(); // this will return the resolutions
      console.log("res *** ", res);
      if (res !== undefined && res.length > 0) {
          // return only first option. remove dependency
          res = [res[0]];
      }
      // according to this, it will show the radio buttons
      return res;
  }
}