import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { DialogTitle, DialogContent, DialogContentText, Box, Alert, DialogActions } from "@mui/material";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import { filter } from "lodash";

const CloneReport = ({ reports, selectedReport, handleCloneReport,syncTimerOpen }) => {
  const [open, setOpen] = React.useState(false);
  const [date, setDate] = React.useState(moment(new Date()));
  const [error, setError] = React.useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleSetDate = e => {
    setError("");
    setDate(e);
  };

  const createReport = () => {
    const filterReport = filter(reports, (r) =>
      moment(moment(r.Date__c).format("YYYY-MM-DD")).isSame(
        date.format("YYYY-MM-DD")
      )
    );
    if (filterReport.length === 0) {
      // handle clone 
      // reload new report
      handleCloneReport(date, selectedReport.Id);
      handleClose();
    } else {
      setError("Report for selected date already exists.");
    }
  };

  return (
    <>
      <Button variant="contained"  disabled= {syncTimerOpen > 0} onClick={() => {setOpen(true)}}>
        Clone
      </Button>
      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle>{`Clone Report ${selectedReport?.Name}`}</DialogTitle>
          <DialogContent dividers>
            <DialogContentText gutterBottom>Select Date</DialogContentText>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker value={date} onChange={handleSetDate} fullWidth />
            </LocalizationProvider>
            {/* <Box mt={2}>{moment(date).format("dddd, YYYY-MM-DD")}</Box> */}
            <Box mt={2}>{error && <Alert severity="error">{error}</Alert>}</Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant="contained" onClick={createReport}>
              Clone
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default CloneReport;
