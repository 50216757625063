import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  wbsData: [],
};

export const wbsDataSlice = createSlice({
  name: 'wbsData',
  initialState,
  reducers: {
    setWbsData: (state, action) => {
      state.wbsData = action.payload;
    }, 
  }
});

export const { setWbsData } = wbsDataSlice.actions;

export default wbsDataSlice.reducer;
