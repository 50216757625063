import { Column, Combo } from "@bryntum/gantt";

export class AccountableResourceColumn extends Column {

  static get $name() {
    return "AccountableResource";
  }

  static get type() {
    return "accountableResourceColumn";
  }

  static get isGanttColumn() {
    return true;
  }

  static get defaults() {
    return {
      // Set your default instance config properties here
      field: "Accountable_Resource__c",
      text: "Accountable Resource",
      // editor: false,
      // readOnly: true, // For FillHandle etc.
      cellCls: "b-accountable-column-cell",
      htmlEncode: false,
      editor: new Combo({
        valueField: "id",
        displayField: "name",
        items: [],
      }),
    };
  }

  renderer(data) {
    const { record, grid } = data;
    const { store: { resourceStore } } = grid;
    const recId = record.Accountable_Resource__c;
    // const { store } = data.column.editor;
    const recData = resourceStore.getById(recId);

    return recData
      ? [
          {
            tag: "span",
            className: `custom-${recData.name}`,
          },
          recData.name,
        ]
      : "";
  }
}
