import {  DateHelper } from "@bryntum/gantt";

export const schedulerConfig = {
  flex: "1 1 300px",

  startDate: DateHelper.parse(new Date(), "YYYY-MM-DD"),
  endDate: DateHelper.add(
    DateHelper.parse(new Date(), "YYYY-MM-DD"),
    14,
    "days"
  ),
  viewPreset: "dayAndMonth",

  barMargin: 10,

  stripeFeature: true,
  sortFeature: true,

  eventDragFeature: {
    disabled: true,
    // constrainDragToTimeline: false,
  },
  eventResizeFeature: {
    disabled: true,
  },

  // columns: [
  //   {
  //     type: "resourceInfo",
  //     text: "New York office",
  //     width: "15em",
  //   },
  // ],
  columns: [],
  resources: [],
  events: [],
};
