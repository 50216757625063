import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Grid,
  CircularProgress,
  Box,
  Button,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputLabel,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import PrintIcon from "@mui/icons-material/Print";
import {
  Store,
  DateHelper,
  StringHelper,
  DragHelper,
  WidgetHelper,
  Wbs,
} from "@bryntum/gantt";

import { UnplannedGrid } from "./UnplannedGrid";
import { Drag } from "./DragHelper";
import { fetchBaseVFTemplateUrl, fetchMetadataValueByName } from "api";
import {
  processResources,
  processEvents,
  processAssignments,
  findDropParentNode,
  findDropAssignment,
  checkIsWeekendAvailable,
} from "./helpers";
import MuiDialog from "../../components/dialog/MuiDialog";
import DeleteResourceDialog from "./DeleteResourceDialog";

import "./lookahead.scss";

import {
  fetchLookaheadData,
  saveAssignmentsDataList,
  deleteAsignments,
  fetchTags,
  saveTagsList,
  fetchWBSData,
  deleteProjectAsignments,
  fetchHolidays,
  addProjResource,
  fetchCrews,
} from "./api/lookaheadApis";

import AssignmentsTable from "./AssignmentsTable";
import moment from "moment";
import { cloneDeep, difference, filter, find, isEmpty, uniq } from "lodash";
import SaveTimer from "components/SaveTimer";
import {
  setResourcesData,
  setAssignmentsData,
  setCompletedDates,
  resetState,
} from "./services/LookaheadDataSlice";
import { setWbsData } from "./services/WbsDataSlice";
import DeleteAssignmentDialog from "./DeleteAssignmentDialog";
import CreateTask from "./CreateTask";

const toDeleteResourceBody = `Are you sure you want to delete <Resource name> from the daily report? When deleted, <Resource name> will be remove from your daily report.`;

const Lookahead = ({
  isDailyReportView = false,
  isReadOnly = false,
  reportDate,
  reportId,
  reloadReport = false,
  reportStatus = "",
  setSyncTimerOpenForImport,
  ...props
}) => {
  const { toSaveEnabled, saveAllButtonRef } = props;
  const { userData = {} } = useSelector((state) => state.userData);
  const { resources, assignments, crews } = useSelector(
    (state) => state.lookaheadData
  );
  const resources_clone = useRef([]);
  useEffect(() => {
    resources_clone.current = resources;
  }, [resources]);

  const { wbsData } = useSelector((state) => state.wbsData);
  const { holidays, completedDates } = useSelector(
    (state) => state.lookaheadData
  );
  const holidays_clone = useRef([]);
  useEffect(() => {
    holidays_clone.current = holidays;
  }, [holidays]);

  const completedDates_clone = useRef([]);
  useEffect(() => {
    completedDates_clone.current = completedDates;
  }, [completedDates]);

  const lookaheadDispatch = useDispatch();
  // defualt today
  const initialDate = isDailyReportView ? reportDate : new Date();
  const initialTimeline = isDailyReportView ? 1 : 7;
  const [dateToApply, setDateToApply] = useState(moment(initialDate));
  const [timeLine, setTimeLine] = useState(initialTimeline);
  const [allTasksToggle, setAllTasksToggle] = useState(true);

  const [loading, setLoading] = useState(false);
  const [tagsData, setTagsData] = useState([]);

  const tmpAssignRef = useRef(null);
  const tmpAssignListRef = useRef([]);
  const rawWbsDataRef = useRef([]);
  const [openDialog, setOpenDialog] = useState(false);

  const [syncTimerOpen, setSyncTimerOpen] = useState(0);

  const dragRefGrid = useRef(null);
  const dragRefContainer = useRef(null);

  const [doDeleteResource, setToDeleteResource] = useState(null);
  const [doDeleteDialog, setToDeleteDialog] = useState(null);
  const toDeleteResourceAssignments = useRef(false);

  const [toDeleteAssignmentId, setToDeleteAssignmentId] = useState("");
  const [isReplaceOp, setIsReplaceOp] = useState(false);

  const [showWeekend, setShowWeekend] = useState(false);

  const crewSelectRef = useRef("");

  const [selectedRecord, setSelectedRecord] = useState(null);
  const [vfBaseUrl, setVFBaseUrl] = useState(null);

  const handleSetDate = (e) => {
    // setDateToApply(moment(e).format("YYYY-MM-DD"));
    setDateToApply(e);
  };

  useEffect(() => {
    if(setSyncTimerOpenForImport){
    setSyncTimerOpenForImport(syncTimerOpen);
    }
  }, [syncTimerOpen]);

  useEffect(() => {
     setDateToApply(reportDate);
  }, [reportDate]);


  const handleTimerClose = async () => {
    setSyncTimerOpen(0);
    // call save function
    await handleSaveAllChanges(assignments);
  };

  // const scheduleRef = useRef(null);
  const gridRef = useRef(null);

  const getTags = async (projectId) => {
    try {
      const res = await fetchTags(projectId);
      let tagsDataa = res.tags.map((t) => t.Name);
      //console.log('tagsDataa ',JSON.stringify(tagsDataa));
      setTagsData(tagsDataa);
    } catch (error) {
      setLoading(false);
    }
  };

  const getHolidays = async () => {
    try {
      const res = await fetchHolidays();
      if (res.status && res.data?.length > 0) {
        lookaheadDispatch(
          setAssignmentsData({ type: "HOLIDAYS", data: res.data })
        );
      }
    } catch (error) {}
  };

  const getCrews = async () => {
    try {
      const res = await fetchCrews(userData.projectId);
      if (res.status && res.crewOptions?.length > 0) {
        lookaheadDispatch(
          setAssignmentsData({ type: "CREWS", data: res.crewOptions })
        );
      }
    } catch (error) {}
  };
  const getVFBaseUrl = async () => {
    try {
      let url = await fetchBaseVFTemplateUrl();
      const tempName = await fetchMetadataValueByName('Lookahead_Template_Name');
      if(url && tempName){
        setVFBaseUrl(`${url}${tempName}`);
      }
     } catch (error) {}
  };

  const getLookaheadData = async (projectId, rangeVal = 1) => {
    try {
      lookaheadDispatch(resetState());
      //console.log('isDailyReportView',isDailyReportView,'reportDate',reportDate,'initialDate',initialDate,'dateToApply',dateToApply);
      setLoading(true);
      toDeleteResourceAssignments.current = null;
      // const startDate = DateHelper.parse(new Date(dateToApply), "YYYY-MM-DD");
      const currentDate =
        isDailyReportView || timeLine === 1
          ? moment(new Date()).format("YYYY-MM-DD")
          : moment(new Date()).weekday(1).format("YYYY-MM-DD");
      //console.log('currentDate :: ',currentDate);
      const startDate =
        isDailyReportView || timeLine === 1
          ? moment(dateToApply).format("YYYY-MM-DD")
          : moment(dateToApply).weekday(1).format("YYYY-MM-DD");
      //console.log('startDate :: ',startDate);
      const endDate =
        isDailyReportView || timeLine === 1
          ? startDate
          : DateHelper.add(
              DateHelper.parse(new Date(startDate), "YYYY-MM-DD"),
              (timeLine - 1),
              "days"
            );
      const reqBody = {
        startDate,
        endDate,
        reportId,
        applyDateToWBS: false,
      };
      //console.log('reqBody',JSON.stringify(reqBody));
      const schData = await fetchLookaheadData(projectId, reqBody);
      setLoading(false);
      let addAssignments = moment(startDate).isSameOrAfter(currentDate, "day");
      if (reportStatus && reportStatus === "Draft") {
        addAssignments = true;
      }
      //console.log('staraddAssignmentstDate :: ',addAssignments);
      const resResources = processResources(
        schData.createdResources,
        schData.timecardMap,
        schData.tasks,
        isDailyReportView,
        addAssignments
      );
      lookaheadDispatch(setResourcesData(resResources));
      setTimeout(() => {
        const assignents = processAssignments(schData.tasks);
        // get if weekend task is there
        const isWeekendExists = checkIsWeekendAvailable(assignents);
        if (isWeekendExists) {
          setShowWeekend(true);
        }
        lookaheadDispatch(
          setAssignmentsData({ type: "LOAD", data: assignents })
        );
      }, 100);
      // completed dates
      const comList = schData.completedDailyReportDates.records.map(
        (r) => r.Date__c
      );
      lookaheadDispatch(setCompletedDates([...comList]));
    } catch (error) {
      setLoading(false);
    }
  };
  const getGanttData = async (projectId) => {
    if (wbsData.length > 0) {
      const events = processEvents(wbsData);
      setDataToGrid(events);
    } else {
      try {
        //console.log('getGanttData',isDailyReportView,'reportDate',reportDate,'initialDate',initialDate,'dateToApply',dateToApply);
        const startDate =
          isDailyReportView || timeLine === 1
            ? dateToApply
            : moment(dateToApply).weekday(1).format("YYYY-MM-DD");
        const endDate =
          isDailyReportView || timeLine === 1
            ? startDate
            : DateHelper.add(
                DateHelper.parse(new Date(startDate), "YYYY-MM-DD"),
                timeLine,
                "days"
              );
        const reqBody = {
          startDate,
          endDate,
          applyDateToWBS: false,
          volume: "min", // to get minimum wbs data
        };
        setLoading(true);
        const wbsDataRes = await fetchWBSData(projectId, reqBody);
        rawWbsDataRef.current = wbsDataRes.wbsRecords;
        lookaheadDispatch(setWbsData(wbsDataRes.wbsRecords));
        const events = processEvents(wbsDataRes.wbsRecords);
        setDataToGrid(events);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const timelineAction = async (action, event = null) => {
    if (syncTimerOpen > 0) {
      await handleTimerClose();
      action(event);
    } else {
      action(event);
    }
  };

  const handleWeekChange = (e) => {
        setTimeLine(e.target.value);
  };

  const onTodayClick = () => {
    setDateToApply(moment());
  };

  const handleLeftDateClick = () => {
    const daysToMove = (timeLine === 14 || timeLine === 21) ? 7 : timeLine;
    setDateToApply((pre) => moment(pre).subtract(daysToMove, "days"));
  };
  const handleRightDateClick = () => {
    const daysToMove = (timeLine === 14 || timeLine === 21) ? 7 : timeLine;
    setDateToApply((pre) => moment(pre).add(daysToMove, "days"));
  };

  const handleAllTaskToggle = (value) => {
    setAllTasksToggle(value);
  };

  const handleAddProjResource = async (data) => {
    let crew = null;
    if (crewSelectRef.current.value) {
      crew = crewSelectRef.current.value;
    }
    setLoading(true);
    const reqBody = {
      recLists: [
        {
          Contact__c: data.Id,
          Project__c: userData.projectId,
          Crew__c: crew,
        },
      ],
    };

    const res = await addProjResource(reqBody);
    if (res?.status === 200) {
      setLoading(false);
      getLookaheadData(userData.projectId);
      closeDialog();
    }
  };

  const handleAddProjResourceConfirm = (data) => {
    const addBody = (
      <Grid container gap={2}>
        <Grid
          item
          xs={12}
        >{`Do you want to add "${data.Name}" to your project?`}</Grid>
        <Grid item xs={12}>
          Please select a crew if you want to add him on an existing crew or
          leave it blank if the resource doesn’t belong to any crew.
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="crew">Crew</InputLabel>
            <Select
              labelId="crew"
              id="crew-select"
              label="Age"
              inputRef={crewSelectRef}
              defaultValue=""
            >
              {crews.map((c) => (
                <MenuItem key={c.id} value={c.value}>
                  {c.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
    const actionsButtons = (
      <>
        <Button variant="text" onClick={() => closeDialog()}>
          Cancel
        </Button>
        <Button variant="contained" onClick={() => handleAddProjResource(data)}>
          Add
        </Button>
      </>
    );
    // tuples info [0:resObj, 1:null, 2:title, 3:body, 4:action]
    tmpAssignRef.current = [
      data,
      null,
      "Add Resource",
      addBody,
      actionsButtons,
    ];
    setOpenDialog(true);
  };

  const updateAssignments = (obj) => {
    // check already assigned tasks on the same date
    const filterAssigned = filter(
      tmpAssignListRef.current,
      (a) =>
        a.startDate === obj.startDate &&
        a.resourceId === obj.resourceId &&
        !a.deleted &&
        !a.isDeleted &&
        !a.rDeleted
    );
    if (filterAssigned.length > 0) {
      const duration = Number((8 / (filterAssigned.length + 1)).toFixed(1));
      lookaheadDispatch(
        setAssignmentsData({ type: "UPDATE", data: { ...obj, duration } })
      );
      filterAssigned.forEach((f) => {
        lookaheadDispatch(
          setAssignmentsData({
            type: "UPDATE_DURATION",
            data: { assignId: f.id, duration },
          })
        );
      });
    } else {
      lookaheadDispatch(setAssignmentsData({ type: "UPDATE", data: obj }));
    }
    setSyncTimerOpen((prev) => prev + 1);
  };

  const updateDurationVal = (obj) => {
    lookaheadDispatch(
      setAssignmentsData({
        type: "UPDATE_DURATION",
        data: { assignId: obj.id, duration: obj.duration },
      })
    );
    setSyncTimerOpen((prev) => prev + 1);
  };

  const updateNotesVal = (obj) => {
    lookaheadDispatch(
      setAssignmentsData({
        type: "UPDATE_NOTES",
        data: { assignId: obj.id, notes: obj.notes },
      })
    );
    setSyncTimerOpen((prev) => prev + 1);
  };

  const removeGhostNodes = () => {
    const ghosts = document.querySelectorAll(".b-unassigned-class");
    if (ghosts.length > 0) {
      ghosts.forEach((g) => g.remove());
    }
  };

  const closeDialog = () => {
    tmpAssignRef.current = null;
    setOpenDialog(false);
  };

  const actionDialogCb = (selection) => {
    if (selection === "REPLACE") {
      if (
        isDailyReportView &&
        !tmpAssignRef.current[1].id.includes("unsaved")
      ) {
        // ask for variance
        setToDeleteAssignmentId(tmpAssignRef.current[1].id);
        setIsReplaceOp(true);
        setOpenDialog(false);
      } else {
        lookaheadDispatch(
          setAssignmentsData({
            type: "REPLACE",
            data: {
              ...tmpAssignRef.current[0],
              id: tmpAssignRef.current[1].id,
              key: tmpAssignRef.current[1].key,
            },
          })
        );
        setSyncTimerOpen((prev) => prev + 1);
        closeDialog();
      }
    } else {
      updateAssignments(tmpAssignRef.current[0]);
      closeDialog();
    }
  };

  const updateTasksFromCreate = (list = []) => {
    list.forEach((l) => {
      updateAssignments(l);
    });
    handleTaskCreateClose();
  };

  const getAssigned = (id) => {
    return find(tmpAssignListRef.current, { id });
  };

  const handleDrop = (data, container = false) => {
    const { context } = data;
    const { appointments, target } = context;
    if (target && appointments?.length > 0) {
      const dropTargetID = findDropParentNode(target);
      const dropTargetAssignment = findDropAssignment(target);
      if (dropTargetID) {
        const targetData = dropTargetID.split("_"); // 0>date, 1>resource
        const resId = targetData[1];
        const resRow = resources_clone.current?.find(
          (r) => r.id == resId
        );
        const dDate = moment(targetData[0]).format("MM-DD-YYYY");
        let dropAvailable = true;
        if(resId && resRow && dDate){
          let showTimeOff = checkIfDropAvailable(dDate, resRow);
          let isHoliday = checkHoliday(dDate);
          let isComplete = checkComplete(dDate);
          if(showTimeOff || isHoliday || isComplete){
            dropAvailable = false;
          }
        }
        if(dropAvailable == false || !resRow){
          return;
        }
        const tmp = {
          id: `unsaved-${StringHelper.generateUUID()}`,
          key: `uikey-${StringHelper.generateUUID()}`,
          parentId: appointments[0].parentId,
          eventId: container ? appointments[0].eventId : appointments[0].id,

          allocation1Id :appointments[0].allocation1Id,
          allocation1Name : appointments[0].allocation1Name,
          allocation2Id: appointments[0].allocation2Id,
          allocation2Name:appointments[0].allocation2Name,
          allocation3Id: appointments[0].allocation3Id,
          allocation3Name:appointments[0].allocation3Name,

          resourceId: targetData[1],
          startDate: dDate,
          duration: 8,
          durationUnit: "h",
          units: 100,
          tags: appointments[0]?.tags?.length > 0 ? appointments[0]?.tags : [],
          toSync: true,
          isNew: true,
        };
        if (!tmp.parentId) {
          // if parent is null than task itself is leaf
          tmp.parentId = tmp.eventId;
        }
        if (dropTargetAssignment) {
          const assignmentObj = getAssigned(dropTargetAssignment);
          if (assignmentObj) {
            const taskFromStoreData = gridRef.current.store.getById(
              assignmentObj.eventId
            );
            if (taskFromStoreData) {
              const actionBody = (
                <div className="lh-action-body">
                  Do you want to replace task{" "}
                  <span className="lh-action-body-act-parent">
                    {taskFromStoreData.parent.name}
                  </span>{" "}
                  <span className="lh-action-body-act-child">
                    {taskFromStoreData.name}
                  </span>
                  or <span className="lh-action-body-act">Add new</span> task?
                </div>
              );

              const actionsButtons = (
                <>
                  <Button
                    variant="contained"
                    onClick={() => actionDialogCb("REPLACE")}
                  >
                    Replace
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => actionDialogCb("NEW")}
                  >
                    Add new
                  </Button>
                </>
              );
              // tuples info [0:tmpTaskAssigment, 1:currentAssigned, 2:title, 3:body, 4:action]
              tmpAssignRef.current = [
                tmp,
                assignmentObj,
                "Action",
                actionBody,
                actionsButtons,
              ];
              setOpenDialog(true);
            }
          }
        } else {
          updateAssignments(tmp);
        }
      }
    }
    removeGhostNodes();
  };
  const checkIfDropAvailable = (date, resource) => {
    let showTimeOff = null;
    if (resource && resource.timeOffDates && resource.timeOffDates.length > 0) {
      showTimeOff = find(
        resource.timeOffDates,
        (tf) => tf.offDate === moment(date).format("YYYY-MM-DD")
      );
    }
    return showTimeOff;
  };

  const checkHoliday = (date) => {
    let showHoliday = find(
      holidays_clone.current,
      (h) => h.ActivityDate === moment(date).format("YYYY-MM-DD")
    );
    return showHoliday;
  };

  const checkComplete = (date) => {
    let complete = completedDates_clone.current?.includes(moment(date).format("YYYY-MM-DD"));
    return complete;
  };

  const handleContainerDrop = ({ context }) => {
    const { target } = context;
    if (target) {
      handleDrop({ context }, true);
    }
  };

  function handleSubtaskChange(newSubtask, id) {
    lookaheadDispatch(
      setAssignmentsData({
        type: "UPDATE_SUBTASK",
        data: { ...newSubtask, assignId: id },
      })
    );
    setSyncTimerOpen((prev) => prev + 1);
  }

  function handleAddTag(tag, id) {
    lookaheadDispatch(
      setAssignmentsData({ type: "UPDATE_TAG", data: { tag, assignId: id } })
    );
    setSyncTimerOpen((prev) => prev + 1);
  }

  function handleDeleteTag(index, id) {
    lookaheadDispatch(
      setAssignmentsData({ type: "DELETE_TAG", data: { index, assignId: id } })
    );
    setSyncTimerOpen((prev) => prev + 1);
  }

  function handleDeleteTask(id) {
    if (!isReadOnly) {
      // check for saved task and show confirmation
      if (isDailyReportView && !id.includes("unsaved")) {
        // show confirmation
        setToDeleteAssignmentId(id);
      } else {
        lookaheadDispatch(
          setAssignmentsData({
            type: "DELETE_ASSIGNMENT",
            data: { assignId: id },
          })
        );
        setSyncTimerOpen((prev) => prev + 1);
      }
      // setSyncTimerOpen((prev) => prev + 1);
    }
  }
  const handleDeleteWithVariance = (data) => {
    if (data.isReplaceOp) {
      lookaheadDispatch(
        setAssignmentsData({
          type: "REPLACE",
          data: {
            ...tmpAssignRef.current[0],
            id: tmpAssignRef.current[1].id,
            key: tmpAssignRef.current[1].key,
            Reason_for_Variance__c: data.Reason_for_Variance__c,
            Reason_for_Variance_Notes__c: data.Reason_for_Variance_Notes__c,
          },
        })
      );
      setSyncTimerOpen((prev) => prev + 1);
      setToDeleteAssignmentId("");
      setIsReplaceOp(false);
      tmpAssignRef.current = null;
    } else {
      lookaheadDispatch(
        setAssignmentsData({
          type: "DELETE_ASSIGNMENT",
          data: { ...data },
        })
      );
      setSyncTimerOpen((prev) => prev + 1);
      setToDeleteAssignmentId("");
    }
  };

  function handleDeleteResource(res) {
    if (!isReadOnly) {
      setToDeleteResource(res);
      let toDeleteBody = toDeleteResourceBody;
      while (toDeleteBody.includes("<Resource name>")) {
        toDeleteBody = toDeleteBody.replace("<Resource name>", res.Name);
      }
      setToDeleteDialog(toDeleteBody);
    }
  }

  const handleSyncNewTags = async (tagsList) => {
    const newTags = difference(tagsList, tagsData);
    if (newTags.length > 0) {
      const recLists = uniq(newTags).map((n) => ({
        Name: n,
        Project__c: userData.projectId,
      }));
      const queryData = `?token=${userData?.token}&instanceUrl=${userData?.instanceUrl}`;
      try {
        await saveTagsList(queryData, { recLists });
      } catch (error) {
        console.error("Save.tags error", error);
      }
    }
  };

  const handleSaveAllChanges = async (data) => {
    let isSucces = true;
    let toSyncList = data.filter((f) => f.toSync);
    console.log('pre toSyncList :: ',JSON.stringify(toSyncList));
    const deleteList = data
      .filter(
        (a) =>
          a.deleted && !a.id.includes("unsaved") && !a.isDeleted && !a.rDeleted
      )
      .map((m) => m.id); // !a.isDeleted added cuz if task is deleted on server that means task is synced
      console.log('deleteList :: ',JSON.stringify(deleteList));
    let refreshData = false;
    let deletedRes = {};
    let savedRes = {};
    //console.log('deleteList ',deleteList?.length);
    if (deleteList.length > 0) {
      if (isDailyReportView) {
        // marking task as isDeleted on server only when task is being deleted from dailyreport view
        deleteList.forEach((d) => {
          let s = cloneDeep(data.find((a) => a.id == d));
          if (!toSyncList) {
            toSyncList = [];
          }
          s.status = "Not Completed";
          s.isDeleted = true;
          toSyncList.push(s);
        });
      } else {
        // deleting tasks only when task is being deleted from lookahed view
        setLoading(true);
        deletedRes = await deleteAsignments(deleteList);
        refreshData = true;
      }
    }
    let tagsList = [];
    //console.log('toSyncList :: ',toSyncList.length);
    //console.log('post toSyncList :: ',JSON.stringify(toSyncList));
    if (toSyncList.length > 0) {
      setLoading(true);
      const recLists = [];
      toSyncList.forEach((s) => {
        const sobj = {
          Project__c: userData.projectId,
          Task_Date__c: moment(s.startDate).format("YYYY-MM-DD"),
          Contact__c: s.resourceId,
          Scheduled_Hours__c: s.isDeleted ? 0 : s.duration,
          WBS__c: s.eventId,
          Parent_WBS__c: s.parentId,
          Status__c: s.status,
          isDeleted__c: s.isDeleted ? true : false,
          Resource_Deleted__c: s.rDeleted ? true : false,
          Tags__c: s.tags.join(";"),
          key: s.key,
          Notes__c: s.notes || "",
          Allocation_1__c : s.allocation1Id,
          Allocation_1__r : {Name:s.allocation1Name},
          Allocation_2__c : s.allocation2Id,
          Allocation_2__r : {Name:s.allocation2Name},
          Allocation_3__c : s.allocation3Id,
          Allocation_3__r : {Name:s.allocation3Name},
        };
        if (!s.isNew) {
          sobj.Id = s.id;
        }
        if (sobj.Id && sobj.Id.includes("unsaved")) {
          sobj.Id = null;
        }
        if (
          sobj.isDeleted__c ||
          s.Reason_for_Variance__c ||
          s.Reason_for_Variance_Notes__c
        ) {
          sobj.Reason_for_Variance__c = s.Reason_for_Variance__c;
          sobj.Reason_for_Variance_Notes__c = s.Reason_for_Variance_Notes__c;
        }
        if (s.rDeleted || !sobj.isDeleted__c || sobj.Id) {
          // only pushin task which are deleted with sf id, skiping any task marked deleted without sf id
          recLists.push(sobj);
          tagsList = [...tagsList, ...s.tags];
        }
      });
      try {
        if (recLists?.length > 0) {
          savedRes = await saveAssignmentsDataList({ recLists });
          if (savedRes.status === 200) {
            refreshData = true;
          }
        }
        // handle Sync new tags
      } catch (error) {
        setLoading(false);
        isSucces = false;
      }
    }
    if (refreshData) {
      //getLookaheadData(userData.projectId);
      //console.log("**************** saved res", savedRes);
      lookaheadDispatch(
        setAssignmentsData({
          type: "REFRESH_SAVED",
          data: { savedRes: savedRes.retList, deletedRes: deletedRes },
        })
      );
      setLoading(false);
      if (tagsList?.length > 0) {
        handleSyncNewTags(tagsList);
      }
      console.log('toDeleteResourceAssignments.current ::',JSON.stringify(toDeleteResourceAssignments.current));
      if (toDeleteResourceAssignments.current?.length > 0) {
        await deleteProjectAsignments(
          toDeleteResourceAssignments.current,
          userData.projectId
        );
        toDeleteResourceAssignments.current = null;
      }
    } else {
      setLoading(false);
    }
    //console.log("**************** saved isSucces", isSucces);
    return isSucces;
  };

  const handleTaskCreate = ({ record }) => {
    console.log("handle add task", record);
    setSelectedRecord(record);
  };

  const handleTaskCreateClose = () => {
    setSelectedRecord(null);
  };

  const enableDragDrop = () => {
    // Handles dragging
    dragRefGrid.current = new Drag({
      grid: gridRef.current,
      constrain: false,
    });
    dragRefGrid.current.onDrop = handleDrop;
    // container drag
    dragRefContainer.current = new DragHelper({
      callOnFunctions: true,
      mode: "translateXY",
      cloneTarget: true,
      // containers : [ ".lh-grid-wrapper" ],
      dropTargetSelector: ".lh-task-dropable-td",
      targetSelector: ".lh-task-dropable-box",
    });
    dragRefContainer.current.onDragStart = ({ context }) => {
      const appoint = getAssigned(context.grabbed.id);
      context.appointments = [appoint];
    };
    dragRefContainer.current.onDrop = handleContainerDrop;
  };

  const initializeScheduler = (callback) => {
    // // Holds unplanned sessions, that can be dragged to the schedule
    gridRef.current = new UnplannedGrid({
      ref: "unplanned",
      // flex: "0 1 300px",
      appendTo: "lookahead",
      listeners: {
        selectionChange() {
          const { selectedRecords } = this;
          //console.log("selected records ", selectedRecords);
        },
        cellMenuBeforeShow({ items, record }) {
          if (record.children.length === 0) {
            items.removeRow = false;
            items.cut = false;
            items.copy = false;
            items.paste = false;
            items.createTask = {
              text: "Create Task",
              icon: "b-fa b-fa-plus",
              onItem: handleTaskCreate,
            };
          } else {
            return false;
          }
        },
      },
    });

    if (!isReadOnly) {
      enableDragDrop();
    }

    callback();
  };

  const setDataToGrid = (data) => {
    if (gridRef.current) {
      let tasks = new Store({
        tree: true,
        transformFlatData: true,
        useRawData: true,
        data: data,
      });
      tasks.sort((recordA, recordB) => {
        return Wbs.compare(recordA.wbs, recordB.wbs);
      });
      gridRef.current.data = [...tasks.toJSON()];
      //getLookaheadData(userData.projectId);
    }
  };

  const onInitializeComplete = () => {
    if (gridRef.current) {
      getGanttData(userData.projectId);
    }
  };

  useEffect(() => {
    //console.log("*** use effect:: handle date change data load");
    getLookaheadData(userData.projectId);
  }, [dateToApply, timeLine]);

  useEffect(() => {
    if(reloadReport) {
      getLookaheadData(userData.projectId);
    }
  }, [reloadReport]);
  

  useEffect(() => {
    if (
      userData &&
      userData.instanceUrl &&
      userData.token &&
      userData.projectId
    ) {
      getTags(userData.projectId);
      getHolidays();
      getCrews();
      getVFBaseUrl();
    }
  }, [userData]);

  useEffect(() => {
    initializeScheduler(onInitializeComplete);
    return () => {
      if (WidgetHelper.getById("filterByTimeline")) {
        WidgetHelper.getById("filterByTimeline").destroy();
      }
      if (syncTimerOpen > 0) {
        handleSaveAllChanges(assignments);
      }
    };
  }, []);

  useEffect(() => {
    tmpAssignListRef.current = assignments;
  });

  useEffect(() => {
    //setReadOnlyMode(isReadOnly);
    if (isReadOnly && dragRefGrid.current && dragRefContainer.current) {
      dragRefGrid.current.destroy();
      dragRefContainer.current.destroy();
      lookaheadDispatch(
        setAssignmentsData({ type: "LOAD", data: assignments })
      );
    }
    if (!isReadOnly && dragRefGrid.current && dragRefContainer.current) {
      dragRefGrid.current.destroy();
      dragRefContainer.current.destroy();
      lookaheadDispatch(
        setAssignmentsData({ type: "LOAD", data: assignments })
      );
      enableDragDrop();
    }
    return () => {
      if (dragRefGrid.current && dragRefContainer.current) {
        dragRefGrid.current.destroy();
        dragRefContainer.current.destroy();
      }
    };
  }, [isReadOnly]);

  useEffect(() => {
    console.log("show weekend is ", showWeekend);
  }, [showWeekend]);

  let tagsFromAssignments = [];
  assignments.forEach((a) => {
    tagsFromAssignments = [...tagsFromAssignments, ...a.tags];
  });

  const finalTagsList = uniq([...tagsFromAssignments, ...tagsData]);
  const processSaveBeforeComplete = async () => {
    let returval = true;
    //console.log('processSaveBeforeComplete syncTimerOpen :: ',syncTimerOpen);
    if (syncTimerOpen > 0) {
      returval = false;
      setSyncTimerOpen(0);
      returval = await handleSaveAllChanges(assignments);
    }
    return returval;
  };
  const showDeleteAssignmentDialog = toDeleteAssignmentId ? true : false;
  const closeDeleteAssignmentDialog = () => {
    setToDeleteAssignmentId("");
  };

  return (
    <div>
      {toSaveEnabled && isDailyReportView && (
        <div
          ref={toSaveEnabled}
          style={{ display: "none" }}
          onClick={async () => {
            saveAllButtonRef.current = processSaveBeforeComplete;
          }}
        ></div>
      )}
      {loading && (
        <div className="gantt-loading-state">
          <CircularProgress />
        </div>
      )}
      <Grid
        container
        className={
          isDailyReportView
            ? "lookahead-wrapper-daily-report"
            : "lookahead-wrapper"
        }
      >
        <Grid
          item
          xs={timeLine === 1 || isDailyReportView ? 10 : 10}
          id="grid-container"
        >
          <Grid container>
            {!isDailyReportView && (
              <Grid item xs={12} id="grid-toolbar" className="lh-toolbar">
                <Box>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={showWeekend}
                          onChange={(e) => setShowWeekend(e.target.checked)}
                          checked={showWeekend}
                        />
                      }
                      label="Show Weekend"
                    />
                  </FormGroup>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    margin: "0 8px",
                  }}
                >
                  <Button
                    variant="text"
                    onClick={() => timelineAction(onTodayClick)}
                  >
                    Today
                  </Button>

                   <IconButton
                    onClick={() => timelineAction(handleLeftDateClick)}
                  >
                    <ChevronLeftIcon />
                  </IconButton> 

                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      value={dateToApply}
                      onChange={(e) => timelineAction(handleSetDate, e)}
                      fullWidth
                      slotProps={{ textField: { size: "small" } }}
                    />
                  </LocalizationProvider>

                  <IconButton
                    onClick={() => timelineAction(handleRightDateClick)}
                  >
                    <ChevronRightIcon />
                  </IconButton>
                </Box>
                <Box>
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      value={timeLine}
                      // onChange={(e) => setTimeLine(e.target.value)}
                      onChange={(e) => timelineAction(handleWeekChange, e)}
                      displayEmpty
                      inputProps={{ "aria-label": "Select Timeline" }}
                      size="small"
                    >
                      <MenuItem value={1}>1 Day</MenuItem>
                      <MenuItem value={7}>1 Week</MenuItem>
                      <MenuItem value={14}>2 Week</MenuItem>
                      <MenuItem value={21}>3 Week</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <Tooltip title="Expand">
                    <IconButton onClick={() => handleAllTaskToggle(false)}>
                      <KeyboardDoubleArrowDownIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Collapse">
                    <IconButton onClick={() => handleAllTaskToggle(true)}>
                      <KeyboardDoubleArrowUpIcon />
                    </IconButton>
                  </Tooltip>
                  {vfBaseUrl && !(syncTimerOpen>0) &&
                    <Tooltip title="Print">
                      <IconButton onClick={() => {
                          //console.log("timeLine:",timeLine);
                          const startDate=moment(dateToApply).format("YYYY-MM-DD");
                          const weekSize=(timeLine === 1 || timeLine === 7) ? 1 : 2;
                          const docType='TasksToResources';
                          const params = new URLSearchParams();
                          params.append('projectId', userData.projectId);
                          params.append('weekSize',weekSize);
                          params.append('docType',docType);
                          params.append('startDate',startDate);
                          const baseUrl=new URL(vfBaseUrl);
                          baseUrl.search = params.toString();
                          let redUrl = baseUrl.toString();
                          //console.log("redUrl:",redUrl);
                          window.open(`${redUrl}`, "_blank");
                        }}>
                        <PrintIcon />
                      </IconButton>
                    </Tooltip>
                  }
                </Box>
              </Grid>
            )}

            <Grid
              item
              xs={12}
              id="lh-grid-wrapper"
              className={isDailyReportView ? "lh-grid-daily-report" : "lh-grid"}
            >
              <AssignmentsTable
                taskGridRef={gridRef.current}
                resources={resources}
                assignmentsData={assignments}
                dateToApply={dateToApply}
                timeLine={timeLine}
                tagsData={finalTagsList}
                allTasksToggle={allTasksToggle}
                isDailyReportView={isDailyReportView}
                isReadOnly={isReadOnly}
                showWeekend={showWeekend}
                handleSubtaskChange={handleSubtaskChange}
                handleAddTag={handleAddTag}
                handleDeleteTag={handleDeleteTag}
                handleDeleteTask={handleDeleteTask}
                handleDeleteResource={handleDeleteResource}
                updateDurationVal={updateDurationVal}
                updateNotesVal={updateNotesVal}
                handleAllTaskToggle={handleAllTaskToggle}
                handleAddProjResource={handleAddProjResourceConfirm}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={timeLine === 1 || isDailyReportView ? 2 : 2}
          id="tasks-tree-container"
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              height: `calc(100vh - 64px)`,
              maxHeight: `calc(100vh - 64px - ${
                isDailyReportView ? 138 : 0
              }px)`,
              borderLeft: "2px solid #888",
            }}
            id="lookahead"
          ></Grid>
        </Grid>
      </Grid>
      {syncTimerOpen > 0 && (
        <SaveTimer
          open={syncTimerOpen > 0}
          syncTimerOpen={syncTimerOpen}
          onCloseCallback={handleTimerClose}
        />
      )}
      {openDialog && (
        <MuiDialog
          open={openDialog}
          handleClose={closeDialog}
          title={tmpAssignRef.current[2]}
          actions={tmpAssignRef.current[4]}
        >
          {tmpAssignRef.current[3]}
        </MuiDialog>
      )}
      {doDeleteDialog && doDeleteResource && (
        <DeleteResourceDialog
          header="Delete Resource"
          bodyMessage={doDeleteDialog}
          onSave={(deleteAssignment) => {
            //console.log('deleteAssignment ',deleteAssignment);
            if (doDeleteResource?.Id) {
              if (deleteAssignment === true) {
                if (!toDeleteResourceAssignments.current) {
                  toDeleteResourceAssignments.current = [];
                }
                toDeleteResourceAssignments.current.push(doDeleteResource?.Id);
              }
              lookaheadDispatch(
                setAssignmentsData({
                  type: "DELETE_RESOURCE",
                  data: {
                    resourceId: doDeleteResource?.Id,
                    dailyReportDate: dateToApply,
                  },
                })
              );
              setSyncTimerOpen((prev) => prev + 1);
              setToDeleteDialog(null);
              setToDeleteResource(null);
            }
          }}
          onCancel={() => {
            setToDeleteDialog(null);
            setToDeleteResource(null);
          }}
        />
      )}
      {toDeleteAssignmentId && (
        <DeleteAssignmentDialog
          assignId={toDeleteAssignmentId}
          isReplaceOp={isReplaceOp}
          open={showDeleteAssignmentDialog}
          handleClose={closeDeleteAssignmentDialog}
          handleDeleteWithVariance={handleDeleteWithVariance}
        />
      )}
      {selectedRecord && (
        <CreateTask
          open={true}
          crews={crews}
          resources={resources}
          isDailyReportView={isDailyReportView}
          reportDate={reportDate}
          onClose={handleTaskCreateClose}
          record={selectedRecord}
          updateTasksFromCreate={updateTasksFromCreate}
        />
      )}
    </div>
  );
};

export default Lookahead;
