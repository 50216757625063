import React from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import Resources from "pages/Resources";

const AddResourcesDialog = ({ open, handleClose }) => {
  return (
    <Dialog fullWidth maxWidth="85vw" open={open} onClose={handleClose}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Add Resources
          </Typography>
          <Button autoFocus color="inherit" onClick={handleClose}>
            Done
          </Button>
        </Toolbar>
      </AppBar>
      <Grid container>
        <Grid item xs={12}>
          <Resources />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default AddResourcesDialog;
