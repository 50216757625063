import React, { useCallback, useEffect, useRef, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import { Button } from "@mui/material";

const SaveTimer = ({ open, syncTimerOpen, onCloseCallback }) => {
  // const [open, setOpen] = useState(true);
  const [seconds, setSeconds] = useState(60);
  const timerRef = useRef(null);

  const handleClose = () => {
    clearInterval(timerRef.current);
    onCloseCallback();
  };

  const syncNow = () => {
    handleClose();
  };

  const action = (<Button variant="contained" onClick={syncNow}>Save</Button>);

  const updateTime = useCallback(() => {
    if (seconds === 0) {
      handleClose();
    } else {
      setSeconds((prev) => {
        return prev - 1;
      });
    }
  }, [seconds]);

  useEffect(() => {
    if(syncTimerOpen > 1) {
      if(timerRef.current) {
        // clearInterval(timerRef.current);
        setSeconds(60);
      } 
    }
  }, [syncTimerOpen]);

  useEffect(() => {
    timerRef.current = setInterval(() => {
      updateTime();
    }, 1000);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [seconds, updateTime]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      onClose={handleClose}
      message={`Changes will be auto save in ${seconds} seconds`}
      action={action}
      key={"vertical + horizontal"}
      ClickAwayListenerProps={{
        onClickAway: (e) => {e.preventDefault()},
      }}
      sx={{ top: "10px!important" }}
      ContentProps={{
        sx: { backgroundColor: '#43a35f'}
      }}
    />
  );
};

export default SaveTimer;
