import React, { useState, useEffect, useRef, useReducer } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from "@mui/material/styles";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import { cloneDeep, find, findIndex, isEmpty, keys, filter, unionBy } from "lodash";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { CustomizedSnackbars } from "../../components/Toast/Snackbar";
import SubtaskDataTable from "./SubtaskDataTable";

import { Wbs, StringHelper } from "@bryntum/gantt";
import { saveResourceAssignment, deleteResourceAssignment } from "./api/ganttApi";
import { getInitialsByWords } from "../../utils";

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const SelectionCount = styled('div')`
  right: -8px;
  top: -8px;
  color: blue;
  background: #ddd;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  line-height: 30px;
  position: absolute;
  text-align: center;
  font-size: 0.8rem;
`;

const AssignResourcesDialog = ({
  open,
  handleClose,
  userData,
  selectedTaskRecord,
  projectResources,
  assignResources,
}) => {
  // const { originalData } = selectedTaskRecord;

  const [subtasks, setSubtasks] = useState([]);
  const [selectedCrew, setSelectedCrew] = useState("");
  const [selectedCrewResources, setSelectedCrewResources] = useState([]);
  // resources
  const [ selectedForAssignment, setSelectedForAssignment] = useState([]);
  // tasks
  const [selectedForDrop, setSelectedForDrop] = useState([]);

  const [openToast, setOpenToast] = useState(false);
  const [message, setMessage] = useState("");
  const [toastType, setToastType] = useState("error");

  const [loading, setLoading] = useState(false);
  const ganttRefreshRef = useRef(false);

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setMessage("");
    setToastType("error");
    setOpenToast(false);
  };

  const handleChange = (e) => {
    setSelectedCrew(e.target.value);
  };

  const handleOnSelectAll = (e) => {
    const selected = [];
    if (e.target.checked) {
      selectedCrewResources.forEach(r => {
        selected.push(r.Id);
      });
    }
    setSelectedForAssignment(selected);
  };

  const handleOnSelectAllSubtasks = (e) => {
    const selected = [];
    if (e.target.checked) {
      subtasks.forEach(r => {
        selected.push(r.Id);
      });
    }
    setSelectedForDrop(selected);
  };

  const handleSelectSubtask = (indexVal) => () => {
    const tmp = cloneDeep(selectedForDrop);
    if(tmp.indexOf(subtasks[indexVal].Id) > -1) {
      tmp.splice(tmp.indexOf(subtasks[indexVal].Id), 1);
    } else {
      tmp.push(subtasks[indexVal].Id);
    }
    setSelectedForDrop(tmp);
  };

  const crewGroups = [];
  if (!isEmpty(projectResources)) {
    keys(projectResources).forEach((k) => {
      if (k === 'projectResources') {
        crewGroups.push({
          id: k,
          label: 'Project Resources',
          value: k,
        });
      } else {
        crewGroups.push({
          id: k,
          label: projectResources[k][0].Crew__r.Name,
          value: k,
        });
      }
    });
  }

  const closeDialog = () => {
    setSelectedCrew("");
    handleClose();
  };

  const handleResourceChecked = (indexVal) => () => {
    const tmp = cloneDeep(selectedForAssignment);
    if(tmp.indexOf(selectedCrewResources[indexVal].Id) > -1) {
      tmp.splice(tmp.indexOf(selectedCrewResources[indexVal].Id), 1);
    } else {
      tmp.push(selectedCrewResources[indexVal].Id);
    }

    setSelectedForAssignment(tmp);
  }; 

  const _saveResourceAssignment = async () => {
    console.log("************* save wbs resources ", subtasks);
    const toSave = [];
    const toDelete = [];
    subtasks.forEach(s => {
      const r = { Id: s.Id};
      const newAssigned = s.WBS_Assignments__r.filter(a => a.isNew);
      if (newAssigned.length > 0) {
        toSave.push({ ...r, WBS_Assignments__r: newAssigned });
      }

      const deleted = s.WBS_Assignments__r.filter(a => a.isDeleted);
      if (deleted.length > 0) {
        toDelete.push({...r, WBS_Assignments__r: deleted});
      }
    });
    assignResources(toSave, toDelete);
    closeDialog();
    // try {
    //   setLoading(true);
    //   const queryData = `?token=${userData?.token}&instanceUrl=${userData?.instanceUrl}`;
    //   const res = await saveResourceAssignment(queryData, wbsRec);
    //   setLoading(false);
    //   return res;
    // } catch (err) {
    //   setLoading(false);
    //   setMessage("Something went wrong!");
    //   setOpenToast(true);
    // }
  };

  const onDragEnd = async (result) => {
    if (result && result.destination.droppableId === "original-list") {
      return;
    }

    let droppedResources = [];
    if (selectedForDrop.length > 0) {
      droppedResources = cloneDeep(selectedForDrop);
      
      if (result && selectedForDrop.indexOf(result.destination.droppableId) === -1) {
        // dropped other than selected Items
        droppedResources = [result.destination.droppableId];
        setSelectedForDrop([]);
      }
    } else {
      droppedResources = [result.destination.droppableId];
    }

    let draggedItems = [];
    if (selectedForAssignment.length > 0) {
      // multiple items checked for dropping
      draggedItems = cloneDeep(selectedForAssignment);
      if (result && selectedForAssignment.indexOf(result.draggableId.split("-")[0]) === -1 ) {
        // other than checked items dragged
        // this will take priority
        draggedItems = [result.draggableId.split("-")[0]];
        setSelectedForAssignment([]);
      }
    } else {
      // single item
      draggedItems = [result.draggableId.split("-")[0]];
    }

    console.log("********* selected Reources for drop ", droppedResources);
    console.log("********* selected targets for drop ", draggedItems);

    const findResources = filter(selectedCrewResources, (f) => draggedItems.indexOf(f.Id) > -1);
    if (findResources.length === 0) {
      setMessage("Something went wrong!");
      setOpenToast(true);
      return;
    }

    const stasksTmp = cloneDeep(subtasks);
    // const findDroppedItem = find(stasksTmp, { Id: result.destination.droppableId });
    const saveItemsArr = [];
    droppedResources.forEach((dr) => {
      const itSaveObj = { wbsId: dr, resList: [] };
      const droppedTaskData = find(stasksTmp, { Id: dr});
      const nItems = [];
      findResources.forEach(n => {
        const r = find(droppedTaskData.WBS_Assignments__r, { Contact__c: n.Contact__c });
        if (!r) {
          nItems.push({ Contact__c: n.Contact__c });
        }
      });
      if (nItems.length > 0) {
        itSaveObj.resList = nItems;
        saveItemsArr.push(itSaveObj);
      }
    });

    if (saveItemsArr.length > 0) {
      console.log("**************** save items are ", saveItemsArr);
      // _saveResourceAssignment(saveItemsArr);
      // if (res?.status === 200) {
      //   if (res.retList && res.retList.length > 0) {
      //     res.retList.forEach((ri) => {
      //       const subTask = find(stasksTmp, { Id: ri.wbsId});
      //       if (subTask) {
      //         const assignedResources = [];
      //         ri.resList.forEach(resL => {
      //           const fAssigned = find(findResources, { Contact__c: resL.Contact__c });
      //           if (fAssigned) {
      //             assignedResources.push({ ...resL, Contact__r: { Name: fAssigned.Contact__r.Name }, });
      //           }
      //         });
      //         subTask.WBS_Assignments__r = unionBy(subTask.WBS_Assignments__r, assignedResources, 'Id');
      //       }
      //     });
      //     // set data to state
      //     ganttRefreshRef.current = true;
      //     setSubtasks(stasksTmp);
      //     setSelectedForAssignment([]);
      //     setSelectedForDrop([]);
      //   }
      // } else {
      //   setMessage("Something went wrong!");
      //   setOpenToast(true);
      //   return;
      // }
      saveItemsArr.forEach((ri) => {
        const subTask = find(stasksTmp, { Id: ri.wbsId});
        if (subTask) {
          const assignedResources = [];
          ri.resList.forEach(resL => {
            const fAssigned = find(findResources, { Contact__c: resL.Contact__c });
            if (fAssigned) {
              assignedResources.push({
                ...resL,
                Id: `unsaved-${StringHelper.generateUUID()}`,
                isNew: true,
                Contact__r: { Name: fAssigned.Contact__r.Name },
              });
            }
          });
          subTask.WBS_Assignments__r = unionBy(subTask.WBS_Assignments__r, assignedResources, 'Id');
        }
      });
      setSubtasks(stasksTmp);
      setSelectedForAssignment([]);
      setSelectedForDrop([]);
    } else {
      setMessage("Resources already assigned!");
      setOpenToast(true);
    }
  };

  const deleteResource = async (parentId, resourceId) => {
    const stasksTmp = cloneDeep(subtasks);
    const findItem = find(stasksTmp, { Id: parentId });
    if (findItem) {
      const assignedR = findIndex(findItem.WBS_Assignments__r, {
        Id: resourceId,
      });
      if(assignedR > -1) {
        // delete call
        // setLoading(true);
        // const queryData = `?token=${userData?.token}&instanceUrl=${userData?.instanceUrl}`;
        // const res = await deleteResourceAssignment(resourceId, queryData);
        // setLoading(false);
        // if (res.success) {
          // ganttRefreshRef.current = true;
          if (findItem.WBS_Assignments__r[assignedR].Id.includes("unsaved")) {
            findItem.WBS_Assignments__r.splice(assignedR, 1);
          } else {
            findItem.WBS_Assignments__r[assignedR].isDeleted = true;
          }
          setSubtasks(stasksTmp);
      } else {
        setMessage("Something went wrong!");
        setOpenToast(true);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (selectedCrew) {
      if (selectedCrew === 'projectResources') {
        let allData = [];
        keys(projectResources).forEach((k) => {
          allData = [...allData, ...projectResources[k]];
        });
        setSelectedCrewResources(allData);
      } else {
        setSelectedCrewResources(projectResources[selectedCrew]);
      }
      setSelectedForAssignment([]);
    }
  }, [selectedCrew]);

  useEffect(() => {
    const task = selectedTaskRecord.toJSON();
    if (task.children && task.children.length > 0) {
      let sortedSubtasks = cloneDeep(task.children).sort((recordA, recordB) => {
        return Wbs.compare(recordA.wbsValue, recordB.wbsValue);
      });
      setSubtasks(sortedSubtasks);
    } else {
      setSubtasks(cloneDeep([task]));
    }
  }, [selectedTaskRecord]);

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle id="scroll-dialog-title">
        {selectedTaskRecord.WBS_Name__c}
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ position: "relative" }}>
          {loading && (
            <div className="res-assign-loading">
              <CircularProgress />
            </div>
          )}
          <DragDropContext onDragEnd={onDragEnd}>
            <Grid container>
              <Grid item xs={12}>
                <Droppable droppableId="original-list">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {/* list */}
                      <Grid container justifyContent="space-between">
                        <Grid item xs={7}>
                          {subtasks && subtasks.length > 0 && (
                            <SubtaskDataTable
                              subtaskData={subtasks}
                              deleteResource={deleteResource}
                              selectedSubtasks={selectedForDrop}
                              handleOnSelectAllSubtasks={
                                handleOnSelectAllSubtasks
                              }
                              handleSelectSubtask={handleSelectSubtask}
                            />
                          )}
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            sx={{
                              background: "#ddd",
                              textAlign: "center",
                              lineHeight: "2.3rem",
                              borderTopLeftRadius: "3px",
                              borderTopRightRadius: "3px",
                            }}
                          >
                            {/* select all checkbox */}
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={handleOnSelectAll}
                                  checked={
                                    selectedCrewResources.length > 0 &&
                                    selectedForAssignment.length ===
                                      selectedCrewResources.length
                                  }
                                  sx={{ padding: 0 }}
                                />
                              }
                              disabled={selectedCrewResources.length === 0}
                              sx={{ margin: "6px 0 0 4px", float: "left" }}
                            />
                            Available resources
                          </Typography>
                          <Grid
                            container
                            item
                            xs={12}
                            justifyContent="right"
                            sx={{ padding: "4px 0" }}
                          >
                            <FormControl
                              sx={{ minWidth: 200 }}
                              size="small"
                              fullWidth
                            >
                              <InputLabel id="select-crew-label">
                                Select Crew
                              </InputLabel>
                              <Select
                                labelId="select-crew-label"
                                id="select-crew-small"
                                value={selectedCrew}
                                label="Select Crew"
                                onChange={handleChange}
                              >
                                {crewGroups.map((cg) => (
                                  <MenuItem key={cg.id} value={cg.value}>
                                    {cg.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Box width="100%" className="resources-list-wrapper">
                            <List sx={{ width: "100%" }}>
                              {selectedCrewResources.map((r, index) => (
                                <div key={r.Id}>
                                  <Draggable
                                    draggableId={`${r.Id}-${index}`}
                                    index={index}
                                  >
                                    {(provided, snapshot) => {
                                      const shouldShowSelection =
                                        snapshot.isDragging &&
                                        selectedForAssignment.length > 1;
                                      return (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <>
                                            <ListItem
                                              dense
                                              disablePadding
                                              sx={{
                                                position: "relative",
                                                paddingTop: 0,
                                                paddingBottom: 0,
                                              }}
                                              className={
                                                selectedForAssignment.indexOf(
                                                  r.Id
                                                ) !== -1
                                                  ? "selected-for-assignment"
                                                  : ""
                                              }
                                            >
                                              <Checkbox
                                                edge="end"
                                                onChange={handleResourceChecked(
                                                  index
                                                )}
                                                checked={
                                                  selectedForAssignment.indexOf(
                                                    r.Id
                                                  ) !== -1
                                                }
                                                sx={{
                                                  marginRight: 0,
                                                  display: "inline",
                                                }}
                                              />
                                              <ListItemText
                                                primary={r.Contact__r.Name}
                                                // secondary={
                                                //   r.Contact__r.Primary_Role__c
                                                // }
                                                secondary={
                                                  <Typography
                                                    sx={{
                                                      display: "inline",
                                                      background: "#ddd",
                                                      padding: "2px 6px",
                                                      borderRadius: "2px",
                                                      marginLeft: "4px",
                                                    }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                    title={
                                                      r.Contact__r
                                                        .Primary_Role__c
                                                    }
                                                  >
                                                    {getInitialsByWords(
                                                      r.Contact__r
                                                        .Primary_Role__c
                                                    )}
                                                  </Typography>
                                                }
                                                sx={{
                                                  display: "inline",
                                                  marginTop: "2px",
                                                  marginBottom: "2px",
                                                }}
                                                primaryTypographyProps={{
                                                  component: "span",
                                                  sx: { display: "inline" },
                                                }}
                                              />
                                              {shouldShowSelection ? (
                                                <SelectionCount>
                                                  {selectedForAssignment.length}
                                                </SelectionCount>
                                              ) : (
                                                ""
                                              )}
                                            </ListItem>
                                            <Divider component="li" />
                                          </>
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                </div>
                              ))}
                            </List>
                          </Box>
                        </Grid>
                      </Grid>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Grid>
            </Grid>
          </DragDropContext>
          <CustomizedSnackbars
            open={openToast}
            message={message}
            handleClose={handleCloseToast}
            type={toastType}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Close</Button>
        <Button variant="contained" onClick={_saveResourceAssignment}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignResourcesDialog;
