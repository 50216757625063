import { fetchUrl } from "../Urls";
import axios from "axios";

export const GetToken = async () => {
  const currentUrl = window.location.href;

  // Parse the URL and extract query parameters
  const url = new URL(currentUrl);
  const queryParams = Object.fromEntries(url.searchParams.entries());

  // Use the query parameters as needed
  return await axios
    .get(fetchUrl + "/getUser")
    .then((res) => {
      if (res?.status === 200) {
        if(queryParams?.projectId){
          res.data.projectId = queryParams?.projectId;
        }
        return res;
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
export const fetchBaseVFTemplateUrl = async () => {
  return await axios.get(`${fetchUrl}/get_vf_baseurl`)
  .then((res) => {
    if (res?.status === 200 && res?.data) {
      return res.data;
    }
  }).catch((err) => {
    console.log(err);
  });
};
export const fetchMetadataValueByName = async (metadataName) => {
  return await axios.get(`${fetchUrl}/get_external_service_value/${metadataName}`)
  .then((res) => {
    if (res?.status === 200 && res?.data) {
      return res.data;
    }
  }).catch((err) => {
    console.log(err);
  });
};
