import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  List,
  Divider,
  CircularProgress,
  Box,
  TextField,
  Button,
  DialogContentText
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { cloneDeep, filter, find, includes, uniqBy, keys, differenceBy, get } from "lodash";
import { useSelector } from "react-redux";
import "./resources.scss";

import ResourceTabs from "./ResourceTabs";
import { CustomizedSnackbars } from "../../components/Toast/Snackbar";
import { fetchUrl } from "../../Urls";
import axios from "axios";
import MuiSelect from "../../components/select/MuiSelect";
import CreateCrew from "./CreateCrew";
import { ListItemDragable } from "./ListItemDraggable";
import MuiDialog from "components/dialog/MuiDialog";

export const SelectionCount = styled("div")`
  right: -8px;
  top: -8px;
  color: blue;
  background: #ddd;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  line-height: 30px;
  position: absolute;
  text-align: center;
  font-size: 0.8rem;
`;

const StyledDialogContentText = styled(DialogContentText)`
  span.highlight {
    font-weight: 600;
  }
`;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const fetchResourceTabData = async (projectId) => {
  return await axios
    .get(`${fetchUrl}/get_resource_tab_data/${projectId}`)
    .then((res) => {
      if (res?.status === 200) {
        return res.data || {};
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

const Resources = () => {
  const { userData = {} } = useSelector((state) => state.userData);

  const [loading, setLoading] = useState(false);
  const [resourcesData, setResourcesData] = useState([]);
  const [createdResources, setCreatedResources] = useState({
    projectResources: [],
  });
  const [allCrewResources, setAllCrewResources] = useState([]);
  const [crewGroups, setCrewGroups] = useState([]);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [toastType, setToastType] = useState("error");

  const [openAddCrew, setOpenAddCrew] = useState(false);
  const [editCrew, setEditCrew] = useState(null);
  const [deleteCrewData, setDeleteCrewData] = useState();

  const [searchTerms, setSearchTerms] = useState({ name: "", role: "" });

  // left panel selections
  const [selectedItems, setSelectedItems] = useState([]);
  // right panel selections
  const [selectedFromCrews, setSelectedFromCrews] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);

  const dialogDataRef = useRef(null);

  const closeDialog = () => {
    dialogDataRef.current = {};
    setOpenDialog(false);
  };

  useEffect(() => {
    //console.log('userData :: ',JSON.stringify(userData));
    if (
      userData &&
      userData.instanceUrl &&
      userData.token &&
      userData.projectId
    ) {
      getResourceTabdata(userData.projectId);
    }
  }, [userData]);

  const getResourceTabdata = async (projectId) => {
    setLoading(true);
    const wbsData = await fetchResourceTabData(projectId);
    console.log("********* wbs data", wbsData);
    if (wbsData.resources) {
      setResourcesData(wbsData.resources);
      setSelectedItems([]);
      setSelectedFromCrews([]);
    }
    if (wbsData.createdResources) {
      setCreatedResources(wbsData.createdResources);
      let allData = [];
      keys(wbsData.createdResources).forEach((k) => {
        if (k !== "projectResources") {
          allData = [...allData, ...wbsData.createdResources[k]];
        }
      });
      setAllCrewResources(allData);
    }
    if (wbsData.crewGroups) {
      setCrewGroups(wbsData.crewGroups);
    }
    setLoading(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setMessage("");
    setOpen(false);
  };

  const addResource = async (recToSave) => {
    setLoading(true);
    const reqBody = { recObj: recToSave };
    return await axios
      .post(
        `${fetchUrl}/recSave/Project_Assignment__c?token=${userData?.token}&instanceUrl=${userData?.instanceUrl}`,
        reqBody
      )
      .then((res) => {
        setLoading(false);
        if (res.data.status == 200) {
          return res.data.retObj;
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        return;
      });
  };

  const saveResourceList = async (recToSaveArr) => {
    setLoading(true);
    const reqBody = { recLists: recToSaveArr };
    return await axios
      .post(
        `${fetchUrl}/recSave/list/Project_Assignment__c?token=${userData?.token}&instanceUrl=${userData?.instanceUrl}`,
        reqBody
      )
      .then((res) => {
        setLoading(false);
        if (res.data.status === 200) {
          // setSelectedItems([]);
          // setSelectedFromCrews([]);
          getResourceTabdata(userData.projectId);
          return res.data.recLists;
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        return;
      });
  };

  const updateCrewGroup = (data) => {
    getResourceTabdata(userData.projectId);
    // const tmp = cloneDeep(crewGroups);
    // const findItemIndex = findIndex(tmp, { id: data.id });
    // if (findItemIndex > -1) {
    //   tmp[findItemIndex] = data;
    // } else {
    //   tmp.push(data);
    // }
    // setCrewGroups(tmp);
  };

  const handleMultiDrop = async (destination, selectedResources) => {
    const crew =
      destination.droppableId && destination.droppableId !== "projectResources"
        ? destination.droppableId
        : null;

    if(destination.droppableId) {
      const toSaveArr = [];
      selectedResources.forEach(s => {
        let fc = null;
        // find in crew
        fc = find(allCrewResources, { Contact__c: s });
        if (!fc) {
          // find in resources
          fc = find(createdResources.projectResources, { Contact__c: s });
        }
        const saveItem = {
          Contact__c: s,
          Project__c: userData.projectId,
          Crew__c: crew,
        };
        if (fc) {
          saveItem.Id = fc.Id;
        }
        toSaveArr.push(saveItem);
      });

      if(createdResources[destination.droppableId]) {
        // crew is available
        // remove already assigned
        const diff = differenceBy(toSaveArr, createdResources[destination.droppableId], 'Contact__c');
        if(diff.length === 0) {
          setMessage("Resource alredy added!");
          setOpen(true);
        } else {
          // assign diff resources
          await saveResourceList(diff);
          
        }
      } else {
        // crew not available
        // assign all
        await saveResourceList(toSaveArr);
      }
    }
  };

  const onDragEnd = async (result) => {
    console.log("result is *** ", result.draggableId);
    const { source, destination, crewId } = result;
    console.log("source is *** ", source);
    console.log("destination is *** ", destination);
    if (!result.destination) {
      return;
    }

    // dropped on original list
    if (
      destination.droppableId === "original-list" ||
      source.droppableId === destination.droppableId
    ) {
      return;
    }
    
    if (selectedItems.length > 0 || selectedFromCrews.length > 0) {
      // handle multi drag
      const crewsArr = ['projectResources', ...crewGroups.map(c => c.id)];
      if(crewsArr.includes(destination.droppableId) && crewsArr.includes(source.droppableId)) {
        // movinng from crew to crew or project
        handleMultiDrop(destination, selectedFromCrews);
      } else {
        handleMultiDrop(destination, selectedItems);
      }
    } else {
      // get source item
      // const sItem = resourcesData[source.index];
      const sItem = find(resourcesData, {
        Id: result.draggableId.split("-")[0],
      });
      console.log("sItem is *** ", sItem);
      // find resource assignment
      let fc = null;
      if (sItem.crewResource) {
        // find in crew
        fc = find(allCrewResources, { Contact__c: sItem.Id });
      }
      if (sItem.projectResource) {
        // find in resources
        fc = find(createdResources.projectResources, { Contact__c: sItem.Id });
      }

      const crew =
        destination.droppableId &&
        destination.droppableId !== "projectResources"
          ? destination.droppableId
          : null;
      const toSave = {
        Contact__c: sItem.Id,
        Project__c: userData.projectId,
        Crew__c: crew,
      };
      let toSavePush = { ...toSave, ...{ Contact__r: sItem } };
      if (fc) {
        toSave.Id = fc.Id;
      }
      let pushed = false;
      let _createdResources;
      if (destination.droppableId) {
        if (createdResources[destination.droppableId]) {
          const findItem = find(createdResources[destination.droppableId], {
            Contact__c: sItem.Id,
          });
          if (findItem) {
            setMessage("Resource alredy added!");
            setOpen(true);
          } else {
            let retVal = await addResource(toSave);
            if (retVal) {
              pushed = true;
              toSavePush.Id = retVal.id;
              const oldDataSet = cloneDeep(createdResources);
              oldDataSet[destination.droppableId] = [
                ...oldDataSet[destination.droppableId],
                toSavePush,
              ];
              _createdResources = oldDataSet;
              setCreatedResources(oldDataSet);
            }
          }
        } else {
          let retVal = await addResource(toSave);
          if (retVal) {
            pushed = true;
            toSavePush.Id = retVal.id;
            const oldDataSet = cloneDeep(createdResources);
            oldDataSet[destination.droppableId] = [toSavePush];
            _createdResources = oldDataSet;
            setCreatedResources(oldDataSet);
          }
        }
        if (pushed) {
          checkResourceAssignment([sItem.Id], _createdResources);
          getResourceTabdata(userData.projectId);
        }
      }
    }
  };

  const checkResourceAssignment = (
    resourceIds,
    _createdResources = createdResources
  ) => {
    const tmpResources = cloneDeep(resourcesData);
    resourceIds.forEach(resourceId=>{
      const rInd = tmpResources.findIndex((res) => res.Id == resourceId);
      if (rInd > -1) {
        let projectResource = false;
        let crewResource = false;
        for (const key in _createdResources) {
          if (_createdResources.hasOwnProperty(key)) {
            //console.log('***key***',key);
            const findItem = find(_createdResources[key], {
              Contact__c: resourceId,
            });
            //console.log('***findItem***',JSON.stringify(findItem));
            if (findItem) {
              if (key == "projectResources") {
                projectResource = true;
              } else {
                crewResource = true;
              }
              if (projectResource && crewResource) {
                break;
              }
            }
          }
        }
        tmpResources[rInd].projectResource = projectResource;
        tmpResources[rInd].crewResource = crewResource;
      }
    })
    setResourcesData(tmpResources);
  };

  const handleDeleteResource = async (droppableId, fInd) => {
    const oldDataSet = cloneDeep(createdResources);
    closeDialog();
    setLoading(true);
    let resourceId = oldDataSet[droppableId][fInd].Id;
    await axios
      .delete(
        `${fetchUrl}/delete/Project_Assignment__c/${resourceId}?token=${userData?.token}&instanceUrl=${userData?.instanceUrl}`
      )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          oldDataSet[droppableId].splice(fInd, 1);
          setCreatedResources(oldDataSet);
          checkResourceAssignment([oldDataSet[droppableId][fInd].Contact__c], oldDataSet);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        return;
      });
  };
  const onDeleteProjectAssignments = async (resourceIds) => {
    const oldDataSet = cloneDeep(createdResources);
    closeDialog();
    if(oldDataSet && oldDataSet && resourceIds?.length > 0){
      setLoading(true);
      let recIds = [];
      keys(oldDataSet).forEach((k) => {
        if(oldDataSet[k]?.length > 0){
          let newLi = [];
          let selectedPs = [];
          oldDataSet[k].forEach((ps) => {
            if(resourceIds.includes(ps.Contact__c)){
              selectedPs.push(ps);
            }else{
              newLi.push(ps);
            }
          })
          if(selectedPs?.length > 0){
            for(let ps of selectedPs){
              if(!recIds.includes(ps.Id)){
                recIds.push(ps.Id);
              }
            }
            oldDataSet[k] = newLi;
          }
        }
      })
      if(recIds?.length > 0 ){
        await axios.post(`${fetchUrl}/delete/Project_Assignment__c`, { recIds })
          .then((res) => {
            if (res.status === 200) {
              setLoading(false);
              setCreatedResources(oldDataSet);
              checkResourceAssignment(resourceIds, oldDataSet);
              setSelectedItems([]);
              setSelectedFromCrews([]);
            }
          })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          return;
        });
      }
    }
  };

  const handleRemoveResource = async (droppableId, fInd) => {
    const oldDataSet = cloneDeep(createdResources);
    closeDialog();
    let resource = oldDataSet[droppableId][fInd];
    const toSave = {
      Id: resource.Id,
      Contact__c: resource.Contact__c,
      Project__c: userData.projectId,
      Crew__c: null,
    };
    let retVal = await addResource(toSave);
    if(retVal) {
      oldDataSet[droppableId].splice(fInd, 1);
      oldDataSet['projectResources'].push({...resource, Crew__c: null, Crew__r: null });
      setCreatedResources(oldDataSet);
    }
  };

  const handleDelete = async (droppableId, deleteId) => {
    const oldDataSet = cloneDeep(createdResources);
    if (oldDataSet[droppableId] && oldDataSet[droppableId].length > 0) {
      const fInd = oldDataSet[droppableId].findIndex(
        (res) => res.Id === deleteId
      );
      if (fInd > -1) {
        const crewName = get(oldDataSet[droppableId][fInd].Crew__r, 'Name', 'Unassigned to Crew');
        const dialogData = {};
        dialogData.title = "Delete resource";
        if (droppableId === "projectResources") {
          dialogData.actions = (
            <>
              <Button onClick={closeDialog}>Cancel</Button>
              <Button
                variant="contained"
                onClick={() => handleDeleteResource(droppableId, fInd)}
              >
                Remove from Project
              </Button>
            </>
          );
          dialogData.body = (
            <StyledDialogContentText>
              Are you sure you want to delete{" "}
              <span className="highlight">{`${oldDataSet[droppableId][fInd].Contact__r.Name}`}</span>{" "}
              from {" "}
              <span className="highlight">Project</span>?
            </StyledDialogContentText>
          );
        } else {
          dialogData.body = (
            <StyledDialogContentText>
              Do you want to remove resource {" "}
              <span className="highlight">{`${oldDataSet[droppableId][fInd].Contact__r.Name}`}</span>{" "}
              from crew <span className="highlight">{`${crewName}`}</span> or <span className="highlight">Project</span>?
            </StyledDialogContentText>
          );
          dialogData.actions = (
            <>
              <Button onClick={closeDialog}>Cancel</Button>
              <Button
                variant="contained"
                onClick={() => handleRemoveResource(droppableId, fInd)}
              >
                Remove from Crew
              </Button>
              <Button
                variant="contained"
                onClick={() => handleDeleteResource(droppableId, fInd)}
              >
                Remove from Project
              </Button>
            </>
          );
        }
        dialogDataRef.current = dialogData;
        setOpenDialog(true);
      }
    }
  };

  const handleCrewEdit = (crew) => () => {
    console.log("crew edit ", crew);
    setEditCrew(crew);
    setOpenAddCrew(true);
  };
  const handleCrewDelete = (crew) => () => {
    //console.log("crew edit ", crew);
    if(crew?.value){
        let message = `Are you sure you want to delete this crew from your project?`;
        let action = 'delete'; 
      if( createdResources && createdResources[crew.value]?.length > 0 ){
        message = `Unable to delete crew. ${crew.label} has resources assigned to them. Please remove or transfer all resources to a different crew before deleting.`;
        action = 'close'; 
      }
      const dialogData = {};
      dialogData.title = "Delete Crew";
      dialogData.actions = (
        <>
          <Button onClick={closeDialog}>{action == 'delete' ? 'Cancel' : 'Close'}</Button>
          {action == 'delete' && 
            <Button
              variant="contained"
              onClick={() => onConfirmDelete(crew?.value)}
            >
              Delete
            </Button>
          }
        </>
      );
      dialogData.body = (
        <StyledDialogContentText>
          {message}
        </StyledDialogContentText>
      );
      dialogDataRef.current = dialogData;
      setOpenDialog(true);
    }
  };
  const onConfirmDelete = async (crewId) => {
    const oldDataSet = cloneDeep(crewGroups);
    closeDialog();
    setLoading(true);
    if(oldDataSet && oldDataSet?.length > 0){
      const index = oldDataSet.findIndex(c=>c.value===crewId);
      if (index > -1) {
        await axios.delete(`${fetchUrl}/delete/Crew__c/${crewId}`)
        .then((res) => {
            if (res.status === 200) {
              const tmp = [...oldDataSet];
              tmp.splice(index, 1);
              setCrewGroups([...tmp]);
              setLoading(false);
            }
        }).catch((err) => {
          console.log(err);
          setLoading(false);
          return;
        });
      }
    }
    setLoading(false);
  };

  const findCrewNameByResource = (resource) => {
    const findItem = find(allCrewResources, { Contact__c: resource.Id });
    if (findItem) {
      return findItem.Crew__r.Name;
    }
    return "";
  };

  const checkSelectedResource = (id) => {
    const index = selectedItems.indexOf(id);
    if (index > -1) {
      const tmp = [...selectedItems];
      tmp.splice(index, 1);
      setSelectedItems([...tmp]);
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const getIsChecked = id => {
    return selectedItems.includes(id);
  };

  const handleCrewCheckBox = (id) => {
    const index = selectedFromCrews.indexOf(id);
    if (index > -1) {
      const tmp = [...selectedFromCrews];
      tmp.splice(index, 1);
      setSelectedFromCrews([...tmp]);
    } else {
      setSelectedFromCrews([...selectedFromCrews, id]);
    }
  };

  const handleSearch = (e) => {
    const key = e.target.id || e.target.name;
    setSearchTerms({ ...searchTerms, [key]: e.target.value });
  };

  let filterSelectOptions = [];
  if (resourcesData.length > 0) {
    filterSelectOptions = uniqBy(
      resourcesData.map((r) => {
        return { value: r.Primary_Role__c, label: r.Primary_Role__c };
      }),
      "value"
    );
  }

  let filteredResources = resourcesData;
  if (searchTerms.name || searchTerms.role) {
    if (searchTerms.role) {
      filteredResources = filter(resourcesData, (r) => {
        return r.Primary_Role__c === searchTerms.role;
      });
      if (searchTerms.name) {
        filteredResources = filter(filteredResources, (f) => {
          return includes(f.Name.toLowerCase(), searchTerms.name.toLowerCase());
        });
      }
    }

    if (searchTerms.name && !searchTerms.role) {
      filteredResources = filter(resourcesData, (f) => {
        return includes(f.Name.toLowerCase(), searchTerms.name.toLowerCase());
      });
    }
  }

  if (
    userData &&
    userData.instanceUrl &&
    userData.token &&
    userData.projectId
  ) {
    return (
      <>
        {loading && (
          <div className="gantt-loading-state">
            <CircularProgress />
          </div>
        )}
        <Grid container className="resources-grid resources-grid-wrapper">
          <DragDropContext onDragEnd={onDragEnd}>
            <Grid
              item
              xs={6}
              sm={6}
              md={5}
              lg={3}
              xl={3}
              className="resources-list-left"
            >
              <Box p={1}>
                <TextField
                  id="name"
                  label="Search by name"
                  variant="outlined"
                  size="small"
                  className="search-field"
                  value={searchTerms.name}
                  onChange={handleSearch}
                  fullWidth
                />
                <MuiSelect
                  id="role"
                  name="role"
                  label="Filter by role"
                  value={searchTerms.role}
                  handleChange={handleSearch}
                  options={filterSelectOptions}
                />
              </Box>
              <Droppable droppableId="original-list">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {/* list */}
                    <List sx={{ width: "100%" }}>
                      {filteredResources.map((r, index) => (
                        <div key={r.Id}>
                          <Draggable
                            draggableId={`${r.Id}-${index}`}
                            index={index}
                          >
                            {(provided, snapshot) => {
                              const shouldShowSelection =
                                snapshot.isDragging && selectedItems.length > 1;
                              return (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <>
                                    <ListItemDragable
                                      item={r}
                                      getIsChecked={getIsChecked}
                                      findCrewNameByResource={
                                        findCrewNameByResource
                                      }
                                      checkSelectedResource={
                                        checkSelectedResource
                                      }
                                    />
                                    {shouldShowSelection ? (
                                      <SelectionCount>
                                        {selectedItems.length}
                                      </SelectionCount>
                                    ) : (
                                      ""
                                    )}
                                    {index + 1 !== filteredResources.length && (
                                      <Divider component="li" />
                                    )}
                                  </>
                                </div>
                              );
                            }}
                          </Draggable>
                        </div>
                      ))}
                    </List>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={7}
              lg={9}
              xl={9}
              className="resources-list-right"
            >
              <Grid container>
                <Grid item xs={12} sx={{ padding: "8px", display:'flex', flexDirection:'row',
                                        justifyContent:'center', gap:'1rem', textAlign: "center" }}>
                  <CreateCrew
                    resourcesData={createdResources}
                    openAddCrew={openAddCrew}
                    setOpenAddCrew={setOpenAddCrew}
                    editCrew={editCrew}
                    setEditCrew={setEditCrew}
                    crewGroups={crewGroups}
                    userData={userData}
                    setLoading={setLoading}
                    updateCrewGroup={updateCrewGroup}
                  />
                  {selectedFromCrews?.length > 0 &&
                    <Button variant="outlined" startIcon={<DeleteIcon />} onClick={()=>{
                      const dialogData = {};
                      dialogData.title = "Remove Resource";
                      dialogData.actions = (
                        <>
                          <Button onClick={closeDialog}>Cancel</Button>
                          <Button
                            variant="contained"
                            onClick={() => onDeleteProjectAssignments(selectedFromCrews)}
                          >
                            Remove From Project
                          </Button>
                        </>
                      );
                      dialogData.body = (
                        <StyledDialogContentText>
                          Do you want to remove all selected resources from the project? Once removed, their WBS assignment will be deleted as well. 
                        </StyledDialogContentText>
                      );
                      dialogDataRef.current = dialogData;
                      setOpenDialog(true);
                    }}>Delete</Button>
                  }
                </Grid>
                <Grid item xs={12}>
                  <ResourceTabs
                    handleCrewEdit={handleCrewEdit}
                    handleCrewDelete={handleCrewDelete}
                    createdResources={createdResources}
                    crewGroups={crewGroups}
                    handleDelete={handleDelete}
                    selectedFromCrews={selectedFromCrews}
                    handleCrewCheckBox={handleCrewCheckBox}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DragDropContext>
          <CustomizedSnackbars
            open={open}
            message={message}
            handleClose={handleClose}
            type={toastType}
          />
        </Grid>
        {openDialog && (
          <MuiDialog
            open={openDialog}
            handleClose={closeDialog}
            title={dialogDataRef.current.title}
            actions={dialogDataRef.current.actions}
          >
            {dialogDataRef.current.body}
          </MuiDialog>
        )}
      </>
    );
  }
  return <div>Authenticating...</div>;
};

export default Resources;
