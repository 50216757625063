export const colorsList = [
  "#191919",
  "#750E21",
  "#E3651D",
  "#BED754",
  "#35155D",
  "#8CABFF",
  "#E5C3A6",
  "#FF9843",
  "#FFDD95",
  "#82A0D8",
  "#86A7FC",
  "#862B0D",
  "#FFC95F",
  "#FF8F8F",
  "#EEF296",
  "#9ADE7B",
  "#9ADE7B",
  "#508D69",
  "#8DDFCB",
  "#610C9F",
];
