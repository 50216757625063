import { Grid } from "@bryntum/gantt";

// Custom grid that holds unassigned appointments
class UnplannedGrid extends Grid {
  static get configurable() {
    return {
      selectionMode: {
        cell: false,
      },
      features: {
        stripe: true,
        tree: true,
        filter: false,
        cellMenu: true,
        columnResize: {
          disabled: true,
        }
      },

      columns: [
        {
          type: "tree",
          text: "Tasks",
          flex: 1,
          cellCls: "unscheduledNameCell",
          field: "name",
          sortable : false,
          readOnly: true,
          filter: false,
          cls: "lh-tasks-header-custom",
          headerWidgets: [
            {
              type: "textfield",
              ref: "filter-custom",
              placeholder: "Search",
              keyStrokeChangeDelay: 100,
              cls: "lh-task-filter-custom",
              onChange: "up.onFilterChange",
            }
          ],
          filterable: ({ record, value, operator }) => {
            let searchTree = record.name;
            let currRec = record;
            while(currRec.parent) {
              searchTree += currRec.parent.name;
              currRec = currRec.parent;
            }
            return searchTree.match(new RegExp(value, "i"));
          },
          renderer: ({ record: appointment, row }) => {
            if (appointment.data.children.length === 0) {
              row.addCls("leaf-drag");
              row.removeCls("no-drag");
            } else {
              row.addCls("no-drag");
              row.removeCls("leaf-drag");
            }
            return appointment.name;
          },
        },
      ],

      tbar: [
        {
          type: "widget",
          tag: "strong",
          html: "",
          flex: 1,
        },
        /*{
          type: "button",
          ref: "filterByTimeline",
          id: "filterByTimeline",
          text: "Filter Tasks",
          tooltip: "Filter tasks for current timeline",
          // onAction: "up.createBaseLines",
        },*/
        {
          icon: "b-fa b-fa-angle-double-down",
          cls: "b-transparent",
          tooltip: "Expand all groups",
          onAction: "up.expandAll",
        },
        {
          icon: "b-fa b-fa-angle-double-up",
          cls: "b-transparent",
          tooltip: "Collapse all groups",
          onAction: "up.collapseAll",
        },
      ],

      rowHeight: 25,

      disableGridRowModelWarning: true,
      preserveScroll: true,
      preserveScrollOnDatasetChange: true,
      preserveFocusOnDatasetChange: true,
    };
  }

  static get $name() {
    return "UnplannedGrid";
  }

  onFilterChange(data) {
    if(data.value) {
      this.expandAll();
      let passedIds = [];
      this.store.filterBy((rec) => {
        let pass = rec.name.match(new RegExp(data.value, "i")) || (rec.parentId && passedIds.indexOf(rec.parentId) > -1);
        if(pass && passedIds.indexOf(rec.id) === -1) {
          passedIds.push(rec.id);
        }
        return pass;
      });
    } else {
      this.store.clearFilters();
      this.collapseAll();
    }
  };
}

export { UnplannedGrid }
