export const setAuthToken = token => {
  localStorage.setItem('authToken', token);
};

export const setUserData = userData => {
  localStorage.setItem('userData', JSON.stringify(userData));
};

export const getAuthToken = () => {
  return localStorage.getItem('authToken');
};

export const getUserData = () => {
  return localStorage.getItem('userData');
};

export const clearAuthData = () => {
  localStorage.removeItem('authToken');
  localStorage.removeItem('userData');
};

export const saveLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export const getInitialsByWords = (value) => {
  const items = value.split(' ');
  const res = items.map((i) => i.charAt(0));
  return res.join('');
};

export const dependencyTypes = {
  0: 'start|start',
  1: 'start|end',
  2: 'end|start',
  3: 'end|end'
};
