import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, CircularProgress } from "@mui/material";
import { filter, find, isEmpty } from "lodash";
import {
  Scheduler,
  StringHelper,
  Store,
  DomClassList,
  Toast,
  MessageDialog,
  DomHelper,
} from "@bryntum/gantt";
import { keys, uniqBy } from "lodash";

import "./sc2.scss";
import { GetToken } from "api";
import { setUserData } from "pages/Home/services/UserDataSlice";
import { useNavigate, useLocation, Outlet } from "react-router-dom";

import {
  fetchSchedulerData,
  saveSchedulerEvent,
  fetchCompanyProjectsData,
  deleteSchedulerEvent,
} from "./api/scheduler2Api";
import { getLocalStorage } from "utils";
import { colorsList } from "./colors";
import moment from "moment";

const Scheduler2 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { userData = {} } = useSelector((state) => state.userData);
  const [loading, setLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const [events, setEvents] = useState([]);

  const schedulerRef = useRef();
  const projectsListRef = useRef([]);

  const showToast = (message = "", type = "success") => {
    const color = type === "success" ? "b-green" : "b-red";
    Toast.show({
      color,
      html: message ? message : "Success!",
      timeout: 2000,
      dock: "top",
    });
  };

  const saveEventData = async (saveObj, showLoader = true ) => {
    if(showLoader) {
      setLoading(true);
    }
    const query = `/Resource_Forecast__c?token=${userData?.token}&instanceUrl=${userData?.instanceUrl}`;
    const res = await saveSchedulerEvent(query, saveObj);
    setLoading(false);
    return res;
  };

  const saveResourceData = async (saveObj) => {
    setLoading(true);
    const query = `/Contact?token=${userData?.token}&instanceUrl=${userData?.instanceUrl}`;
    const res = await saveSchedulerEvent(query, saveObj);
    setLoading(false);
    return res;
  };

  const deleteEvent = async (id) => {
    setLoading(true);
    const query = `/Resource_Forecast__c/${id}?token=${userData?.token}&instanceUrl=${userData?.instanceUrl}`;
    const res = await deleteSchedulerEvent(query);
    setLoading(false);
  };

  const beforeEventSaveHandler = async (data) => {
    const { values, eventRecord, resourceRecords } = data;
    const saveObj = {
      Start_Date__c: moment(values.startDate).format("YYYY-MM-DD"),
      End_Date__c: moment(values.endDate).format("YYYY-MM-DD"),
      Project__c: eventRecord.data.Project__c,
      Contact__c: resourceRecords[0].data.id,
      Role__c: resourceRecords[0].data.role,
    };
    if (eventRecord.isNewEvent) {
      saveObj.Project__c = values.name;
    } else {
      saveObj.Id = eventRecord.data.id;
    }

    const res = await saveEventData(saveObj);
    if (res?.retObj?.success) {
      showToast("Successfully saved!");
      getSchedulerData();
      return true;
    } else {
      showToast("Something went wrong!", "error");
      return false;
    }
  };

  const beforeEventDeleteHandler = async ({ eventRecords, context }) => {
    const result = await MessageDialog.confirm({
      title: "Delete",
      message: "Are you sure you want to delete?",
      okButton: "Yes",
      cancelButton: "No",
    });
    if (result === MessageDialog.okButton) {
      deleteEvent(eventRecords[0].data.id);
      context.finalize(true);
    } else {
      context.finalize(false);
    }
    return false;
  };

  const _finishCellEdit = async ({ editorContext }) => {
    // sobject Contact__c
    // saveResourceData
    console.log("Data editedd ", editorContext);
    const { record } = editorContext;
    const saveObj = {
      Id: record.data.id,
      Notes__c: record.data.notes,
    };
    saveResourceData(saveObj);
  };

  const _scheduleMenuBeforeShow = ({ eventRecord }) => {
    if (["LOA", "Terminated"].includes(eventRecord.name)) {
      return false;
    }
  };

  const _onEventResizeEnd = async ({ source, changed, eventRecord }) => {
    const saveObj = {
      Id: eventRecord.data.id,
      End_Date__c: moment(eventRecord.data.endDate).format("YYYY-MM-DD"),
      Start_Date__c: moment(eventRecord.data.startDate).format("YYYY-MM-DD"),
    };
    const res = await saveEventData(saveObj, false);
    if (res?.retObj?.success) {
      showToast("Successfully saved!");
    } else {
      showToast("Something went wrong!", "error");
    }
  };

  const _onEventDrop = async ({ eventRecords, startDate, endDate }) => {
    const saveObj = {
      Id: eventRecords[0].data.id,
      Start_Date__c: moment(eventRecords[0].data.startDate).format("YYYY-MM-DD"),
      End_Date__c: moment(eventRecords[0].data.endDate).format("YYYY-MM-DD"),
    };
    const res = await saveEventData(saveObj, false);
    if (res?.retObj?.success) {
      showToast("Successfully saved!");
    } else {
      showToast("Something went wrong!", "error");
    }
  };

  const assignmentColRender = (data) => {
    return data.value;
  }

  const saveButtonClick = () => {
    const { eventEdit, eventStore } = schedulerRef.current;
    const { editingContext } = eventEdit;
    if (editingContext) {
      const { eventRecord, resourceRecord, editor } = editingContext;
      const es = moment(editor.values.startDate).format("YYYY-MM-DD");
      const ed = moment(editor.values.endDate).format("YYYY-MM-DD");
      const filtered = filter(eventStore._data, (rec) => rec.id !== eventRecord.id && rec.resourceId === resourceRecord.id);
      const overLapRec = filtered.some((f) => {
        const start = moment(f.startDate).format("YYYY-MM-DD");
        const end = moment(f.endDate).format("YYYY-MM-DD");
        return moment(es).isBetween(start, end, undefined, "[)") || moment(ed).isBetween(start, end, undefined, "[)");
      });

      if(overLapRec) {
        showToast("Date overlap is not allowed.", "error");
        return false;
      }
    }
  };

  const loadScheduler = () => {
    schedulerRef.current = new Scheduler({
      appendTo: "scheduler-container",
      preserveScroll: true,
      preserveScrollOnDatasetChange: true,
      preserveFocusOnDatasetChange: true,
      startDate: moment(new Date()).weekday(1).format("YYYY-MM-DD"),

      resourceStore: {
        data: [],
      },
      eventStore: {
        data: [],
      },

      barMargin: 10,
      rowHeight: 40,
      eventColor: null,
      eventStyle: null,
      allowOverlap: false,

      viewPreset: {
        base: "weekAndMonth", //weekAndDayLetter
        displayDateFormat: "ll",
      },

      features: {
        filterBar: true,
        stripe: true,
        timeRanges: {
          showHeaderElements: true,
          showCurrentTimeLine: false,
        },
        // Disable cell editing, this demo has its own custom row editor
        cellEdit: true,
        // Drag only within clients/employees, snap to days
        eventDrag: {
          constrainDragToResource: true,
          showExactDropPosition: true,
        },
        // Resize snapping to days
        eventResize: {
          showExactResizePosition: true,
        },
        eventEdit: {
          items: {
            nameField: false,
            resourceField: false,
            startTimeField: false,
            endTimeField: false,
          },
          editorConfig: {
            bbar: {
              items: {
                deleteButton: null,
                saveButton: {
                  listeners: {
                    click: saveButtonClick,
                  },
                },
              },
            },
          },
        },
        // Shade weekends
        nonWorkingTime: true,
        // Uses a tree where parent nodes are employees and child nodes are clients
        tree: false,
        eventMenu: {
          items: {
            deleteEvent: {
              text: "Delete",
            },
            copyEvent: false,
            cutEvent: false,
          },
        },
        cellMenu: {
          items: {
            removeRow: false,
            copy: false,
            cut: false,
            paste: false,
          },
        },
      },

      columns: [
        {
          type: "resourceInfo",
          text: "Employees",
          showRole: false,
          showEventCount: false,
          width: "15em",
          cls: "col-header",
          showImage: false,
          readOnly: true,
          filterable: {
            filterField: {
              placeholder: "Filter by name",
              cls: "filter-col-name",
            },
          },
          enableCellContextMenu: false,
        },
        {
          text: "Role",
          field: "role",
          cls: "col-header",
          readOnly: true,
          width: 150,
          filterable: {
            filterField: {
              placeholder: "Filter by role",
              cls: "filter-col-role",
              type: "combo",
              multiSelect: true,
              valueField: "role",
              displayField: "role",
            },
          },
          enableCellContextMenu: false,
        },
        {
          text: "Assigned Project",
          field: "currentProj",
          cls: "col-header",
          width: 120,
          editor: false,
          filterable: {
            filterField: {
              placeholder: "Filter by name",
              cls: "filter-col-name",
            },
          },
          // align: "right",
          // renderer: ({ value }) => `${value.map((v) => v.name).join(",")}`,
          renderer: assignmentColRender,
        },
        {
          text: "Notes",
          field: "notes",
          cls: "col-header",
          width: 180,
          filterable: false,
          readOnly: false,
        },
      ],

      tbar: {
        cls: "scheduler-toolbar",
        items: [
          {
            type: "button",
            ref: "zoomInButton",
            cls: "b-transparent zoom-btns",
            icon: "b-icon-search-plus",
            tooltip: "Zoom in",
            onAction: () => schedulerRef.current.zoomIn(),
          },
          {
            type: "button",
            ref: "zoomOutButton",
            cls: "b-transparent zoom-btns",
            icon: "b-icon-search-minus",
            tooltip: "Zoom out",
            onAction: () => schedulerRef.current.zoomOut(),
          },
          "->", // Right align toolbar items
          {
            type: "textfield",
            ref: "filterByName",
            icon: "b-fa b-fa-filter",
            placeholder: "Search Project",
            cls: "filter-task-input",
            clearable: true,
            width: "15em",
            keyStrokeChangeDelay: 100,
            triggers: {
              filter: {
                align: "start",
                cls: "b-fa b-fa-filter",
              },
            },
            onChange({ value }) {
              value = value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
              // Replace all previous filters and set a new filter
              schedulerRef.current.eventStore.filter({
                filters: (event) => event.name.match(new RegExp(value, "i")),
                replace: true,
              });
            },
          },
          /*{
            type: "textfield",
            ref: "highlight",
            placeholder: "Highlight Project",
            clearable: true,
            cls: "filter-task-input",
            width: "15em",
            keyStrokeChangeDelay: 100,
            triggers: {
              filter: {
                align: "start",
                cls: "b-fa b-fa-search",
              },
            },
            onChange({ value }) {
              schedulerRef.current.eventStore.forEach((task) => {
                const taskClassList = new DomClassList(task.cls);
                if (
                  value !== "" &&
                  task.name.toLowerCase().includes(value.toLowerCase())
                ) {
                  taskClassList.add("b-match");
                } else {
                  taskClassList.remove("b-match");
                }
                task.cls = taskClassList.value;
              });
              schedulerRef.current.element.classList[
                value.length > 0 ? "add" : "remove"
              ]("b-highlighting");
            },
          },*/
        ],
      },

      // Custom event renderer that applies colors and display events location
      eventRenderer({ renderData, resourceRecord, eventRecord }) {
        renderData.wrapperCls.add("employee-event");
        renderData.wrapperCls.add("green-bar");

        return StringHelper.encodeHtml(eventRecord.name);
      },
      listeners: {
        cellClick({ record, event }) {
          console.log("+ clicked. handle add new");
        },

        // beforeDragCreate(event) {
        //   event.eventColor = "red";
        // },

        dragCreateEnd({ eventRecord, resourceRecord }) {
          console.log("drag end create event", resourceRecord);
          // Make new event have correct type, to show correct fields in event editor
          eventRecord.type = "employee";
          eventRecord.name = "";
          eventRecord.resourceRole = resourceRecord.data.role;
          eventRecord.isNewEvent = true;
        },

        cellDblClick({ record, cellElement, column }) {
          console.log("cell double click");
        },
        beforeEventEdit({ eventRecord, eventEdit, resourceRecord }) {
          if (eventRecord.isNewEvent) {
            if (projectsListRef.current.length === 0) {
              showToast("Something went wrong!", "error");
              return false;
            }
            eventEdit._editorConfig.items.projectField = {
              type: "combo",
              name: "name",
              label: "Project",
              required: true,
              editable: false,
              weight: 115,
              items: projectsListRef.current,
            };
          } else {
            eventEdit._editorConfig.items.projectField = {
              type: "displayfield",
              name: "name",
              label: "Project",
              weight: 115,
              template: (val) => StringHelper.xss`<span>${val}</span></div>`,
            };
          }

          eventEdit._editorConfig.items.resField = {
            type: "displayfield",
            name: "resourceId",
            label: "Resource",
            weight: 120,
            template: (val) =>
              StringHelper.xss`<span>${resourceRecord.data.name}</span></div>`,
          };
          // return false;
        },
        beforeEventSave: beforeEventSaveHandler,
        beforeEventDelete: beforeEventDeleteHandler,
        finishCellEdit: _finishCellEdit,
        eventMenuBeforeShow: _scheduleMenuBeforeShow,
        renderRows: (data) => {
          console.log("scheduler is ready");
          const savedTheme = getLocalStorage("userTheme");
          if (savedTheme) {
            DomHelper.setTheme(JSON.parse(savedTheme));
          }
        },
        prio: 1,
      },
      onEventResizeEnd: _onEventResizeEnd,
      onEventDrop: _onEventDrop,
    });
    schedulerRef.current.zoomTo({ preset : 'weekAndMonth' });
  };

  const processSchedulerData = (data) => {
    const resources = [];
    const events = [];
    const keysArr = keys(data);
    keysArr.forEach((k) => {
      if (data[k].resource) {
        const rec = {
          id: data[k].resource.Id,
          name: data[k].resource.Name,
          title: data[k].resource.Primary_Role__c,
          role: data[k].resource.Primary_Role__c,
          notes: data[k].resource.Notes__c || "",
          currentProj: data[k].resource.Project__r?.Project_Number__c || "",
          expanded: true,
        };
        if(data[k].resource.LOA_Start_Date__c && data[k].resource.LOA_End_Date__c) {
          const loaEvent = {
            id: `${data[k].resource.Id}_${data[k].resource.LOA_Start_Date__c}`,
            resourceId: data[k].resource.Id,
            resourceRole: data[k].resource.Primary_Role__c,
            type: "employee",
            startDate: data[k].resource.LOA_Start_Date__c,
            endDate: data[k].resource.LOA_End_Date__c,
            name: "LOA",
            location: "LOA",
            eventColor: "red",
            projectData: "",
            Project__c: "",
          };
          events.push(loaEvent);
        }
        if(data[k].resource.Terminated_Date__c) {
          const termEvent = {
            id: `term_${data[k].resource.Id}_${data[k].resource.Terminated_Date__c}`,
            resourceId: data[k].resource.Id,
            resourceRole: data[k].resource.Primary_Role__c,
            type: "employee",
            startDate: data[k].resource.Terminated_Date__c,
            endDate: data[k].resource.Terminated_Date__c,
            name: "Terminated",
            location: "Terminated",
            eventColor: "red",
            projectData: "",
            Project__c: "",
          };
          events.push(termEvent);
        }
        if (data[k].assignments && data[k].assignments.length > 0) {
          data[k].assignments.forEach((c) => {
            // push event
            const ev = {
              id: c.Id,
              resourceId: data[k].resource.Id,
              resourceRole: data[k].resource.Primary_Role__c,
              type: "employee",
              startDate: c.Start_Date__c,
              endDate: c.End_Date__c,
              name: c.Project__r.Project_Number__c,
              location: c.Project__r.Project_Number__c,
              eventColor: "red",
              projectData: c.Project__r,
              Project__c: c.Project__c,
            };
            const prj = find(projectsListRef.current, { value: c.Project__c });
            if(prj) {
              ev.eventColor = prj.color;
            }
            events.push(ev);
          });
        }
        resources.push(rec);
      }
    });

    const store = new Store({
      tree: true,
      transformFlatData: false,
      useRawData: true,
      data: resources,
    });
    setResources(store.toJSON());

    const eventStore = new Store({
      tree: true,
      transformFlatData: false,
      useRawData: true,
      data: events,
    });

    setEvents(eventStore.toJSON());
    if (schedulerRef.current) {
      console.log("loading data for scheduler ");
      schedulerRef.current.resources = store.toJSON();
      schedulerRef.current.events = eventStore.toJSON();
    }
  };

  const getSchedulerData = async () => {
    try {
      setLoading(true);
      const queryData = `?token=${userData?.token}&instanceUrl=${userData?.instanceUrl}`;
      const wbsData = await fetchSchedulerData(queryData);
      processSchedulerData(wbsData?.resWrapper);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getProjectsData = async () => {
    try {
      setLoading(true);
      const queryData = `?token=${userData?.token}&instanceUrl=${userData?.instanceUrl}`;
      const prjData = await fetchCompanyProjectsData(queryData);
      //console.log("********* projects  data", prjData);
      if (prjData?.projects && prjData.projects.length > 0) {
        const projects = prjData.projects.map((p, i) => ({
          ...p,
          text: p.Project_Number__c,
          value: p.Id,
          color: colorsList[i % colorsList.length],
        }));
        projectsListRef.current = projects;
        getSchedulerData();
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };
  async function getCreditials() {
    const data = await GetToken();
    return data ? data : {};
  }
  const userAuthCheck = async () => {
    const response = await getCreditials();
    //console.log("data is ", response);
    const { data } = response;
    if (data?.instanceUrl && data?.token) {
      dispatch(setUserData(data));
    } else {
      console.log("failed to login");
      navigate("/login", { state: { queryTo: "redirectTo=scheduler2" } });
    }
  };

  useEffect(() => {
    if (isEmpty(userData)) {
      userAuthCheck();
    }
    return () => {
      console.log("*****unmounting index page**********");
    };
  }, []);

  useEffect(() => {
    if (resources.length > 0 && events.length > 0) {
      // document.getElementById("scheduler-container").innerHTML = "";
      // loadScheduler();schedulerRef.current
      // schedulerRef.current.resources = resources;
      // schedulerRef.current.events = events;
    }
  }, [resources, events]);
  // check user auth

  useEffect(() => {
    if (
      userData &&
      userData.instanceUrl &&
      userData.token
    ) {
      loadScheduler();
      getProjectsData();
    }
  }, [userData]);

  return (
    <div>
      {loading && (
        <div className="gantt-loading-state">
          <CircularProgress />
        </div>
      )}

      <Grid container sx={{ height: "calc(100vh - 64px)" }}>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", height: "100%" }}
          id="scheduler-container"
        ></Grid>
      </Grid>
    </div>
  );
};

export default Scheduler2;
