import React, { useRef, useState } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Button,
  DialogContentText,
  Checkbox
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

import DroppableBox from "../../components/drag-drop/DroppableBox";
import MuiDialog from "../../components/dialog/MuiDialog";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#ddd",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 0,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#eee",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&.selected": {
    backgroundColor: '#bee2ff'
  }
}));

const StyledDialogContentText = styled(DialogContentText)`
  span.highlight {
    font-weight: 600;
  }
`;

const SubtaskDataTable = ({
  subtaskData,
  deleteResource,
  selectedSubtasks,
  handleOnSelectAllSubtasks,
  handleSelectSubtask,
}) => {
  const [open, setOpen] = useState(false);

  const dialogDataRef = useRef(null);

  const closeDialog = () => {
    dialogDataRef.current = {};
    setOpen(false);
  };

  const handleDeleteResource = (parentId, resourceId) => {
    deleteResource(parentId, resourceId);
    closeDialog();
  };

  const handleDelete = (parent, resource) => {
    const dialogData = {};
    dialogData.title = "Delete resource";
    dialogData.actions = (
      <>
        <Button onClick={closeDialog}>Cancel</Button>
        <Button
          variant="contained"
          onClick={() => handleDeleteResource(parent.Id, resource.Id)}
        >
          Delete
        </Button>
      </>
    );
    dialogData.body = (
      <StyledDialogContentText>
        Are you sure you want to delete{" "}
        <span className="highlight">{`${resource.Contact__r.Name}`}</span> from
        task <span className="highlight">{`${parent.WBS_Name__c}`}</span>?
      </StyledDialogContentText>
    );
    dialogDataRef.current = dialogData;
    setOpen(true);
    // deleteResource(parentId, resourceId);
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ maxHeight: "75vh", overflow: "auto" }}
      >
        <Table sx={{ width: "100%" }} size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Checkbox
                  onChange={handleOnSelectAllSubtasks}
                  checked={selectedSubtasks.length === subtaskData.length}
                  sx={{ padding: '2px' }}
                />
              </StyledTableCell>
              <StyledTableCell>WBS</StyledTableCell>
              <StyledTableCell>Subtask</StyledTableCell>
              <StyledTableCell>Assigned Resources</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subtaskData.map((c, index) => (
              <StyledTableRow
                key={c.Id}
                className={
                  selectedSubtasks.indexOf(c.Id) !== -1 ? "selected" : ""
                }
              >
                <StyledTableCell align="center">
                  <Checkbox
                    onChange={handleSelectSubtask(index)}
                    checked={selectedSubtasks.indexOf(c.Id) !== -1}
                    sx={{ padding: '2px' }}
                  />
                </StyledTableCell>
                <StyledTableCell>{c.wbsValue}</StyledTableCell>
                <StyledTableCell>{c.WBS_Name__c}</StyledTableCell>
                <StyledTableCell>
                  <DroppableBox id={c.Id}>
                    {c.WBS_Assignments__r.map((r) => (
                      !r.isDeleted ? <Chip
                        key={r.Contact__c}
                        label={r.Contact__r.Name}
                        sx={{ margin: "3px" }}
                        onDelete={() => handleDelete(c, r)}
                      /> : ""
                    ))}
                  </DroppableBox>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {open && (
        <MuiDialog
          open={open}
          handleClose={closeDialog}
          title={dialogDataRef.current.title}
          actions={dialogDataRef.current.actions}
        >
          {dialogDataRef.current.body}
        </MuiDialog>
      )}
    </>
  );
};

export default SubtaskDataTable;
