import { Box, Grid, TextareaAutosize, Typography } from "@mui/material";
import React from "react";
import moment from 'moment';
import "./dailyReport.scss";

const ReportDetailsCard = ({ report ,...props}) => {
  const {totWorker} = props;
  return (
    <Grid item xs={12}>
      <Box className="report-data-card">
        <Box className="report-data-card-box">
          <Typography variant="subtitle1">Project</Typography>
          <Typography variant="body1">{report.Project__r.Name}</Typography>
        </Box>
        <Box className="report-data-card-box">
          <Typography variant="subtitle1">Report Name</Typography>
          <Typography variant="body1">{report.Name}</Typography>
        </Box>
        <Box className="report-data-card-box">
          <Typography variant="subtitle1">Day of week</Typography>
          <Typography variant="body1">{moment(report.Date__c).format("dddd")}</Typography>
        </Box>
        <Box className="report-data-card-box">
          <Typography variant="subtitle1">Date</Typography>
          <Typography variant="body1">{moment(report.Date__c).format("MM/DD/YYYY")}</Typography>
        </Box>
        <Box className="report-data-card-box">
          <Typography variant="subtitle1">Status</Typography>
          <Typography variant="body1">{report.Status__c}</Typography>
        </Box>
        <Box className="report-data-card-box">
          <Typography variant="subtitle1">Time On</Typography>
          <Typography variant="body1">{report.Time_On_Site__c && moment.utc(report.Time_On_Site__c, 'HH:mm:ss.SSSZ', true).format("hh:mm A")}</Typography>
        </Box>
        <Box className="report-data-card-box">
          <Typography variant="subtitle1">Time Off</Typography>
          <Typography variant="body1">{report.Time_Off_Site__c && moment.utc(report.Time_Off_Site__c, 'HH:mm:ss.SSSZ' , true).format("hh:mm A")}</Typography>
        </Box>
        <Box className="report-data-card-box">
          <Typography variant="subtitle1">Weather</Typography>
          <Typography variant="body1">{report.Weather__c}</Typography>
        </Box>
        {report.Status__c == 'Completed' &&
          <Box className="report-data-card-box">
            <Typography variant="subtitle1">Total Workers</Typography>
            <Typography variant="body1">{totWorker}</Typography>
          </Box>
        }
        {/* <Box className="report-data-card-box">
          <Typography variant="subtitle1">Comment</Typography>
          <TextareaAutosize />
        </Box> */}
      </Box>
    </Grid>
  );
};

export default ReportDetailsCard;
