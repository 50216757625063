import { DateHelper, Wbs } from "@bryntum/gantt";

const getDuration = (start, end) => {
  if(start && end) {
    return DateHelper.diff(DateHelper.parse(start, 'YYYY-MM-DD'), DateHelper.parse(end, 'YYYY-MM-DD'), 'day') * 8;
  }
  return null;
}

export const arrayToTree = (arr, parentId = null) =>
  arr
    .filter((item) => item.parentId === parentId)
    .map((child) => ({ ...child, children: arrayToTree(arr, child.id) }));

export const arrayToTree2 = (arr, parentId = null) => {
  let a = arr.filter((item) => item.parentId === parentId);
  a = a.map((child) => {
    let c = arrayToTree2(arr, child.id)
    return { ...child, children: c, readOnly: c.length > 0 ? true : false };
  });
  return a;
};

export const loadTasks = (tasksData) => {
  const tasks = [];
  const assignments = [];
  tasksData.forEach((d) => {
    // console.log("start is ***", new Date(d.Start_Date__c));
    //startDate: d.Start_Date__c ? DateHelper.parse(d.Start_Date__c, "YYYY-MM-DD") : null,
    //endDate: d.End_Date__c ? DateHelper.parse(d.End_Date__c, "YYYY-MM-DD") : null,
    const taskObj = {
      ...d,
      wbsValue: d.WBS_Number__c,
      startDate: d.Start_Date__c ? `${DateHelper.format(new Date(d.Start_Date__c), "YYYY-MM-DD")}T09:00` : null,
      endDate: d.End_Date__c ? `${DateHelper.format(new Date(d.End_Date__c), "YYYY-MM-DD")}T17:00` : null,
      // startDate: d.Start_Date__c ? d.Start_Date__c : null,
      // endDate: d.End_Date__c ? d.End_Date__c : null,
      id: d.Id,
      name: d.WBS_Name__c,
      baselines: [],
      percentDone: d.Complete__c || 0,
      expanded: true,
      rollup: true,
      durationUnit: "days",
      effortUnit: "hour",
      note: d.Notes__c,
      effort: d.Effort__c ? d.Effort__c : 0,
      duration: d.Duration__c,
      calendar: "allday",
      manuallyScheduled: false,
      constraintType: d.Constraint_Type__c || "",
      constraintDate: d.Constraint_Date__c || "",
      forceWbsOrderForChanges: true,
      wbsMode: "manual",
      // projectConstraintResolution: "ignore",
      schedulingMode: 'Normal',
      // effortDriven: true,
      // lateStartDate: d.Start_Date__c ? d.Start_Date__c: null,
      // lateEndDate: d.End_Date__c ? d.End_Date__c: null,
      Accountable_Resource__c: d.Accountable_Resource__c ? d.Accountable_Resource__c: "",
      Actual_Hours__c: Number(d.Actual_Hours__c).toFixed(2) || 0,
      Utilization__c: d.Utilization__c || 0,
    };

    if (d.Type__c === 'Milestone') {
      taskObj.isMilestone = true;
      taskObj.endDate = d.Start_Date__c ? d.Start_Date__c : null;
    } else {
      taskObj.isMilestone = false;
    }

    if(d.Parent__c) {
      taskObj.parentId = d.Parent__c;
    } else {
      taskObj.parentId = null;
    }
    if(d.WBS_Assignments__r.length > 0) {
      d.WBS_Assignments__r.forEach(a => {
        assignments.push({
          id: a.Id,
          event: d.Id,
          resource: a.Contact__c
        });
      });
    }

    if (d.WBS_Baselines__r && d.WBS_Baselines__r.records.length > 0) {
      const blines = d.WBS_Baselines__r.records.map((b) => {
        return { ...b, id: b.Id, startDate: b.Start_Date__c, endDate: b.End_Date__c }
      });
      taskObj.baselines = blines;
    }
    if(taskObj.startDate && taskObj.endDate) {
      tasks.push(taskObj);
    }
  });

  // const treeData = arrayToTree(tasks);

  const tree2 = arrayToTree2(tasks);
  console.log(tree2);

  return {tasks: tree2, assignments};
};

