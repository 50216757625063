import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Box,
  IconButton,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { fetchBaseVFTemplateUrl, fetchMetadataValueByName } from "api";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PrintIcon from "@mui/icons-material/Print";
import moment from "moment";
import { MessageDialog, Toast } from "@bryntum/gantt";

import Lookahead from "pages/Lookahead";
import {
  fetchDailyReports,
  createNewReport,
  completeReport,
  updateReportStatus,
  cloneReportApi,
  importReportApi,
} from "./api/dailyReportApis";
import CreateReportDialog from "./CreateReportDialog";
import ReportsGrid from "./ReportsGrid";
import ReportDetailsCard from "./ReportDetailsCard";
import { cloneDeep, find, uniq } from "lodash";
import UpdateStatus from "./UpdateStatus";
import AddResourcesDialog from "./AddResourcesDialog";
import CloneReport from "./CloneReport";
import ImportReport from "./ImportReport";

const DailyReport = () => {
  const { userData = {} } = useSelector((state) => state.userData);
  const { resources, assignments } = useSelector(
    (state) => state.lookaheadData
  );
  const [syncTimerOpen, setSyncTimerOpen] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalWorkers, setTotalWorkers] = useState(0);
  const [showFutureDailyReport, setShowFutureDailyReport] = useState(false);
  const [reports, setReports] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedReport, setSelectedReport] = useState(null);
  const toSaveEnabled = useRef(false);
  const saveAllButtonRef = useRef(null);

  const [openAddResources, setOpenAddResources] = useState(false);
  const [reloadReport, setReloadReport] = useState(false);

  const [vfBaseUrl, setVFBaseUrl] = useState(null);
  const [allDailyReports, setAllDailyReports] = useState([]);
  const [pastDailyReports, setPastDailyReports] = useState([]);
  const today = moment(new Date()).startOf("day").format("MM/DD/YYYY");

  const getDailyReports = async (projectId, reportToSelect = "") => {
    try {
      setLoading(true);
      const res = await fetchDailyReports(projectId);

      const alldailyreports = res;
      setAllDailyReports(alldailyreports);
      //console.log("FutureReports:", futureReports);
      const pastDailyReports = res.filter((r) => {
        const recordDate = moment(r.Date__c);
        return recordDate.isSameOrBefore(today);
      });
      setLoading(false);
      //  setReports([...pastDailyReports]);
      setPastDailyReports(pastDailyReports);
      //console.log("pastReports:", pastReports);
      if (reportToSelect) {
        const findReport = find(res, { Id: reportToSelect });
        setReloadReport(false);
        if (findReport) {
          setSelectedReport(findReport);
          setReloadReport(true);
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    console.log("show future daily report is :", showFutureDailyReport);

    if (showFutureDailyReport) {
      setReports(allDailyReports);
    } else {
      setReports(pastDailyReports);
    }
  }, [showFutureDailyReport, allDailyReports, pastDailyReports]);

  const getVFBaseUrl = async () => {
    try {
      let url = await fetchBaseVFTemplateUrl();
      const tempName = await fetchMetadataValueByName('Daily_Report_Template_Name');
      //console.log('tempName ::',tempName);
      if(url && tempName){
        const burl = `${url}${tempName}`;
        //console.log('burl ::',burl);
        setVFBaseUrl(burl);
      }
    } catch (error) {}
  };

  const handleCreateReport = async (date) => {
    const queryData = `?token=${userData?.token}&instanceUrl=${userData?.instanceUrl}`;
    try {
      setLoading(true);
      const saveObj = {
        Date__c: moment(date).format("YYYY-MM-DD"),
        Project__c: userData.projectId,
        Status__c: "Draft",
      };
      //console.log('new Report',saveObj);
      const res = await createNewReport(queryData, { recObj: saveObj });
      if (res.status === 200) {
        getDailyReports(userData.projectId);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleViewSelect = (reportObj) => {
    console.log("selected to view ", reportObj);
    setTotalWorkers(0);
    setSelectedReport(reportObj);
  };

  const handleBack = () => {
    setTotalWorkers(0);
    setSelectedReport(null);
  };

  const isValidReport = () => {
    let isResourcesValid = false;
    let isAssignmentValid = false;
    const resWithtask = getCountOfTotalWorkers();
    const workingResources = resources.filter(
      (res) => !checkIfResourceIsOff(selectedReport.Date__c, res)
    );
    //console.log(assignments?.length,'assignments ::',JSON.stringify(assignments));
    //console.log('resWithtask :: ',resWithtask.length);
    //console.log('resources :: ',resources.length);
    //console.log('workingResources :: ',workingResources.length);
    if (resWithtask >= workingResources.length) {
      isResourcesValid = true;
    }
    isAssignmentValid = !assignments
      .filter((a) => !a.deleted && !a.isDeleted && !a.rDeleted)
      .some((a) => !a.parentId || !a.eventId); //|| a.id.includes("unsaved")
    //console.log('isAssignmentValid ::',isAssignmentValid);
    return isResourcesValid && isAssignmentValid;
  };
  const getCountOfTotalWorkers = () => {
    return resources?.length;
  };
  const checkIfResourceIsOff = (date, res) => {
    let showTimeOff;
    if (res.timeOffDates && res.timeOffDates.length > 0) {
      showTimeOff = find(
        res.timeOffDates,
        (tf) => tf.offDate === moment(date).format("YYYY-MM-DD")
      );
    }
    return showTimeOff ? true : false;
  };
  const handleCompleteReport = async () => {
    if (isValidReport()) {
      try {
        let con = true;
        if (toSaveEnabled && toSaveEnabled.current != null) {
          toSaveEnabled.current.click(); // this click is to assign latest save method to saveAllButtonRef ref variable
          //console.log('con before ',con);
          con = await saveAllButtonRef.current(); // called save task method keeping await in case of failures
          //console.log('con after ',con);
        }
        //console.log('con final ',con);
        if (con) {
          setLoading(true);
          const totWorker = getCountOfTotalWorkers();
          const res = await completeReport(selectedReport.Id , totWorker);
          if (res.status === 200) {
            const tmp = cloneDeep(selectedReport);
            tmp.Status__c = "Completed";
            setSelectedReport((prev) => {
              return tmp;
            });
            const allReports = cloneDeep(reports);
            const findR = find(allReports, { Id: selectedReport.Id });
            if (findR) {
              findR.Status__c = "Completed";
              setReports(allReports);
            }
            Toast.show("Completed");
          }
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    } else {
      MessageDialog.alert({
        title: "Alert!",
        message:
          "Daily Report cannot be completed until every resource has a task.",
      });
    }
  };

  const handleUpdateStatus = async (reason) => {
    try {
      setOpenUpdate(false);
      setLoading(true);
      const res = await updateReportStatus(selectedReport.Id, reason);
      console.log("*** update status ", res);
      if (res.status === 200) {
        const tmp = cloneDeep(selectedReport);
        tmp.Status__c = "Draft";
        setSelectedReport((prev) => {
          return tmp;
        });
        const allReports = cloneDeep(reports);
        const findR = find(allReports, { Id: selectedReport.Id });
        if (findR) {
          findR.Status__c = "Draft";
          setReports(allReports);
        }
        Toast.show("Status updated");
      }
      setLoading(false);
    } catch (error) {
      console.log("Error update status:: ", error);
      Toast.show({ html: "Failed to update report", color: "b-red" });
    }
  };

  const [openUpdate, setOpenUpdate] = useState(false);
  const handleStatusUpdate = () => {
    setOpenUpdate(true);
  };

  const closeStatus = () => {
    setOpenUpdate(false);
  };

  const checkDateRange = () => {
    let canUpdate = false;
    const rmoment = moment(selectedReport.Date__c);
    if (moment().diff(rmoment, "days") < 7) {
      canUpdate = true;
    }
    return canUpdate;
  };

  async function searchListViewFunction(searchTerm) {
    setSearchText(searchTerm);
    let orgData = cloneDeep(reports);
    //console.log(searchTerm,'orgData :: '+orgData?.length);
    let searchFoundList = orgData;
    if (orgData) {
      if (searchTerm && searchTerm !== null && searchTerm !== "") {
        searchFoundList = [];
        for (let el of orgData) {
          if (el.Name && el.Name.toLowerCase().includes(searchTerm)) {
            searchFoundList.push(el);
          }
        }
      }
    }
    //console.log(searchTerm, 'searchFoundList :: ' + searchFoundList?.length);
    setViewData(searchFoundList);
  }

  const handleOpenAddResources = () => {
    setOpenAddResources(true);
    setReloadReport(false);
  };

  const handleCloseAddResources = () => {
    setOpenAddResources(false);
    // getDailyReports(userData.projectId);
    setReloadReport(true);
  };

  const handleCloneReport = async (date, reportId) => {
    try {
      setLoading(true);
      const res = await cloneReportApi({ projectId: userData.projectId, sourceId: reportId, reportDate: moment(date).format("YYYY-MM-DD") });
      setLoading(false);
      if(res.data.status) {
        const newReportId = res.data.saveId;
        getDailyReports(userData.projectId, newReportId);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleImport = async (sourceId, targetId) => {
    try {
      setLoading(true);
      setReloadReport(false);
      const res = await importReportApi({sourceId, targetId});
      setLoading(false);
      if(res.data.status) {
        // reload report data
        setReloadReport(true);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      userData &&
      userData.instanceUrl &&
      userData.token &&
      userData.projectId
    ) {
      getDailyReports(userData.projectId);
      getVFBaseUrl();
    }
  }, [userData]);

  useEffect(() => {
    setViewData(reports);
  }, [reports]);

  useEffect(() => {
    const totWorker = getCountOfTotalWorkers();
    setTotalWorkers(totWorker);
  }, [assignments]);

  return (
    <div>
      {loading && (
        <div className="gantt-loading-state">
          <CircularProgress />
        </div>
      )}
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            padding: "0.5rem",
            display: "flex",
             justifyContent:"space-between",
          }}
        >
          {!selectedReport && (
            <Typography variant="subtitle1" component="span">
              Daily Reports
            </Typography>
          )}
           {!selectedReport && (
             <Box  sx={{marginLeft:"62%"}}>
             <FormGroup>
               <FormControlLabel
                 control={
                   <Checkbox
                     value={showFutureDailyReport}
                     onChange={(e) => setShowFutureDailyReport(e.target.checked)}
                     checked={showFutureDailyReport}                   />
                 }
                 label="Show Future Daily Report"
               />
             </FormGroup>
           </Box>
           
          )}

          {!selectedReport && (
            <CreateReportDialog
              reports={reports}
              handleSave={handleCreateReport}
              searchText={searchText}
              setSearchText={setSearchText}
              searchListViewFunction={searchListViewFunction}
             
            />
          )}
         
          {selectedReport && (
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={handleBack}
            >
              Back
            </Button>
          )}
          {selectedReport && (
            <Box display="flex" flexDirection="row" gap={1}  >
              {vfBaseUrl && !(syncTimerOpen>0) && (
                <Tooltip title="Print">
                  <IconButton
                    onClick={() => {
                      const Id=selectedReport.Id;
                      const params = new URLSearchParams();
                      params.append('id', Id);
                      const baseUrl=new URL(vfBaseUrl);
                      baseUrl.search = params.toString();
                      let redUrl = baseUrl.toString();
                      console.log("redUrl:",redUrl);
                      window.open(redUrl, "_blank");
                    }}
                  >
                    <PrintIcon />
                  </IconButton>
                </Tooltip>
              )}
              <CloneReport
                reports={reports}
                selectedReport={selectedReport}
                    
                handleCloneReport={handleCloneReport}
                syncTimerOpen={syncTimerOpen}
              />

              {selectedReport.Status__c !== "Completed" && (
                <>
                  <ImportReport
                    reports={reports}
                    selectedReport={selectedReport}
                    handleImport={handleImport}
                    syncTimerOpen={syncTimerOpen}
                  />

                  <Button variant="contained" onClick={handleOpenAddResources}>
                    Add Resources
                  </Button>
                </>
              )}
              {selectedReport.Status__c !== "Completed" && (
                <Button variant="contained" onClick={handleCompleteReport}>
                  Complete
                </Button>
              )}
              {selectedReport.Status__c === "Completed" && checkDateRange() && (
                <Button variant="contained" onClick={handleStatusUpdate}>
                  Update Status
                </Button>
              )}
            </Box>
          )}
        </Grid>
        {!selectedReport && (
          <Grid item xs={12} sx={{ padding: "0.3rem" }}>
            <ReportsGrid
              reports={viewData}
              handleViewSelect={handleViewSelect}
            />
          </Grid>
        )}
        {selectedReport && (
          <ReportDetailsCard report={selectedReport} totWorker={totalWorkers} />
        )}
        {selectedReport && (
          <Grid item xs={12} sx={{ borderTop: "1px solid #ddd" }}>
            <Lookahead
              setSyncTimerOpenForImport={setSyncTimerOpen}
              toSaveEnabled={toSaveEnabled}
              saveAllButtonRef={saveAllButtonRef}
              isDailyReportView={true}
              isReadOnly={selectedReport.Status__c === "Completed"}
              reportDate={selectedReport.Date__c}
              reportId={selectedReport.Id}
              reloadReport={reloadReport}
              reportStatus={selectedReport.Status__c}
            />
          </Grid>
        )}
        {openUpdate && (
          <UpdateStatus
            open={openUpdate}
            onClose={closeStatus}
            updateReport={handleUpdateStatus}
          />
        )}
      </Grid>
      {openAddResources && (
        <AddResourcesDialog
          open={openAddResources}
          handleClose={handleCloseAddResources}
        />
      )}
    </div>
  );
};

export default DailyReport;
