import { fetchUrl } from "../../../Urls";
import axios from "axios";

export const fetchGanttData = async (projectId) => {
  return await axios
    .get(`${fetchUrl}/get_gantt_data/${projectId}`)
    .then((res) => {
      if (res?.status === 200) {
        return res.data || {};
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const saveResourceAssignment = async (queryObj, wbsResourceObj) => {
  return await axios
    .post(`${fetchUrl}/saveGanttResource/list${queryObj}`, wbsResourceObj)
    .then((res) => {
      if (res?.status === 200) {
        return res.data || {};
      }
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const deleteResourceAssignment = async (recIds) => {
  return await axios
    .post(`${fetchUrl}/delete/WBS_Assignment__c`, recIds)
    .then((res) => {
      if (res?.status === 200) {
        return res.data || {};
      }
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const updateGantData = async (queryObj, saveObj) => {
  return await axios
  .post(`${fetchUrl}/save_wbs_wrapper${queryObj}`, saveObj)
  .then((res) => {
    if (res?.status === 200) {
      return res.data || {};
    }
  })
  .catch((err) => {
    console.log(err);
    throw err;
  });
};

export const updateDepedency = async(queryObj, saveObj) => {
  return await axios
  .post(`${fetchUrl}/recSave/WBS_Dependency__c${queryObj}`, saveObj)
  .then((res) => {
    if (res?.status === 200) {
      return res.data || {};
    }
  })
  .catch((err) => {
    console.log(err);
    throw err;
  });
};

export const deleteDependencies = async (data) => {
  return await axios
  .post(`${fetchUrl}/delete/WBS_Dependency__c`, { recIds: data })
  .then((res) => {
    if (res?.status === 200) {
      return res.data || {};
    }
  })
  .catch((err) => {
    console.log(err);
    throw err;
  });
};

export const deleteEvent = async (queryObj, eventId, data) => {
  return await axios
  .post(`${fetchUrl}/delete_wbs_record/${eventId}${queryObj}`, data)
  .then((res) => {
    return res;
  })
  .catch((err) => {
    console.log(err);
    throw err;
  });
};

export const createBaselines = async(queryObj, projectId) => {
  return await axios
  .post(`${fetchUrl}/create_baseline/${projectId}${queryObj}`)
  .then((res) => {
    if (res?.status === 200) {
      return res.data || {};
    }
  })
  .catch((err) => {
    console.log(err);
    throw err;
  });
};

export const saveWbsDataList = async(queryObj, saveObj) => {
  return await axios
  .post(`${fetchUrl}/recSave/list/Work_Breakdown_Structure__c${queryObj}`, saveObj)
  .then((res) => {
    if (res?.status === 200) {
      return res.data || {};
    }
  })
  .catch((err) => {
    console.log(err);
    throw err;
  });
};

export const updateGantDataList = async (queryObj, saveObj) => {
  return await axios
  .post(`${fetchUrl}/save_wbs_wrapper/list${queryObj}`, saveObj)
  .then((res) => {
    if (res?.status === 200) {
      return res.data || {};
    }
  })
  .catch((err) => {
    console.log(err);
    throw err;
  });
};

export const getWBSTasks = async (wbs) => {
  return await axios
    .get(`${fetchUrl}/getWBSTasks/${wbs}`)
    .then((res) => {
      if (res?.status === 200) {
        return res.data || {};
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
