import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { CircularProgress, Grid } from "@mui/material";
import { BryntumScheduler, BryntumSplitter, BryntumButton } from '@bryntum/scheduler-react';
import {  DateHelper } from "@bryntum/gantt";

import { fetchGanttData } from "pages/Gantt/api/ganttApi";
import { processResourcesByCrew } from "./taskByCrewHelper";
import { schedulerConfig } from './config';
import { isEmpty, keys } from "lodash";
import { fetchLookaheadData } from "pages/Lookahead/api/lookaheadApis";

const TasksByCrew = () => {
  const { userData = {} } = useSelector((state) => state.userData);

  const scRefs = useRef([]);

  const [loading, setLoading] = useState(false);
  const [resourcesByCrewData, setResourcesByCrewData] = useState({});

  const getEventsData = async (projectId) => {
    const startDate = DateHelper.parse(new Date(), "YYYY-MM-DD");
    const endDate = DateHelper.add(
      DateHelper.parse(new Date(), "YYYY-MM-DD"),
      14,
      "days"
    );

    const reqBody = {
      startDate,
      endDate,
    };

    const eventsData = await fetchLookaheadData(projectId, reqBody);
    return eventsData;
  };

  const getGanttData = async (projectId, assignents = []) => {
    try {
      setLoading(true);
      const wbsData = await fetchGanttData(projectId);
      const eventsData = await getEventsData(projectId);
      setLoading(false);
      console.log("wbsData ", wbsData);
      console.log("wbsData ", eventsData);
      const resourcesByCrew = processResourcesByCrew(wbsData.createdResources, wbsData.wbsRecords, eventsData.tasks);
      setResourcesByCrewData(resourcesByCrew);
    } catch (error) {
      setLoading(false);
    }
  };

  const getScConfig = data => {
    const columns = [
      {
        type: "resourceInfo",
        text: data.name,
        width: "15em",
      }
    ];

    return { ...schedulerConfig, columns, resources: data.resources, events: data.events }
  };

  useEffect(() => {
    if (
      userData &&
      userData.instanceUrl &&
      userData.token &&
      userData.projectId
    ) {
      getGanttData(userData.projectId);
    }
  }, [userData]);

  return (
    <div>
      {loading && (
        <div className="gantt-loading-state">
          <CircularProgress />
        </div>
      )}
      <Grid container>
        <Grid item xs={12} id="schedulers">
          {!isEmpty(resourcesByCrewData) && keys(resourcesByCrewData).map(k => { return (
            <React.Fragment key={k}>
              <BryntumScheduler {...getScConfig(resourcesByCrewData[k])} />
              <BryntumSplitter />
            </React.Fragment>
          )})}
        </Grid>
      </Grid>
    </div>
  );
};

export default TasksByCrew;
