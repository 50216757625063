import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  // Link
} from 'react-router-dom';

import Main from "../pages/Main";
// import Gantt6 from '../pages/Gantt6';
import Home from '../pages/Home';
import Login from '../pages/Login';
import Resources from "../pages/Resources";
import Scheduler from "../pages/Scheduler";
import Scheduler2 from "../pages/Scheduler2";
import GanttSplit from "pages/Gantt/GanttSplit";
import Lookahead from 'pages/Lookahead';
import TasksByCrew from "pages/TasksByCrew";
import GanttImport from "pages/GanttImport";
import DailyReport from "pages/dailyReport";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main/>}>
          <Route path="/gantt" element={<Home/>} />
          <Route path="/gantt-import" element={<GanttImport />} />
          <Route path="/gantt-split" element={<GanttSplit />} />
          <Route path="/resources" element={<Resources/>} />
          <Route path="/lookahead" element={<Lookahead />} />
          <Route path="/daily-report" element={<DailyReport />} />
          <Route path="/tasksbycrew" element={<TasksByCrew />} />
          {/* <Route path="/scheduler" element={<Scheduler />} /> */}
          <Route path="/scheduler2" element={<Scheduler2 />} />
        </Route>
        <Route path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
