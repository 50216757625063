import React, { useRef, useState, useEffect } from "react";
import { Grid, CircularProgress } from "@mui/material";
import {
  Store,
  Container,
  Gantt,
  Toast,
  WidgetHelper,
  DomHelper,
  Splitter,
  SchedulerPro,
  MessageDialog,
} from "@bryntum/gantt";
import { useSelector, useDispatch } from "react-redux";

import {
  getGanttConfig,
  getProjectConfig,
  calendars,
  ganttFeatures,
  getShedulerConfig,
} from "./GanttConfig";
import {
  fetchGanttData,
  updateGantData,
  updateDepedency,
  deleteEvent,
  createBaselines,
} from "./api/ganttApi";

import { loadTasks } from "../../helpers/task";
import AssignResourcesDialog from "./AssignResourcesDialog";
import { GanttToolbar } from "./GanttToolbar";

import "./Gantt.scss";
import { getLocalStorage, dependencyTypes } from "utils";

const DATAFIELDSMAP = {
  startDate: "Start_Date__c",
  endDate: "End_Date__c",
  id: "Id",
  name: "WBS_Name__c",
};

// Register this widget type with its Factory
GanttToolbar.initClass();

const GanttSplit = () => {
  const dispatch = useDispatch();

  const { userData = {} } = useSelector((state) => state.userData);

  const storeRef = useRef(null);
  const ganttRef = useRef(null);
  const projectRef = useRef(null);
  const ganttContainerRef = useRef(null);
  const selectedToScrollRef = useRef(null);
  const schedulerProRef = useRef(null);

  // const [calendars, setCalendars] = useState(calendar);
  const [tasks, setTasks] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [dependencies, setDependencies] = useState([]);
  const [resources, setResources] = useState([]);
  const [projectResources, setProjectResources] = useState([]);

  const [selectedTaskRecord, setSelectedTaskRecord] = useState(null);
  const [loading, setLoading] = useState(false);

  // resource assign dialog
  const handleClose = (_reloadGantt = false) => {
    selectedToScrollRef.current = selectedTaskRecord;
    setSelectedTaskRecord(null);
    if (_reloadGantt) {
      getGanttData(userData.projectId);
    }
  };

  const processResources = (data) => {
    const resourcesData = data.map((d) => {
      return { id: d.Id, name: d.Name, role: d.Primary_Role__c };
    });
    setResources(resourcesData);
  };

  const processTasksData = (data) => {
    const { tasks, assignments } = loadTasks(data);
    storeRef.current = new Store({
      tree: true,
      transformFlatData: true,
      useRawData: true,
      data: tasks,
    });
    console.log("******** store JSON ", storeRef.current.toJSON());
    setTasks(storeRef.current.toJSON());
    setAssignments(assignments);
  };

  const processDependencies = (data) => {
    const dependencies = [];
    data.forEach((d) => {
      const connects = d.Dependency_Type__c.split("|");
      const fromSide = connects[0] === "start" ? "left" : "right";
      const toSide = connects[1] === "start" ? "left" : "right";
      dependencies.push({
        id: d.Id,
        fromTask: d.Predecessor__c,
        toTask: d.Successor__c,
        fromSide,
        toSide,
        lagUnit: "d",
        lag: d.Lag__c ? d.Lag__c : 0,
        name: d.Name,
      });
    });
    setDependencies(dependencies);
  };

  const getGanttData = async (projectId) => {
    try {
      setLoading(true);
      const wbsData = await fetchGanttData(projectId);
      setLoading(false);
      if (wbsData.resources) {
        processResources(wbsData.resources);
      }
      if (wbsData.wbsRecords) {
        processTasksData(wbsData.wbsRecords);
      }
      if (wbsData.createdResources) {
        // flatten resources
        setProjectResources(wbsData.createdResources);
      }
      if (wbsData.links) {
        processDependencies(wbsData.links);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const showToast = (message = "", type = "success") => {
    const color = type === "success" ? "b-green" : "b-red";
    Toast.show({
      color,
      html: message ? message : "Success!",
      timeout: 2000,
      dock: "top",
    });
  };

  const saveGanttData = async (saveObj) => {
    try {
      console.log("saveGanttData :: " + JSON.stringify(saveObj));
      //return;
      setLoading(true);
      const queryData = `?token=${userData?.token}&instanceUrl=${userData?.instanceUrl}`;
      const res = await updateGantData(queryData, saveObj);
      console.log("res save ", res);
      setLoading(false);
      showToast("Successfully saved!");
      getGanttData(userData.projectId);
      selectedToScrollRef.current = {
        ...res.retWrap,
        id: res.retWrap.wbsRec.Id,
      };
      return res;
    } catch (error) {
      console.log("error saving gantt data ", error);
      setLoading(false);
      showToast("Something went wrong!", "error");
      return false;
    }
  };

  const _updateDepedency = async (saveObj) => {
    try {
      setLoading(true);
      const queryData = `?token=${userData?.token}&instanceUrl=${userData?.instanceUrl}`;
      const res = await updateDepedency(queryData, saveObj);
      console.log("depedency save ", res);
      setLoading(false);
      showToast("Successfully saved!");
    } catch (error) {
      console.log("error saving gantt data ", error);
      setLoading(false);
      showToast("Something went wrong!", "error");
    }
  };

  const onFinishEdit = (event) => {
    console.log("add new task ", event);
    const editColumnName = event.editorContext.editor.dataField;
    const newTask =
      event.editorContext.editor._record.originalData.newTask | false;
    const saveObj = {
      wbsRec: { Project__c: userData.projectId },
      resources: [],
      predecessors: [],
      successors: [],
    };
    if (!newTask) {
      saveObj.wbsRec.Id = event.editorContext._id;
      if (event.editorContext.editor._record.originalData.Parent__c) {
        saveObj.wbsRec.Parent__c =
          event.editorContext.editor._record.originalData.Parent__c;
      }
    }
    // const saveObj = {Parent__c
    //   wbsRec: { Id: event.editorContext._id },
    //   resources: [],
    // };
    if (
      editColumnName === "startDate" ||
      editColumnName === "endDate" ||
      editColumnName === "name"
    ) {
      const editItemKey = DATAFIELDSMAP[editColumnName];
      if (editColumnName === "startDate" || editColumnName === "endDate") {
        saveObj.wbsRec[editItemKey] = new Date(event.editorContext.value).toUTCString();
      } else {
        saveObj.wbsRec[editItemKey] = event.editorContext.value;
      }
      saveGanttData(saveObj);
    }
  };

  const onTaskResize = (data) => {
    console.log("onTaskResize ************ data is ", data.taskRecord);
    if (data.changed) {
      const saveObj = {
        wbsRec: {
          Id: data.taskRecord.originalData.Id,
          End_Date__c: data.taskRecord._data.endDate,
        },
        resources: [],
        predecessors: [],
        successors: [],
      };
      saveGanttData(saveObj);
    }
  };

  const handleTaskDrop = (data) => {
    console.log("handleTaskDrop ************ data is ", data);
    const saveObj = {
      wbsRec: {
        Id: data.taskRecords[0].originalData.Id,
        Start_Date__c: new Date(data.taskRecords[0]._data.startDate).toUTCString(),
        End_Date__c: new Date(data.taskRecords[0]._data.endDate).toUTCString(),
      },
      resources: [],
      predecessors: [],
      successors: [],
    };
    saveGanttData(saveObj);
  };

  const handleDepedencyDrop = (dep) => {
    console.log("depedency data ", dep);
    const linkType = `${dep.data.fromSide}|${dep.data.toSide}`;
    const saveObj = {
      recObj: {
        Predecessor__c: dep.source.originalData.Id,
        Successor__c: dep.target.originalData.Id,
        Dependency_Type__c: linkType,
      },
    };
    _updateDepedency(saveObj);
  };

  // edit task using task editor and save
  const _onAfterTaskSave = async (data) => {
    console.log("after save task ", data, "isNew :", data.taskRecord);
    const saveObj = {
      wbsRec: {
        Id: data.taskRecord.originalData.Id,
        WBS_Name__c: data.taskRecord._data.name,
        Start_Date__c: new Date(data.taskRecord._data.startDate).toUTCString(),
        End_Date__c: new Date(data.taskRecord._data.endDate).toUTCString(),
        Project__c: userData?.projectId,
        Notes__c: data.taskRecord.note,
      },
      resources: [],
      predecessors: [],
      successors: [],
    };
    if (data.taskRecord.originalData.newTask) {
      delete saveObj.wbsRec.Id;
      saveObj.wbsRec.WBS_Number__c = data.taskRecord._data.wbsValue._value;
    }
    data.taskRecord.resources.forEach((r) => {
      saveObj.resources.push({ Contact__c: r.originalData.id });
    });
    data.taskRecord.predecessors.forEach((p) => {
      const lObj = {
        Predecessor__c: p.fromEvent._data.id,
        Successor__c: p.toEvent._data.id,
        Dependency_Type__c: dependencyTypes[p.type],
        Lag__c: p.lag,
      };
      if (p.id.indexOf("_generatede_") < 0) {
        // existing record
        lObj.Id = p.id;
      }
      saveObj.predecessors.push(lObj);
    });
    data.taskRecord.successors.forEach((s) => {
      const sObj = {
        Predecessor__c: s.fromEvent._data.id,
        Successor__c: s.toEvent._data.id,
        Dependency_Type__c: dependencyTypes[s.type],
        Lag__c: s.lag,
      };
      if (s.id.indexOf("_generatede_") < 0) {
        // existing record
        sObj.Id = s.id;
      }
      saveObj.successors.push(sObj);
    });
    console.log("******** save obj ", saveObj);
    const res = await saveGanttData(saveObj);
    if (data.taskRecord.originalData.newTask && res.status === 200) {
      const newtsk = {
        ...res.retObj,
        wbsValue: res.retObj.WBS_Number__c.toString(),
        startDate: res.retObj.Start_Date__c,
        endDate: res.retObj.End_Date__c,
        id: res.retObj.Id,
        name: res.retObj.WBS_Name__c,
        baselines: [],
        percentDone: 0,
        expanded: true,
        rollup: true,
        durationUnit: "hour",
        effortUnit: "hour",
      };
      if (res.retObj.Parent__c) {
        newtsk.parentId = res.retObj.Parent__c;
      }
      ganttRef.current.taskStore.rootNode.appendChild(newtsk);
      _taskEditCanceled(data);
    }
  };

  // call on context menu delete
  const onItemDeleteTask = async (data) => {
    const res = await MessageDialog.confirm({
      title: "Delete",
      message: "Are you sure you want to delete the event?",
      okButton: "Delete",
      cancelButton: "Cancel",
    });
    if (res === 1) {
      // delete event
      const resDel = await _onBeforeEventDelete(data);
      if (resDel) {
        getGanttData(userData.projectId);
      }
    }
  };

  const _onBeforeEventDelete = async (data) => {
    setLoading(true);
    const queryData = `?token=${userData?.token}&instanceUrl=${userData?.instanceUrl}`;
    const delObj = {
      wbsRec: {
        Id: data.taskRecord.originalData.Id,
      },
    };
    const delRes = await deleteEvent(
      queryData,
      data.taskRecord._data.id,
      delObj
    );
    setLoading(false);
    if (delRes?.status === 200) {
      showToast("Successfully deleted!");
      return true;
    } else {
      showToast("Something went wrong!", "error");
      return false;
    }
  };

  const _onBeforeTaskAdd = (data) => {
    console.log("before Task Add task");
    data.taskRecord.originalData.newTask = true;
    ganttRef.current.editTask(data.taskRecord);
  };

  const _taskEditCanceled = (data) => {
    console.log("cannceleld task ", data);
    if (data.taskRecord.originalData.newTask) {
      if (storeRef.current) {
        storeRef.current.remove(data.taskRecord);
      }
    }
  };

  const createContainer = () => {
    if (!ganttContainerRef.current) {
      console.log("creating container");
      // gantt chart and resources list container
      ganttContainerRef.current = new Container({
        appendTo: "gantt-chart-container",
      });
    }

    return ganttContainerRef.current;
  };

  const _createBaseLines = async () => {
    console.log("create baselines click");
    setLoading(true);
    try {
      const queryData = `?token=${userData?.token}&instanceUrl=${userData?.instanceUrl}`;
      const res = await createBaselines(queryData, userData.projectId);
      console.log("res*** ", res);
      if (res.status === 200) {
        getGanttData(userData.projectId);
      }
    } catch (error) {
      showToast("Something went wrong!", "error");
      setLoading(false);
    }
  };

  const createProjectAndGantt = (container) => {
    if (!projectRef.current) {
      projectRef.current = getProjectConfig();

      projectRef.current.tasks = [];
      projectRef.current.assignments = [];
      projectRef.current.dependencies = [];
      projectRef.current.resources = [];
      projectRef.current.calendars = [];

      const ganttFeaturesObj = { ...ganttFeatures };
      // add extra menu item
      ganttFeaturesObj.features.taskMenu.items.assignResources = {
        text: "Assign Resources",
        icon: "b-fa b-fa-fw b-fa-users",
        onItem(data) {
          setSelectedTaskRecord(data.taskRecord);
        },
      };

      ganttFeaturesObj.features.taskMenu.items.deleteTask = {
        // text: "Assign Resources",
        // icon: "b-fa b-fa-fw b-fa-users",
        onItem(data) {
          console.log("delete task action ", data);
          onItemDeleteTask(data);
        },
      };

      ganttRef.current = new Gantt({
        appendTo: "gantt-chart-container",
        flex: 1,
        project: projectRef.current,
        ...getGanttConfig(),
        ...ganttFeaturesObj,
        tbar: { type: "gantttoolbar" },
        onTaskResizeEnd: onTaskResize,
        onTaskDrop: handleTaskDrop,
        onAfterTaskSave: _onAfterTaskSave,
        onBeforeEventDelete: _onBeforeEventDelete,
        onBeforeTaskAdd: _onBeforeTaskAdd,
        onTaskEditCanceled: _taskEditCanceled,
        listeners: {
          beforeCellEditStart: (data) => {
            console.log("before edit ", data);
          },
          finishCellEdit: (data) => {
            console.log("finishCellEdit ", data);
            onFinishEdit(data);
          },
          dependencyCreateDrop: handleDepedencyDrop,
          renderRows: (data) => {
            // fired when gantt is ready in dom
            if (selectedToScrollRef.current) {
              ganttRef.current.scrollTaskIntoView(
                selectedToScrollRef.current.id,
                { block: "center" }
              );
            }
            const savedTheme = getLocalStorage("userTheme");
            if (savedTheme) {
              DomHelper.setTheme(JSON.parse(savedTheme));
              WidgetHelper.getById("theme-selector").value =
                JSON.parse(savedTheme);
              WidgetHelper.getById("createBaselinesBtn").onAction =
                _createBaseLines;
            }
          },
        },
      });
      // container.add(ganttRef.current);
      // append splitter
      new Splitter({
        appendTo: "gantt-chart-container",
      });
      // append scheduler pro
      schedulerProRef.current = new SchedulerPro({
        appendTo: "gantt-chart-container",
        flex: 1,
        project: projectRef.current,
        ...getShedulerConfig(),
        partner: ganttRef.current,
      });
    }
  };

  const initialiseGantt = () => {
    // const container = createContainer();
    createProjectAndGantt();
  };

  const loadGanttChart = () => {
    projectRef.current.tasks = tasks;
    projectRef.current.assignments = assignments;
    projectRef.current.dependencies = dependencies;
    projectRef.current.resources = resources;
    projectRef.current.calendars = calendars;
  };

  useEffect(() => {
    if (
      userData &&
      userData.instanceUrl &&
      userData.token &&
      userData.projectId
    ) {
      getGanttData(userData.projectId);
    }
  }, [userData]);

  useEffect(() => {
    if (tasks.length > 0 && resources.length > 0) {
      // document.getElementById('gantt-chart-container').innerHTML = '';
      loadGanttChart();
    }
  }, [tasks, resources]);

  useEffect(() => {
    initialiseGantt();
    return () => {
      console.log("88888888 gant ref", ganttRef.current);
      // Toolbar.remove(WidgetHelper.getById('createBaselinesBtn'));
      ganttRef.current._widgetMap.createBaselinesBtn.doDestroy();
      ganttRef.current._widgetMap["theme-selector"].doDestroy();
    };
  }, []);

  const open = selectedTaskRecord ? true : false;

  return (
    <div>
      {loading && (
        <div className="gantt-loading-state">
          <CircularProgress />
        </div>
      )}

      <Grid container>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", flexDirection: "column", height: "calc(100vh - 64px)", maxHeight: "calc(100vh - 64px)" }}
          id="gantt-chart-container"
        ></Grid>
      </Grid>
      {open && (
        <AssignResourcesDialog
          open={open}
          handleClose={handleClose}
          userData={userData}
          selectedTaskRecord={selectedTaskRecord}
          projectResources={projectResources}
        />
      )}
    </div>
  );
};

export default GanttSplit;
