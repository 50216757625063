import React, { useRef, useState, useEffect } from "react";
import { Grid, CircularProgress } from "@mui/material";
import {
  Store,
  Gantt,
  Toast,
  WidgetHelper,
  DomHelper,
  MessageDialog,
  StateProvider,
  ColumnStore,
} from "@bryntum/gantt";
import { useSelector, useDispatch } from "react-redux";

import {
  getGanttConfig,
  getProjectConfig,
  calendars,
  ganttFeatures,
} from "./GanttConfig";
import {
  fetchGanttData,
  deleteEvent,
  createBaselines,
  updateGantDataList,
  deleteResourceAssignment,
  deleteDependencies,
  getWBSTasks,
} from "./api/ganttApi";

import { loadTasks } from "../../helpers/task";
import AssignResourcesDialog from "./AssignResourcesDialog";
import { GanttToolbar } from "./GanttToolbar";
import SaveTimer from "components/SaveTimer";

import "./Gantt.scss";
import { getLocalStorage, dependencyTypes, saveLocalStorage } from "utils";
import { cloneDeep, filter, findIndex, forEach, uniqBy, find, isUndefined } from "lodash";
import { CustomCycleResolutionPopup } from "./DependencyResolutionPopup";
import { AccountableResourceColumn } from "./customColumns/AccountableResource";
import { ActualHoursColumn } from "./customColumns/ActualHours";
import { UtilizationColumn } from "./customColumns/Utilization";

const DATAFIELDSMAP = {
  startDate: "Start_Date__c",
  endDate: "End_Date__c",
  // id: "Id",
  name: "WBS_Name__c",
  percentDone: "Complete__c",
  note: "Notes__c",
  parentId: "Parent__c",
  effort: "Effort__c",
  wbsValue: "WBS_Number__c",
  parentIndex: "parentIndex",
  duration: "Duration__c",
  Accountable_Resource__c: "Accountable_Resource__c",
  constraintType: "Constraint_Type__c",
  constraintDate: "Constraint_Date__c",
};

const stateId = 'ganttGrid';

// Register this widget type with its Factory
// GanttToolbar.initClass();

const GanttChart = () => {
  const dispatch = useDispatch();

  const { userData = {} } = useSelector((state) => state.userData);

  const storeRef = useRef(null);
  const ganttRef = useRef(null);
  const projectRef = useRef(null);
  const ganttContainerRef = useRef(null);
  const selectedToScrollRef = useRef(null);
  const schedulerProRef = useRef(null);
  const dataChangesRef = useRef(null);

  // const [calendars, setCalendars] = useState(calendars);
  const [tasks, setTasks] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [dependencies, setDependencies] = useState([]);
  const [resources, setResources] = useState([]);
  const [projectResources, setProjectResources] = useState([]);

  const [selectedTaskRecord, setSelectedTaskRecord] = useState(null);
  const [loading, setLoading] = useState(false);

  const [syncTimerOpen, setSyncTimerOpen] = useState(0);

  const handleTimerClose = () => {
    setSyncTimerOpen(0);
    // call save function
    console.log("Save these changes on timer elapsed ", dataChangesRef.current);
    saveGanttDataList(dataChangesRef.current);
  };

  // resource assign dialog
  const handleClose = (_reloadGantt = false) => {
    selectedToScrollRef.current = selectedTaskRecord;
    setSelectedTaskRecord(null);
    if (_reloadGantt) {
      getGanttData(userData.projectId);
    }
  };

  const processResources = (data) => {
    // const resourcesData = data.map((d) => {
    //   return { id: d.Id, name: d.Name, role: d.Primary_Role__c };
    // });
    // setResources(resourcesData);
    let resourcesData = [];
    Object.keys(data).forEach((k) => {
      let tmp = data[k].map((i) => ({
        id: i.Contact__c,
        name: i.Contact__r.Name,
        role: i.Contact__r.Primary_Role__c,
        Contact__r: i.Contact__r,
      }));
      resourcesData = [...resourcesData, ...tmp];
    });
    setResources([...uniqBy(resourcesData, "id")]);
  };

  const processTasksData = (data) => {
    const { tasks, assignments } = loadTasks(data);
    const store = new Store({
      // tree: true,
      // transformFlatData: true,
      useRawData: true,
      data: [...tasks],
    });

    setTasks(store.toJSON());
    setAssignments(assignments);
    storeRef.current = store;
  };

  const processDependencies = (data, deleted = []) => {
    // suspendChangeTraking();
    let tmp = cloneDeep(dependencies);
    const newDependencies = [];
    data.forEach((d) => {
      const connects = d.Dependency_Type__c.split("|");
      const fromSide = connects[0] === "start" ? "left" : "right";
      const toSide = connects[1] === "start" ? "left" : "right";
      const dObj = {
        id: d.Id,
        fromTask: d.Predecessor__c,
        toTask: d.Successor__c,
        fromSide,
        toSide,
        lagUnit: "d",
        lag: d.Lag__c ? d.Lag__c : 0,
        name: d.Name,
      };

      const findDepIdx = findIndex(tmp, {id: d.Id});
      if(findDepIdx > -1) {
        tmp[findDepIdx] = dObj;
      } else {
        tmp.push(dObj);
      }
    });

    if(deleted.length > 0) {
      tmp = tmp.filter((t) => !deleted.includes(t.id));
    }
    setDependencies((prev) => {
      return [...tmp];
    });
  };

  const getGanttData = async (projectId) => {
    try {
      setLoading(true);
      const wbsData = await fetchGanttData(projectId);
      setLoading(false);
      resetUnsavedTimer();
      suspendChangeTraking();
      if(wbsData){
        if (wbsData.wbsRecords) {
          processTasksData(wbsData.wbsRecords);
        }
        if (wbsData.createdResources) {
          processResources(wbsData.createdResources);
        }
        if (wbsData.createdResources) {
          setProjectResources(wbsData.createdResources);
        }
        if (wbsData.links) {
          processDependencies(wbsData.links);
        }
      }
      resumeChangeTraking();
    } catch (error) {
      console.log('getGantt Data error :: ',error);
      setLoading(false);
    }
  };

  const showToast = (message = "", type = "success") => {
    const color = type === "success" ? "b-green" : "b-red";
    Toast.show({
      color,
      html: message ? message : "Success!",
      timeout: 2000,
      dock: "top",
    });
  };

  const saveGanttDataList = async (saveWbsList) => {
    try {
      projectRef.current.stm.resetQueue();
      projectRef.current.clearChanges(true);
      suspendChangeTraking();
      setLoading(true);
      let deletedDeps = [];
      if(saveWbsList.deletedDependencies.length > 0) {
        deletedDeps = await _deleteDependencies(saveWbsList.deletedDependencies);
      }
      const queryData = `?token=${userData?.token}&instanceUrl=${userData?.instanceUrl}`;
      let promises = [];
      if (saveWbsList.recLists.length > 0) {
        promises.push(
          updateGantDataList(queryData, {
            recLists: saveWbsList.recLists,
          })
        );
      }

      if (saveWbsList.deletedAssignments.length > 0) {
        promises.push(
          deleteResourceAssignment({
            recIds: saveWbsList.deletedAssignments,
          })
        );
      }
      const resAll = await Promise.all(promises);
      dataChangesRef.current = null;
      // projectRef.current.stm.resetQueue();
      projectRef.current.clearChanges(true);
      
      if (resAll[0] && resAll[0].retWrap?.length > 0) {
        let dependenciesAdded = [];
        forEach(resAll[0].retWrap, (r) => {
          let ffield = r.wbsRec.WBS_Number__c != null ? "wbsValue" : "id" ;
          let fValue = r.wbsRec.WBS_Number__c != null ? r.wbsRec.WBS_Number__c : r.wbsRec.Id ;
          const findRec = projectRef.current.taskStore.findRecord( ffield, fValue, true );
          if (findRec) {
            findRec.Id = r.wbsRec.Id;
            findRec.id = r.wbsRec.Id;
            if(r?.resources?.length > 0){
              forEach(r.resources, wa =>{
                //console.log('wa.key ::',wa.key);
                if(wa.key){
                  const sres = projectRef.current.assignmentStore.getById(wa.key);
                  //console.log('sres ::',sres);
                  if(sres){
                    sres.set('id',wa.Id);
                  }
                  if(findRec?.WBS_Assignments__r?.length > 0){
                    const fiii = findRec?.WBS_Assignments__r.findIndex(ra=>ra.Id === wa.key);
                    //console.log('fiii ::',fiii);
                    if(fiii > -1){
                      findRec.WBS_Assignments__r[fiii].Id = wa.Id;
                      findRec.WBS_Assignments__r[fiii].isNew = undefined;
                      findRec.WBS_Assignments__r[fiii].key = undefined;
                    }
                  }
                }
              });
            }
          }
          if(r.predecessors?.length > 0) {
            dependenciesAdded = [...dependenciesAdded, ...r.predecessors];
          }
          if(r.successors?.length > 0) {
            dependenciesAdded = [...dependenciesAdded, ...r.successors];
          }
        });
        if(dependenciesAdded.length > 0) {
          processDependencies(dependenciesAdded, deletedDeps);
        }
        projectRef.current.stm.resetQueue();
        projectRef.current.clearChanges(true);
        resumeChangeTraking();
      }
      setLoading(false);
      // projectRef.current.clearChanges(true);
      // resumeChangeTraking();
      showToast("Successfully saved!");
    } catch (error) {
      console.log("error saving gantt data ", error);
      setLoading(false);
      showToast("Something went wrong!", "error");
    }
  };

  const _deleteDependencies = async (recIds) => {
    const deletedDepRes = await deleteDependencies(recIds);
    return deletedDepRes.map(r => r.id);
    // if(res && res.length > 0) {
    //   const removed = res.map(r => r.id);
    //   const tmpDep = cloneDeep(dependencies);
    //   const updated = tmpDep.filter(d => removed.indexOf(d.id) === -1);
    //   setDependencies(prev => [...updated]);
    // }
  };

  const _saveAllChanges = () => {
    if (projectRef.current.changes) {
      if (!WidgetHelper.getById("readonlyCheck")?.checked) {
        projectRef.current.refreshWbs();
      }
      const { tasks, assignments, dependencies } = projectRef.current.changes;
      const saveObj = { recLists: [], deletedAssignments: [], deletedDependencies: [], updatedDependencies: [] };
      if (tasks && tasks.updated?.length > 0) {
        const updatedTasks = tasks.updated;
        // filter records with updates
        const updated = filter(updatedTasks, (u) => {
          let avail = false;
          Object.keys(DATAFIELDSMAP).forEach((k) => {
            if (k in u) {
              avail = true;
            }
          });
          return avail;
        });
        // prepare Obj
        updated.forEach((u) => {
          //create obj
          const sdata = {
            wbsRec: {
              Project__c: userData.projectId,
              Id: u.id,
            },
            resources: [],
            predecessors: [],
            successors: [],
          };
          const taskFromStore = projectRef.current.taskStore.getById(u.id);
          // generate available fields
          Object.keys(DATAFIELDSMAP).forEach((k) => {
            if (!isUndefined(u[k])) {
              if (k === "parentIndex") {
                // do nothing
              } else if (k === "startDate" || k === "endDate") {
                sdata.wbsRec[DATAFIELDSMAP[k]] = new Date(u[k]).toUTCString();
                if(taskFromStore?.duration) {
                  sdata.wbsRec["Duration__c"] = taskFromStore.duration;
                }
              } else {
                sdata.wbsRec[DATAFIELDSMAP[k]] = u[k];
              }
            }
          });

          const parentId = taskFromStore.parentId;
          if (parentId) {
            sdata.wbsRec.Parent__c = parentId;
          }
          if (taskFromStore.wbsValue && taskFromStore.wbsValue.value) {
            sdata.wbsRec.WBS_Number__c = taskFromStore.wbsValue.value;
          }
          //push to array
          saveObj.recLists.push(sdata);
          selectedToScrollRef.current = u;
        });
      } // end tasks updated

      if (tasks && tasks.added) {
        tasks.added.forEach((u) => {
          const taskFromStore = projectRef.current.taskStore.getById(
            u.$PhantomId
          );
          //create obj
          const sdata = {
            wbsRec: {
              Project__c: userData.projectId,
              Parent__c: u.parentId,
              WBS_Number__c: taskFromStore.wbsValue._value,
            },
            resources: [],
            predecessors: [],
            successors: [],
          };
          if(taskFromStore?.id && taskFromStore?.id.indexOf("_generated") === -1) {
            sdata.wbsRec.Id = taskFromStore?.id;
          }
          // generate available fields
          Object.keys(DATAFIELDSMAP).forEach((k) => {
            if (!isUndefined(u[k])) {
              if (k === "parentIndex") {
                // do nothing
              } else if (k === "startDate" || k === "endDate") {
                sdata.wbsRec[DATAFIELDSMAP[k]] = new Date(u[k]).toUTCString();
              } else {
                sdata.wbsRec[DATAFIELDSMAP[k]] = u[k];
              }
            }
          });

          if (taskFromStore.isMilestone) {
            sdata.wbsRec.Type__c = "Milestone";
            sdata.wbsRec.End_Date__c = new Date(u.startDate).toUTCString();
          } else {
            sdata.wbsRec.Type__c = "Task";
          }

          taskFromStore.resources.forEach((r) => {
            sdata.resources.push({ Contact__c: r.id });
          });
          saveObj.recLists.push(sdata);
        });
      }

      //assignments changed
      if (assignments && assignments.added) {
        assignments.added.forEach((a) => {
          let resRec = cloneDeep({ Id : a.$PhantomId ,Contact__c: a.resourceId });
          if(resRec.Id?.includes("unsaved")){
            resRec.key = a.$PhantomId;
            resRec.Id = null;
          }
          const findex = findIndex(saveObj.recLists, {
            wbsRec: { Id: a.eventId },
          });
          if (findex >= 0) {
            saveObj.recLists[findex].resources.push(resRec);
          } else {
            saveObj.recLists.push({
              wbsRec: {
                Project__c: userData.projectId,
                Id: a.eventId,
              },
              resources: [resRec],
              predecessors: [],
              successors: [],
            });
          }
        });
      } // assignments added end

      // assignment modified
      const assignStore = projectRef.current.assignmentStore;
      if (
        assignStore.changes &&
        assignStore.changes.modified &&
        assignStore.changes.modified.length > 0
      ) {
        assignStore.changes.modified.forEach((a) => {
          let resRec = cloneDeep({ Id : a.id ,Contact__c: a.resourceId });
          if(resRec.Id?.includes("unsaved")){
            resRec.key = a.id;
            resRec.Id = null;
          }
          const findex = findIndex(saveObj.recLists, {
            wbsRec: { Id: a.eventId },
          });
          if (findex >= 0) {
            saveObj.recLists[findex].resources.push(resRec);
          } else {
            saveObj.recLists.push({
              wbsRec: {
                Project__c: userData.projectId,
                Id: a.eventId,
              },
              resources: [resRec],
              predecessors: [],
              successors: [],
            });
          }
        });
      } // assignments modified end

      // assignments deleted
      if (assignStore.changes && assignStore.changes.removed?.length > 0) {
        console.log(
          "******* assignment store changed ",
          assignStore.changes.removed
        );
        saveObj.deletedAssignments = assignStore.changes.removed.map(
          (a) => a.id
        );
      } // assignments deleted end

      // predesessor and successor added
      if (dependencies && dependencies.added) {
        dependencies.added.forEach((d) => {
          const findex = findIndex(saveObj.recLists, {
            wbsRec: { Id: d.fromEvent },
          });
          if (findex >= 0) {
            // push to existing records
            saveObj.recLists[findex].successors.push({
              Predecessor__c: d.fromEvent,
              Successor__c: d.toEvent,
              Dependency_Type__c: dependencyTypes[d.type],
              Lag__c: d.lag,
            });
          } else {
            saveObj.recLists.push({
              wbsRec: {
                Project__c: userData.projectId,
                Id: d.fromEvent,
              },
              resources: [],
              predecessors: [],
              successors: [
                {
                  Predecessor__c: d.fromEvent,
                  Successor__c: d.toEvent,
                  Dependency_Type__c: dependencyTypes[d.type],
                  Lag__c: d.lag,
                },
              ],
            });
          }
        });
      } // predesessor and successor added

      // predesessor and successor modified
      const depStore = projectRef.current.dependencyStore;
      if (
        depStore.changes &&
        depStore.changes.modified &&
        depStore.changes.modified.length > 0
      ) {
        depStore.changes.modified.forEach((d) => {
          const findex = findIndex(saveObj.recLists, {
            wbsRec: { Id: d.from },
          });
          if (findex >= 0) {
            // push to existing records
            saveObj.recLists[findex].successors.push({
              Predecessor__c: d.from,
              Successor__c: d.to,
              Dependency_Type__c: dependencyTypes[d.type],
              Lag__c: d.lag,
              Id: d.id,
            });
          } else {
            saveObj.recLists.push({
              wbsRec: {
                Project__c: userData.projectId,
                Id: d.from,
              },
              resources: [],
              predecessors: [],
              successors: [
                {
                  Predecessor__c: d.from,
                  Successor__c: d.to,
                  Dependency_Type__c: dependencyTypes[d.type],
                  Lag__c: d.lag,
                  Id: d.id,
                },
              ],
            });
          }
        });
      } // predesessor and successor modified

      if(dependencies?.removed && dependencies?.removed?.length > 0) {
        saveObj.deletedDependencies = dependencies.removed.map(d => d.id);
      }

      if(dependencies && dependencies.updated?.length > 0) {
        saveObj.updatedDependencies = dependencies.updated.map(d => d.id);
        // toDO: sAVE
      }

      const filteredObj = saveObj.recLists.filter((d) => {
        if (!d.wbsRec.Id) {
          return d;
        }
        if (d.wbsRec.Id.indexOf("_generate") === -1) {
          return d;
        }
        return false;
      });

      if (
        filteredObj.length > 0 ||
        saveObj.deletedAssignments.length > 0 ||
        saveObj.deletedDependencies.length > 0
      ) {
        
        dataChangesRef.current = {
          recLists: filteredObj,
          deletedAssignments: saveObj.deletedAssignments,
          deletedDependencies: saveObj.deletedDependencies,
        };
        setSyncTimerOpen((prev) => prev + 1);
      } else {
        resetUnsavedTimer();
      }
    } else {
      resetUnsavedTimer();
    }
  };

  const resetUnsavedTimer = () => {
    if(projectRef.current){
      projectRef.current.stm.resetQueue();
      projectRef.current.clearChanges(true);
    }
    dataChangesRef.current = null;
    setSyncTimerOpen(0);
  };

  const assignResources = (added, deleted) => {
    let assignments = projectRef.current.assignmentStore.toJSON();
    added.forEach((d) => {
      const tsk = projectRef.current.taskStore.getById(d.Id);
      // assignments = projectRef.current.assignmentStore.toJSON();
      d.WBS_Assignments__r.forEach((r) => {
        const findAssigned = assignments.find((rec) => {
          return rec.event === d.Id && rec.resource === r.Contact__c;
        });
        if (!findAssigned) {
          projectRef.current.assignmentStore.add({
            id: r.Id,
            event: d.Id,
            resource: r.Contact__c
          });
        }

        const findAssignedToTask = tsk.WBS_Assignments__r.find(
          (tr) => tr.Contact__c === r.Contact__c
        );
        //console.log('r :: ',JSON.stringify(r));
        if (!findAssignedToTask) {
          tsk.set("WBS_Assignments__r", [...tsk.WBS_Assignments__r, r]);
        }
        //console.log('tsk.WBS_Assignments__r :: ',JSON.stringify(tsk.WBS_Assignments__r));
      });
    });
    //deleted
    deleted.forEach((d) => {
      d.WBS_Assignments__r.forEach((r) => {
        const findAssigned = assignments.find((rec) => {
          return rec.event === d.Id && rec.resource === r.Contact__c;
        });
        if (findAssigned) {
          projectRef.current.assignmentStore.remove(findAssigned.id);
        }
        // mark deleted from task
        const tsk = projectRef.current.taskStore.getById(d.Id);
        const findAssignedToTask = tsk.WBS_Assignments__r.find(
          (tr) => tr.Contact__c === r.Contact__c
        );
        if (findAssignedToTask) {
          tsk.set("WBS_Assignments__r", [
            ...tsk.WBS_Assignments__r.filter(
              (t) => t.Id !== findAssignedToTask.Id
            ),
          ]);
        }
      });
    });
  };

  const confirmDelete = async (data) => {
    const res = await MessageDialog.confirm({
      title: "Delete",
      message: "Are you sure you want to delete the event?",
      okButton: "Delete",
      cancelButton: "Cancel",
    });
    if (res === 1) {
      // delete event
      if(data.taskRecord._data.id.indexOf("_generated") > -1) {
        showToast("Successfully deleted!");
        projectRef.current.taskStore.remove(data.taskRecord);
        return true;
      }
      const resDel = await _onBeforeEventDelete(data);
      return resDel;
    } else {
      return false;
    }
  };

  // call on context menu delete
  const onItemDeleteTask = async (data) => {
    try {
      if(!data.taskRecord.originalData.Id) {
        const res =  await confirmDelete(data);
        return res;
      } else {
        setLoading(true);
        const wbsTasks = await getWBSTasks(data.taskRecord.originalData.Id);
        setLoading(false);
        if(wbsTasks?.data?.records?.length > 0) {
          const res = await MessageDialog.alert({
            title: "Delete",
            message: "WBS cannot be deleted if it has a task associated.",
            okButton: "Ok",
          });
          if(res === 1) {
            return false;
          }
        } else {
          return await confirmDelete(data);
        } 
      }      
    } catch (error) {
      setLoading(false);
      showToast("Something went wrong!", "error");
      return false;
    }
  };

  const _onBeforeEventDelete = async (data) => {
    setLoading(true);
    const queryData = `?token=${userData?.token}&instanceUrl=${userData?.instanceUrl}`;
    const delObj = {
      wbsRec: {
        Id: data.taskRecord.originalData.Id,
      },
    };
    const delRes = await deleteEvent(
      queryData,
      data.taskRecord._data.id,
      delObj
    );
    setLoading(false);
    if (delRes?.status === 200) {
      showToast("Successfully deleted!");
      projectRef.current.taskStore.remove(data.taskRecord);
      return true;
    } else {
      showToast("Something went wrong!", "error");
      return false;
    }
  };

  const _createBaseLines = async () => {
    console.log("create baselines click");
    setLoading(true);
    try {
      const queryData = `?token=${userData?.token}&instanceUrl=${userData?.instanceUrl}`;
      const res = await createBaselines(queryData, userData.projectId);
      console.log("res*** ", res);
      if (res.status === 200) {
        getGanttData(userData.projectId);
      }
    } catch (error) {
      showToast("Something went wrong!", "error");
      setLoading(false);
    }
  };

  const suspendChangeTraking = () => {
    if (projectRef.current?.suspendChangeTracking) {
      projectRef.current.suspendChangeTracking();
    }

    if (projectRef.current?.suspendChangesTracking) {
      projectRef.current.suspendChangesTracking();
    }
  };
  const resumeChangeTraking = () => {
    if (projectRef.current?.resumeChangeTracking) {
      projectRef.current.resumeChangeTracking(true);
    }
    if (projectRef.current?.resumeChangesTracking) {
      projectRef.current.resumeChangesTracking(true);
    }
  };

  const createProjectAndGantt = (container) => {
    if (!projectRef.current) {
      projectRef.current = getProjectConfig();

      projectRef.current.tasks = [];
      projectRef.current.assignments = [];
      projectRef.current.dependencies = [];
      projectRef.current.resources = [];
      projectRef.current.calendars = [];

      projectRef.current.stm.autoRecord = true;
      projectRef.current.resetUndoRedoQueuesAfterLoad = true;
      // projectRef.current.enable();

      projectRef.current.listeners = {
        change(data) {
          const { store, action, records } = data;
        },
        hasChanges() {
          console.log("******** has changes ");
          _saveAllChanges();
        },
        noChanges(data) {
          if(!dataChangesRef.current) {
            resetUnsavedTimer();
          }
        },
      };

      const ganttFeaturesObj = { ...ganttFeatures };
      // add extra menu item
      ganttFeaturesObj.features.taskMenu.items.assignResources = {
        text: "Assign Resources",
        icon: "b-fa b-fa-fw b-fa-users",
        weight: 101,
        onItem(data) {
          setSelectedTaskRecord(data.taskRecord);
        },
      };

      ganttFeaturesObj.features.taskMenu.items.deleteTask = {
        onItem(data) {
          //console.log("delete task action ", data);
          onItemDeleteTask(data);
        },
      };

      // custom column
      ColumnStore.registerColumnType(AccountableResourceColumn);
      ColumnStore.registerColumnType(ActualHoursColumn);
      ColumnStore.registerColumnType(UtilizationColumn);

      ganttRef.current = new Gantt({
        appendTo: "gantt-chart-container",
        preserveScroll: true,
        preserveScrollOnDatasetChange: true,
        preserveFocusOnDatasetChange: true,
        project: projectRef.current,
        stateId: "ganttGrid",
        ...getGanttConfig(),
        ...ganttFeaturesObj,
        tbar: { type: "gantttoolbar" },
        cycleResolutionPopupClass: CustomCycleResolutionPopup,
        onBeforeTaskAdd(data) {
          const { taskRecord } = data;
          taskRecord.effort = 0;
        },
        listeners: {
          beforeCellEditStart(data) {
            const { editorContext } = data;
            if(editorContext.column.field === "Accountable_Resource__c") {
              const resources = ganttRef.current.resources.map((r) => ({ id: r.id, name: r.name }));
              editorContext.editor.items = resources;
              editorContext.editor.store = resources;
            }
          },
          beforeTaskEditShow({ taskRecord, editor }) {
            const rd = WidgetHelper.getById("readonlyCheck");
            if(rd.checked) {
              editor.widgetMap.deleteButton.disabled = true;
            } else {
              if (taskRecord.isParent) {
                editor.widgetMap.deleteButton.disabled = true;
              } else {
                editor.widgetMap.deleteButton.disabled = false;
              }
            }
          },
          beforeEventDelete: async function (data) {
            const res = await onItemDeleteTask(data);
            return res;
          },
          //gridRowBeforeDragStart: function(data) {
            //return data?.context?.records[0]?.isLeaf;
          //},
          renderTask: (data) => {
            if (data.taskRecord.isLeaf) {
              data.taskRecord.readOnly = false;
            } else {
              data.taskRecord.readOnly = false;
              data.taskRecord.constraintType = "";
            }
          },
          renderRows: (data) => {
            console.log("gantt is ready");
            if (projectRef.current) {
              // projectRef.current.stm.resetQueue();
              projectRef.current.clearChanges(true);
              resumeChangeTraking();
            }

            const savedTheme = getLocalStorage("userTheme");
            if (savedTheme) {
              DomHelper.setTheme(JSON.parse(savedTheme));
              let thmSelector = WidgetHelper.getById("theme-selector");
              if (thmSelector) {
                thmSelector.value = JSON.parse(savedTheme);
              }
            }
            let baseLineBtn = WidgetHelper.getById("createBaselinesBtn");
            if (baseLineBtn) {
              baseLineBtn.onAction = _createBaseLines;
            }
            // readonly on load
            // const rd = WidgetHelper.getById("readonlyCheck");
            // if(!rd.checked) {
            //   rd.checked = true;
            // }
          },
        },
      });
    }
  };

  const initialiseGantt = () => {
    createProjectAndGantt();
  };

  const loadGanttChart = () => {
    suspendChangeTraking();
    if (projectRef.current) {
      projectRef.current.inlineData = {
        tasksData: tasks,
        resourcesData: resources,
        dependenciesData: dependencies,
        assignmentsData: assignments,
        calendarsData: calendars,
      };

      const colLines = localStorage.getItem("columnLines");
      if (colLines) {
        ganttRef.current.features.columnLines.disabled = JSON.parse(colLines);
      }
      const dep = localStorage.getItem("dependencies");
      if (dep) {
        ganttRef.current.features.dependencies.disabled = JSON.parse(dep);
      }
      const rd = WidgetHelper.getById("readonlyCheck");
      const readOnlyState = userData.showEditButton;
      if (readOnlyState) {
        // show button and editable
        rd.checked = false;
        if (!rd.isVisible) {
          rd.hidden = false;
        }
      } else {
        // hide button and readonly
        rd.checked = true;
        if (rd.isVisible) {
          rd.hidden = true;
        }
        ganttRef.current.features.taskMenu.items.deleteTask.disabled = true;
      }
      // projectRef.current.stm.resetQueue();
      projectRef.current.clearChanges(true);
    }
  };

  useEffect(() => {
    if (
      userData &&
      userData.instanceUrl &&
      userData.token &&
      userData.projectId
    ) {
      getGanttData(userData.projectId);
    }
  }, [userData]);

  useEffect(() => {
    if (tasks.length > 0) {
      // document.getElementById('gantt-chart-container').innerHTML = '';
      loadGanttChart();
    }
  }, [tasks, resources, assignments]);

  useEffect(() => {
    // suspendChangeTraking();
    if(projectRef.current) {
      projectRef.current.inlineData = {
        dependenciesData: dependencies,
      };
      // projectRef.current.stm.resetQueue();
      projectRef.current.clearChanges(true);
      // resumeChangeTraking();
    }
  }, [dependencies]);

  useEffect(() => {
    GanttToolbar.initClass();
    StateProvider.setup('local');
    initialiseGantt();
    return () => {
      if(ganttRef.current){
        Object.keys(ganttRef.current._widgetMap).forEach(w => {
          if (ganttRef.current._widgetMap[w].doDestroy) {
            ganttRef.current._widgetMap[w].doDestroy();
          }
        });
      }
      if(syncTimerOpen > 0 && dataChangesRef.current){
        saveGanttDataList(dataChangesRef.current);
      }
    };
  }, []);

  const open = selectedTaskRecord ? true : false;

  return (
    <div>
      {loading && (
        <div className="gantt-loading-state">
          <CircularProgress />
        </div>
      )}

      <Grid container>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", height: `calc(100vh - 64px)` }}
          id="gantt-chart-container"
        ></Grid>
      </Grid>
      {syncTimerOpen > 0 && (
        <SaveTimer
          open={syncTimerOpen > 0}
          syncTimerOpen={syncTimerOpen}
          onCloseCallback={handleTimerClose}
        />
      )}
      {open && (
        <AssignResourcesDialog
          open={open}
          handleClose={handleClose}
          userData={userData}
          selectedTaskRecord={selectedTaskRecord}
          projectResources={projectResources}
          assignResources={assignResources}
        />
      )}
    </div>
  );
};

export default GanttChart;
