import React from "react";
import {
  Grid,
  Typography,
  List,
  Tooltip,
  IconButton,
  Box,
  Checkbox
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Draggable } from "react-beautiful-dnd";

import "./resources.scss";

import DroppableBox from "../../components/drag-drop/DroppableBox";
import { getInitialsByWords } from "../../utils";
import { SelectionCount } from "./index";

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const ResourceTab = (props) => {
  const { resources, handleDelete, selectedFromCrews, handleCrewCheckBox } = props;

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{ marginBottom: "1rem", border: "1px solid #ccc" }}
      >
        <Typography
          variant="subtitle1"
          gutterBottom
          className="crew-group-box-heading"
        >
          Unassigned to Crew
        </Typography>
        <DroppableBox id="projectResources" additionalStyle={{minWidth: undefined , maxWidth : undefined , padding : 8}}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <List sx={{ width: "100%" }}>
                {resources &&
                  resources.map((r, index) => (
                    <div key={r.Id} className="resource-div">
                      <Draggable
                        draggableId={`${r.Contact__c}-${index}-${r.Id}`}
                        crewId={`${r.Id}`}
                        index={index}
                      >
                        {(provided, snapshot) => {
                          const shouldShowSelection =
                          snapshot.isDragging && selectedFromCrews.length > 1;
                          return (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <>
                            <Box p={1} className="resource-box">
                              <Checkbox
                                edge="start"
                                tabIndex={-1}
                                disableRipple
                                sx={{ padding: "0 6px", verticalAlign: "top" }}
                                value={selectedFromCrews.includes(r.Contact__c)}
                                onChange={() => handleCrewCheckBox(r.Contact__c)}
                              />
                              <Typography
                                variant="body1"
                                className="resource-box-text"
                              >
                                {r.Contact__r.Name}
                              </Typography>
                              <Typography
                                component="span"
                                variant="body2"
                                sx={{
                                  display: "inline",
                                  background: "#ddd",
                                  padding: "2px 6px",
                                  borderRadius: "2px",
                                  marginLeft: "4px",
                                  verticalAlign: "top",
                                }}
                              >
                                {getInitialsByWords(
                                  r.Contact__r.Primary_Role__c
                                )}
                                <span className="resource-group-actions">
                                  <Tooltip title="Delete" placement="top" arrow>
                                    <IconButton
                                      aria-label="delete"
                                      onClick={() => {
                                        handleDelete("projectResources", r.Id);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </span>
                              </Typography>
                            </Box>
                            {shouldShowSelection ? (
                                <SelectionCount>
                                  {selectedFromCrews.length}
                                </SelectionCount>
                              ) : (
                                ""
                              )}
                            </>
                          </div>
                        )}}
                      </Draggable>
                    </div>
                  ))}
              </List>
            </Grid>
          </Grid>
        </DroppableBox>
      </Grid>
    </Grid>
  );
};

export default ResourceTab;
