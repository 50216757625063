import React from "react";
import Button from "@mui/material/Button";
import Alert from '@mui/material/Alert';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import { Box ,TextField, InputAdornment} from "@mui/material";
import { Search , Cancel } from "@mui/icons-material";
import { filter } from "lodash";

const CreateReportDialog = ({ reports, handleSave , ...props }) => {
  const {searchText , setSearchText ,searchListViewFunction} = props;
  const [open, setOpen] = React.useState(false);
  const [date, setDate] = React.useState(moment(new Date()));
  const [error, setError] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSetDate = e => {
    setError("");
    setDate(e);
  };

  const createReport = () => {
    const filterReport = filter(reports, (r) =>
      moment(moment(r.Date__c).format("YYYY-MM-DD")).isSame(
        date.format("YYYY-MM-DD")
      )
    );
    if (filterReport.length === 0) {
      handleSave(date);
      setError("");
      handleClose();
    } else {
      setError("Report for selected date already exists.");
    }
  };

  return (
    <div>
      <div style={{display:'flex' , gap:'0.3rem'}}>
        <TextField
          sx={{ "& .MuiInputBase-root": { color: "#505050", height: "37px", padding: "4px",  }, }}
          id="outlined-basic"
          variant="outlined"
          value={searchText}
          fullWidth
          className="Searchbar-field"
          autoComplete="off"
          placeholder="Search"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                {searchText && searchText.trim() != '' ? (
                  <Cancel
                    style={{ width: "20px", cursor: "pointer" }}
                    onClick={() => {
                      searchListViewFunction("");
                      setSearchText("");
                    }}
                  />
                ) : (
                  <Search style={{ width: "20px"}} />
                )}
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            //convert input text to lower case
            var lowerCase = e.target.value.toLowerCase();
            if (!lowerCase || lowerCase === null || lowerCase === "") {
              searchListViewFunction(lowerCase);
            }
            setSearchText(lowerCase);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              // Do code here
              e.preventDefault();
              var lowerCase = e.target.value.toLowerCase();
              //console.log('searchText ',searchText , 'lowerCase',lowerCase);
              searchListViewFunction(searchText);
            }
          }}
        />
        <Button variant="contained" onClick={handleClickOpen}>
          New
        </Button>
      </div>
      {open && 
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
          <DialogTitle>Create New Report</DialogTitle>
          <DialogContent dividers>
            <DialogContentText gutterBottom>Select Date</DialogContentText>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker value={date} onChange={handleSetDate} fullWidth />
            </LocalizationProvider>
            {/* <Box mt={2}>{moment(date).format("dddd, YYYY-MM-DD")}</Box> */}
            <Box mt={2}>{error && <Alert severity="error">{error}</Alert>}</Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant="contained" onClick={createReport}>
              Create
            </Button>
          </DialogActions>
        </Dialog>
        }
    </div>
  );
};

export default CreateReportDialog;
