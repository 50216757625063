import React, { useState } from "react";
import {
  ListItem,
  ListItemText,
  Typography,
  Tooltip,
  Checkbox
} from "@mui/material";

import "./resources.scss";

import { getInitialsByWords } from '../../utils/index';



export const ListItemDragable = ({ item, getIsChecked, findCrewNameByResource, checkSelectedResource }) => {
  const handleChecked = (e) => {
    checkSelectedResource(item.Id)
  }

  const checked = getIsChecked(item.Id);

  return (
    <ListItem
      alignItems="flex-start"
      sx={{
        paddingTop: 0,
        paddingBottom: 0,
        backgroundColor: checked ? "#cde9ff" : "#fff",
      }}
    >
      <Checkbox
        edge="start"
        tabIndex={-1}
        disableRipple
        sx={{ padding: "6px" }}
        checked={checked}
        onChange={handleChecked}
      />
      <ListItemText
        primary={item.Name}
        primaryTypographyProps={{
          component: "span",
          sx: { display: "inline" },
        }}
        sx={{ display: "inline" }}
        secondary={
          <Typography
            sx={{
              display: "inline",
              background: "#ddd",
              padding: "2px 6px",
              borderRadius: "2px",
              marginLeft: "4px",
            }}
            component="span"
            variant="body2"
            color="text.primary"
            title={item.Primary_Role__c}
          >
            {getInitialsByWords(item.Primary_Role__c)}
            <span className="resource-group-indicators">
              {item.projectResource && (
                <Tooltip title="Resource" placement="top" arrow>
                  <span className="resource">R</span>
                </Tooltip>
              )}{" "}
              {item.crewResource && (
                <Tooltip title="Crew" placement="top" arrow>
                  <span className="crew">{findCrewNameByResource(item)}</span>
                </Tooltip>
              )}
            </span>
          </Typography>
        }
      />
    </ListItem>
  );
};