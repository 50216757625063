import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton, Chip, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import ForumIcon from '@mui/icons-material/Forum';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';

const StyledAutocomplete = styled(Autocomplete)(() => ({
  background: "#fff",
  display: "inline-block",
  width: "85%",
  ".MuiOutlinedInput-root": {
    padding: 0,
    ".MuiAutocomplete-input": {
      padding: "1px 4px 1px 4px",
    },
  },
}));


const StyledCommentInput = styled(TextField)(() => ({
 
  background: "#fff",
  width: "75%",
  display: "inline-block",
  ".MuiInputBase-input": {
    padding: "1px 4px 1px 4px",
  }
}));

const StyledAutocompleteSubtask = styled(Autocomplete)(() => ({
  background: "#fff",
  width: "85%",
  ".MuiOutlinedInput-root": {
    padding: 0,
    border: "2px solid red",
    ".MuiAutocomplete-input": {
      padding: "1px 4px 1px 4px",
    },
  },
}));

const StyledInput = styled(TextField)(() => ({
  background: "#fff",
  width: "30%",
  ".MuiInputBase-input": {
    padding: "2px 8px",
  }
}));

const filter = createFilterOptions();

const TaskCard = ({
  taskData,
  tagsData,
  allTasksToggle,
  isReadOnly,
  handleSubtaskChange,
  handleAddTag,
  handleDeleteTag,
  handleDeleteTask,
  updateDurationVal,
  updateNotesVal
}) => {
  const [showSubtask, setShowSubtask] = useState(true);

  const [showTagSearch, setShowTagSearch] = useState(false);
  const [tag, setTag] = useState("");

  const [taskExpand, setTaskExpand] = useState(true);

  const [editDuration, setEditDuration] = useState(false);
  const [durationVal, setDurationVal] = useState(0);

  const [showComment, setShowComment] = useState(false);
  const [comment, setComment] = useState("");

  const subtaskDeleteHandler = () => {
    //console.log('taskData.siblings ::',JSON.stringify(taskData.siblings));
    setShowSubtask(false);
  };

  const toggleTaskExpand = () => {
    setTaskExpand(!taskExpand);
  };

  const toggleShowTagSearch = () => {
    setShowTagSearch(!showTagSearch);
  };

  const toggleCommentBox = (flag) => {
    setShowComment(!flag);
    if(flag) {
      // update comment
      if(taskData.notes !== comment) {
        updateNotesVal({ id: taskData.id, notes: comment });
      }
    }
  };

  const subtaskSelectHandler = (e, value, reason) => {
    if (reason === "selectOption") {
      handleSubtaskChange(value, taskData.id);
      // setSubtask(value.label);
      setShowSubtask(true);
    }
  };

  const tagDeleteHandler = (index) => {
    handleDeleteTag(index, taskData.id);
  };
  const handleTagChange = (e, newValue, reason) => {
    setTag(newValue);
    if (reason === "selectOption") {
      // update to assignment
      handleAddTag(newValue, taskData.id);
      setTimeout(() => {
        setTag("");
      }, 10);
    }
  };

  const showDurationEdit = () => {
    setEditDuration(true);
    setDurationVal(taskData.duration);
  };

  const handleDurationChange = (e) => {
    if(e.target.value > 99){
      setDurationVal(99);
      return;
    }
    setDurationVal(e.target.value);
  };

  const saveDuration = () => {
    updateDurationVal({ id: taskData.id, duration: durationVal });
    setEditDuration(false);
  };
  const cancelDuration = () => {
    setEditDuration(false);
    setDurationVal(taskData.duration);
  };

  useEffect(() => {
    setTaskExpand(allTasksToggle);
  }, [allTasksToggle]);

  useEffect(() => {
    if(taskData.notes) {
      setComment(taskData.notes);
    }
  }, [taskData]);

  const subtaskOptions = [];
  taskData.siblings.forEach((s) => {
    if (s.label && s.id) {
      subtaskOptions.push(s);
    }
  });

  return (
    <Box className="lh-task-card">
      <Box className="lh-task-size-toggle">
        <Tooltip title={taskExpand ? "Expand" : "Collapse"}>
          <IconButton
            size="small"
            sx={{ padding: "3px" }}
            onClick={toggleTaskExpand}
          >
            {!taskExpand && (
              <KeyboardDoubleArrowUpIcon
                fontSize="12px"
                sx={{ color: "#444" }}
              />
            )}
            {taskExpand && (
              <KeyboardDoubleArrowDownIcon
                fontSize="12px"
                sx={{ color: "#444" }}
              />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      <Typography
        variant="subtitle2"
        id="lh-parent-task"
        className="lh-parent-task"
      >
        {taskData.parentName}
        {showSubtask && (
          <Typography
            variant="body2"
            id="lh-child-task"
            className="lh-child-task"
            component="span"
          >
            {taskData.name}
            {!isReadOnly && (
              <IconButton
                aria-label="delete"
                size="small"
                onClick={subtaskDeleteHandler}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )}
          </Typography>
        )}{" "}
        &nbsp;
        {taskExpand &&  
          <>
            {taskData.allocation1Id &&  (
              <Chip
                label={taskData.allocation1Name}
                className="lh-task-chips-chip"
                size="small"
              />
            )}
            {taskData.allocation2Id &&  (
              <Chip
                label={taskData.allocation2Name}
                className="lh-task-chips-chip"
                size="small"
              />
            )}
            {taskData.allocation3Id &&  (
                <Chip
                label={taskData.allocation3Name}
                className="lh-task-chips-chip"
                size="small"
                />
            )}
          </>
        }
        {!editDuration && (
          <Chip
            title="Scheduled Hours"
            id={taskData.duration}
            label={taskData.duration + " Hr"}
            className="lh-task-chips-chip"
            size="small"
            onDoubleClick={showDurationEdit}
          />
        )}
      </Typography>
      {editDuration && (
        <>
          <StyledInput
            label="Duration"
            id="outlined-size-small-duration"
            size="small"
            type="number"
            value={durationVal}
            onChange={handleDurationChange}
          />
          <IconButton
            size="small"
            sx={{ padding: "3px" }}
            onClick={saveDuration}
            disabled={!durationVal || Number(durationVal) === 0}
          >
            <DoneIcon
              fontSize="inherit"
              color={durationVal && Number(durationVal) > 0 ? "success" : ""}
            />
          </IconButton>
          <IconButton
            size="small"
            sx={{ padding: "3px" }}
            onClick={cancelDuration}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </>
      )}

      {!showSubtask && (
        <StyledAutocompleteSubtask
          //disablePortal
          id="combo-box-subtask"
          // options={[...taskData.siblings]}
          getOptionLabel={(option) => option.label || ""}
          renderOption={(props, option) => (
            <div {...props} className="share-menu-item">
              <div className="share-menu-item-text">{option.label}</div>
            </div>
          )}
          options={subtaskOptions}
          placeholder="Search"
          onChange={subtaskSelectHandler}
          // value={subtask}
          renderInput={(params) => <TextField {...params} />}
        />
      )}
      <Box className="lh-task-chips"></Box>

      {!taskExpand && (
        <Box className="lh-task-chips">
          {taskData.tags.map((t, index) => (
            <Chip
              id={t}
              label={t}
              className="lh-task-chips-chip"
              size="small"
              onDelete={() => tagDeleteHandler(index)}
            />
          ))}
          {/* tags search button */}
          {/* {!showTagSearch && (
            <IconButton size="small" onClick={toggleShowTagSearch}>
              <SearchIcon fontSize="12px" sx={{ color: "orange" }} />
            </IconButton>
          )} */}
          {showTagSearch && (
            <Box>
              <StyledAutocomplete
                //disablePortal
                options={[...tagsData]}
                placeholder="Add tag"
                value={tag}
                onChange={handleTagChange}
                onKeyDown={(e) => {
                  if (e.target.value && e.target.value.trim() !== "") {
                    if (e.key === "Enter") {
                      // Do code here
                      e.preventDefault();
                      handleTagChange(e, e.target.value.trim(), "selectOption");
                    }
                  }
                }}
                blurOnSelect
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.unshift(inputValue);
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(props, option) => <li {...props}>{option}</li>}
                freeSolo
                renderInput={(params) => (
                  <TextField {...params} placeholder="Add tag" />
                )}
              />
              <IconButton size="small" onClick={toggleShowTagSearch}>
                <HighlightOffIcon fontSize="12px" sx={{ color: "orange",paddingBottom:"6px" }} />
              </IconButton>
            </Box>
          )}
           <Box className="lh-task-chips"></Box>
            {showComment &&  (
            <Box>
              <StyledCommentInput size="small" value={comment} onChange={(e) => setComment(e.target.value)}  
  
  
  multiline
  minRows={1}
  maxRows={10}
  variant="outlined"
/>
            </Box>
          )}
          
        </Box>
      )}

      <Box className="lh-delete-assignment">
        {/* comments */}
        
        {!taskExpand  && (
          <IconButton size="small" onClick={() => toggleCommentBox(showComment)} className="lh-task-chips"  >
            {!showComment && <ForumIcon fontSize="10px" sx={{ color: comment ? "#5a9cff" : "#444" }} />}
            {showComment && <MarkChatReadIcon fontSize="10px" sx={{ color: "#5a9cff" }} />}
          </IconButton>
        )}
      
        
        {/* tags search button */}
        {!taskExpand && !showTagSearch && !isReadOnly && (
          <IconButton size="small" onClick={toggleShowTagSearch}>
            <SearchIcon fontSize="12px" sx={{ color: "#444" }} />
          </IconButton>
        )}
        {!isReadOnly && (
          <IconButton
            aria-label="delete"
            size="small"
            className="lh-delete-assignment-btn"
            onClick={() => handleDeleteTask(taskData.id)}
          >
            <DeleteIcon fontSize="12px" />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default TaskCard;
