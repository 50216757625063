import {
  Toolbar,
  DateHelper,
  Toast,
  DomHelper,
  WidgetHelper,
} from "@bryntum/gantt";

import { saveLocalStorage } from "../../utils/index";
import moment from "moment";

/**
 * @module GanttToolbar
 */

/**
 * @extends Core/widget/Toolbar
 */
export class GanttToolbar extends Toolbar {
  // Factoryable type name
  static get type() {
    return "gantttoolbar";
  }

  static get $name() {
    return "GanttToolbar";
  }

  static get configurable() {
    return {
      items: [
        {
          id: "readonlyCheck",
          type: "checkbox",
          ref: "readonlyCheck",
          label: "Read Only",
          onChange: "up.onReadOnlyChange",
          checked: true,
          hidden: true
        },   
        {
          type: "buttonGroup",
          items: [      
            {
              id: "addTaskButton",
              color: "b-green",
              ref: "addTaskButton",
              icon: "b-fa b-fa-plus",
              text: "Create",
              tooltip: "Create new task",
              onAction: "up.onAddTaskClick",
              disabled: true,
            },
            {
              color: "b-blue",
              ref: "editTaskButton",
              icon: "b-fa b-fa-pen",
              text: "Edit",
              tooltip: "Edit task",
              onAction: "up.onEditTaskClick",
            },
          ],
        },
        {
          ref: "undoRedo",
          type: "undoredo",
          items: {
            transactionsCombo: null,
          },
        },
        /*{
          type: "button",
          id: "createBaselinesBtn",
          text: "Create Baselines",
          tooltip: "Create Baselines",
          // onAction: "up.createBaseLines",
        },*/
        {
          type: "buttonGroup",
          items: [
            {
              ref: "expandAllButton",
              icon: "b-fa b-fa-angle-double-down",
              tooltip: "Expand all",
              onAction: "up.onExpandAllClick",
            },
            {
              ref: "collapseAllButton",
              icon: "b-fa b-fa-angle-double-up",
              tooltip: "Collapse all",
              onAction: "up.onCollapseAllClick",
            },
          ],
        },
        {
          type: "buttonGroup",
          items: [
            {
              ref: "zoomInButton",
              icon: "b-fa b-fa-search-plus",
              tooltip: "Zoom in",
              onAction: "up.onZoomInClick",
            },
            {
              ref: "zoomOutButton",
              icon: "b-fa b-fa-search-minus",
              tooltip: "Zoom out",
              onAction: "up.onZoomOutClick",
            },
            {
              ref: "zoomToFitButton",
              icon: "b-fa b-fa-compress-arrows-alt",
              tooltip: "Zoom to fit",
              onAction: "up.onZoomToFitClick",
            },
            {
              ref: "previousButton",
              icon: "b-fa b-fa-angle-left",
              tooltip: "Previous time span",
              onAction: "up.onShiftPreviousClick",
            },
            {
              ref: "nextButton",
              icon: "b-fa b-fa-angle-right",
              tooltip: "Next time span",
              onAction: "up.onShiftNextClick",
            },
          ],
        },
        // {
        //   type: "button",
        //   text: "Go to current date",
        //   tooltip: "Go to current date",
        //   onAction: "up.goToCurrentDate",
        // },
        // {
        //   type: "viewpresetcombo",
        // },

        /*{
          type: "datefield",
          ref: "startDateField",
          label: "Project start",
          required: false, //(done on load)
          flex: "0 0 15em",
          listeners: {
            change: "up.onStartDateChange",
          },
        },*/
        {
          type: "textfield",
          ref: "filterByName",
          cls: "filter-by-name",
          flex: "0 0 23em",
          // Label used for material, hidden in other themes
          label: "Find tasks by name",
          // Placeholder for others
          placeholder: "Find tasks by name",
          clearable: true,
          keyStrokeChangeDelay: 100,
          triggers: {
            filter: {
              align: "end",
              cls: "b-fa b-fa-filter",
            },
          },
          onChange: "up.onFilterChange",
        },
        {
          type: "datefield",
          ref: "startDateFilter",
          label: "Start Date",
          flex: "0 0 18em",
          clearable: true,
          listeners: {
            change: "up.onRangeFIlterChange",
          },
        },
        {
          type: "datefield",
          ref: "endDateFilter",
          label: "End Date",
          flex: "0 0 18em",
          clearable: true,
          listeners: {
            change: "up.onRangeFIlterChange",
          },
        },
        // "->",
        /*{
          type: "combo",
          id: "theme-selector",
          valueField: "id",
          displayField: "text",
          items: [
            "Stockholm",
            "Material",
            "Classic-Light",
            "Classic",
            "Classic-Dark",
          ].map((name) => {
            return {
              id: name.toLowerCase(),
              text: name,
            };
          }),
          value: "stockholm",
          label: "Theme",
          listeners: {
            change: "up.onThemeChange",
          },
        },*/
        "->",
        {
          type: "button",
          ref: "featuresButton",
          icon: "b-fa b-fa-tasks",
          //text: "Features",
          tooltip: "Toggle features",
          menu: {
            onItem: "up.onFeaturesClick",
            onBeforeShow: "up.onFeaturesShow",
            // "checked" is set to a boolean value to display a checkbox for menu items. No matter if it is true or false.
            // The real value is set dynamically depending on the "disabled" config of the feature it is bound to.
            items: [
              // {
              //   text: "UI settings",
              //   icon: "b-fa-sliders-h",
              //   menu: {
              //     cls: "settings-menu",
              //     layoutStyle: {
              //       flexDirection: "column",
              //     },
              //     onBeforeShow: "up.onSettingsShow",
              //     defaults: {
              //       type: "slider",
              //       showValue: true,
              //     },
              //     items: [
              //       {
              //         ref: "rowHeight",
              //         text: "Row height",
              //         min: 30,
              //         max: 70,
              //         onInput: "up.onRowHeightChange",
              //       },
              //       {
              //         ref: "barMargin",
              //         text: "Bar margin",
              //         min: 0,
              //         max: 10,
              //         onInput: "up.onBarMarginChange",
              //       },
              //       {
              //         ref: "duration",
              //         text: "Animation duration",
              //         min: 0,
              //         max: 2000,
              //         step: 100,
              //         onInput: "up.onAnimationDurationChange",
              //       },
              //       {
              //         ref: "radius",
              //         text: "Dependency radius",
              //         min: 0,
              //         max: 10,
              //         onInput: "up.onDependencyRadiusChange",
              //       },
              //     ],
              //   },
              // },
              {
                text: "Draw dependencies",
                feature: "dependencies",
                checked: true,
              },
              // {
              //   text: "Task labels",
              //   feature: "labels",
              //   checked: true,
              // },
              // {
              //   text: "Critical paths",
              //   feature: "criticalPaths",
              //   tooltip: "Highlight critical paths",
              //   checked: false,
              // },
              // {
              //   text: "Project lines",
              //   feature: "projectLines",
              //   checked: false,
              // },
              // {
              //   text: "Highlight non-working time",
              //   feature: "nonWorkingTime",
              //   checked: false,
              // },
              // {
              //   text: "Enable cell editing",
              //   feature: "cellEdit",
              //   checked: true,
              // },
              {
                text: "Show column lines",
                feature: "columnLines",
                checked: false,
              },
              // {
              //   text: "Show baselines",
              //   feature: "baselines",
              //   checked: false,
              // },
              // {
              //   text: "Show rollups",
              //   feature: "rollups",
              //   checked: false,
              // },
              // {
              //   text: "Show progress line",
              //   feature: "progressLine",
              //   checked: false,
              // },
              // {
              //   text: "Show parent area",
              //   feature: "parentArea",
              //   checked: false,
              // },
               {
                text: "Stretch tasks to fill ticks",
                toggleConfig: "fillTicks",
                checked: false,
              },
              // {
              //   text: "Hide schedule",
              //   cls: "b-separator",
              //   subGrid: "normal",
              //   checked: false,
              // },
            ],
          },
        },
      ], // end items
    };
  }

  // Called when toolbar is added to the Gantt panel
  updateParent(parent, was) {
    super.updateParent(parent, was);

    this.gantt = parent;

    this.styleNode = document.createElement("style");
    document.head.appendChild(this.styleNode);
  }

  // region controller methods
  onExpandAllClick() {
    sessionStorage.setItem("expandAll", true);
    this.gantt.expandAll();
  }

  onCollapseAllClick() {
    sessionStorage.setItem("expandAll", false);
    this.gantt.collapseAll();
  }

  onZoomInClick() {
    this.gantt.zoomIn();
  }

  onZoomOutClick() {
    this.gantt.zoomOut();
  }

  onZoomToFitClick() {
    this.gantt.zoomToFit({
      leftMargin: 50,
      rightMargin: 50,
    });
  }

  onShiftPreviousClick() {
    this.gantt.shiftPrevious();
  }

  onShiftNextClick() {
    this.gantt.shiftNext();
  }

  async onAddTaskClick() {
    const { gantt } = this,
      added = gantt.taskStore.rootNode.appendChild({
        name: this.L("New task"),
        newTask: true,
        duration: 1,
        durationUnit: "days",
        effort: 0,
        /*projectId : userData.projectId,*/
      });

    // run propagation to calculate new task fields
    // await gantt.project.commitAsync();

    if (!added.startDate) {
      added.startDate = new Date();
    }
    // scroll to the added task
    await gantt.scrollRowIntoView(added);
    // gantt.editTask(added);
    gantt.features.cellEdit.startEditing({
      record: added,
      field: "name",
    });
  }

  onEditTaskClick() {
    const { gantt } = this;

    if (gantt.selectedRecord) {
      gantt.editTask(gantt.selectedRecord);
    } else {
      Toast.show(this.L("First select the task you want to edit"));
    }
  }

  onStartDateChange({ value, oldValue }) {
    if (value) {
      this.gantt.scrollToDate(DateHelper.add(value, -1, "week"), {
        block: "start",
      });

      // this.gantt.project.setStartDate(value);
    }
  }

  // createBaseLines() {
  //   console.log('create baselines click');
  // }

  onFilterChange({ value }) {
    if (value === "") {
      this.gantt.taskStore.clearFilters();
    } else {
      value = value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

      this.gantt.taskStore.filter({
        filters: (task) => task.name && task.name.match(new RegExp(value, "i")),
        replace: true,
      });
    }
  }

  onRangeFIlterChange(data) {
    const { startDateFilter, endDateFilter } = this.widgetMap;
    if (startDateFilter.value && endDateFilter.value) {
      if (moment(startDateFilter.value).isBefore(moment(endDateFilter.value))) {
        this.gantt.taskStore.filter({
          filters: (task) => {
            let filterStartDate = moment(startDateFilter.value).format(
              "YYYY-MM-DD"
            );
            let filterEndDate = moment(endDateFilter.value).format(
              "YYYY-MM-DD"
            );
            let taskStartDate = moment(task.startDate).format("YYYY-MM-DD");
            let taskEndDate = moment(task.endDate).format("YYYY-MM-DD");
            // return moment(task.startDate).isBetween(
            //   moment(startDateFilter.value).format("YYYY-MM-DD"),
            //   moment(endDateFilter.value).format("YYYY-MM-DD"),
            //   "[]"
            // ) ||
            // moment(task.endDate).isBetween(
            //   moment(startDateFilter.value).format("YYYY-MM-DD"),
            //   moment(endDateFilter.value).format("YYYY-MM-DD"),
            //   "[]"
            // );
            return (
              (moment(filterStartDate).isSameOrBefore(taskStartDate, "date") &&
                moment(filterEndDate).isSameOrBefore(taskEndDate, "date") &&
                moment(filterEndDate).isSameOrAfter(taskStartDate)) ||
              (moment(filterStartDate).isSameOrAfter(taskStartDate, "date") &&
                moment(filterEndDate).isSameOrBefore(taskEndDate, "date")) ||
              (moment(filterStartDate).isSameOrBefore(taskStartDate, "date") &&
                moment(filterEndDate).isSameOrAfter(taskEndDate, "date"))
            );
          },
          replace: true,
        });
      } else {
        Toast.show({ html: "Invalid date range", color: "b-red" });
        this.gantt.taskStore.clearFilters();
      }
    } else if (startDateFilter.value) {
      this.gantt.taskStore.filter({
        filters: (task) =>
          moment(task.startDate).isSameOrAfter(
            moment(startDateFilter.value).format("YYYY-MM-DD")
          ),
        replace: true,
      });
    } else if (endDateFilter.value) {
      this.gantt.taskStore.filter({
        filters: (task) =>
          moment(task.endDate).isSameOrBefore(
            moment(endDateFilter.value).format("YYYY-MM-DD")
          ),
        replace: true,
      });
    } else {
      // clear filter
      this.gantt.taskStore.clearFilters();
    }
  }

  onFeaturesClick({ source: item }) {
    const { gantt } = this;
    if (item.feature) {
      const feature = gantt.features[item.feature];
      feature.disabled = !feature.disabled;
      localStorage.setItem(item.feature, feature.disabled);
    } else if (item.subGrid) {
      const subGrid = gantt.subGrids[item.subGrid];
      subGrid.collapsed = !subGrid.collapsed;
    } else if (item.toggleConfig) {
      gantt[item.toggleConfig] = item.checked;
    }
  }

  onFeaturesShow({ source: menu }) {
    const { gantt } = this;

    menu.items.map((item) => {
      const { feature } = item;

      if (feature) {
        // a feature might be not presented in the gantt
        // (the code is shared between "advanced" and "php" demos which use a bit different set of features)
        if (gantt.features[feature]) {
          item.checked = !gantt.features[feature].disabled;
        }
        // hide not existing features
        else {
          item.hide();
        }
      } else if (item.subGrid) {
        item.checked = gantt.subGrids[item.subGrid].collapsed;
      }
    });
  }

  onSettingsShow({ source: menu }) {
    const { gantt } = this,
      { rowHeight, barMargin, duration, radius } = menu.widgetMap;

    rowHeight.value = gantt.rowHeight;
    barMargin.value = gantt.barMargin;
    barMargin.max = gantt.rowHeight / 2 - 5;
    duration.value = gantt.transitionDuration;
    radius.value = gantt.features.dependencies.radius ?? 0;
  }

  onRowHeightChange({ value, source }) {
    this.gantt.rowHeight = value;
    source.owner.widgetMap.barMargin.max = value / 2 - 5;
  }

  onBarMarginChange({ value }) {
    this.gantt.barMargin = value;
  }

  onAnimationDurationChange({ value }) {
    this.gantt.transitionDuration = value;
    this.styleNode.innerHTML = `.b-animating .b-gantt-task-wrap { transition-duration: ${
      value / 1000
    }s !important; }`;
  }

  onDependencyRadiusChange({ value }) {
    this.gantt.features.dependencies.radius = value;
  }

  onThemeChange(data) {
    DomHelper.setTheme(data.value);
    saveLocalStorage("userTheme", data.value);
  }

  onReadOnlyChange(data) {
    // localStorage.setItem("ganttReadonly", data.checked);
    if (data.checked) {
      // readonly
      // disable -- add delete indent outdent and column sorting
      this.gantt.features.sort.disabled = false;
      WidgetHelper.getById("addTaskButton").disabled = true;
      this.gantt.features.taskMenu.items.deleteTask.disabled = true;
      this.gantt.features.taskMenu.items.add.disabled = true;
      this.gantt.features.taskMenu.items.indent.disabled = true;
      this.gantt.features.taskMenu.items.outdent.disabled = true;
    } else {
      // reset and allow edit
      // enable --  add delete indent outdent and column sorting
      this.gantt.taskStore.sort({ field: "wbsValue", ascending: true });
      this.gantt.features.sort.disabled = true;

      WidgetHelper.getById("addTaskButton").disabled = false;
      this.gantt.features.taskMenu.items.deleteTask.disabled = false;
      this.gantt.features.taskMenu.items.add.disabled = false;
      this.gantt.features.taskMenu.items.indent.disabled = false;
      this.gantt.features.taskMenu.items.outdent.disabled = false;
    }
  }
  // endregion
  // onSaveChanges() {
  //   console.log('on save chages triggred');
  //   const { gantt } = this;
  //   console.log('************** gantt is ', gantt);
  // }
}
