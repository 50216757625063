import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormHelperText,
  Button,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import MuiDialog from "components/dialog/MuiDialog";
import { fetchVarianceData } from "./api/lookaheadApis";
import { setVarianceData } from "./services/LookaheadDataSlice";

const DeleteAssignmentDialog = ({
  assignId,
  isReplaceOp = false,
  open,
  handleClose,
  handleDeleteWithVariance,
}) => {
  const dispatch = useDispatch();

  const { variance = [] } = useSelector((state) => state.lookaheadData);

  const [reason, setReason] = useState("");
  const [note, setNote] = useState("");
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setReason(e.target.value);
    setError("");
  };

  const handleDelete = () => {
    if (!reason) {
      setError("Please complete this field.");
      return false;
    }

    handleDeleteWithVariance({
      assignId: assignId,
      isReplaceOp: isReplaceOp,
      Reason_for_Variance__c: reason,
      Reason_for_Variance_Notes__c: note,
    });
  };

  const fetchVariance = async () => {
    try {
      const res = await fetchVarianceData();
      dispatch(setVarianceData(res.pickObj.Reason_for_Variance__c.valueList));
    } catch (error) {}
  };

  useEffect(() => {
    if (variance.length === 0) {
      fetchVariance();
    }
  }, []);

  const actionsButtons = (
    <>
      <Button variant="contained" onClick={handleClose}>
        Cancel
      </Button>
      <Button variant="contained" onClick={handleDelete}>
        {isReplaceOp ? "Replace" : "Delete"}
      </Button>
    </>
  );

  return (
    <MuiDialog
      open={open}
      handleClose={handleClose}
      title="Reason of Variance"
      actions={actionsButtons}
    >
      <Box p={2} gap={2} display="flex">
        <FormControl fullWidth error={error ? true : false}>
          <InputLabel id="demo-simple-select-label">
            Reason for Variance
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={reason}
            label="Age"
            onChange={handleChange}
            placeholder="Select"
          >
            {variance.map((v) => (
              <MenuItem key={v} value={v}>
                {v}
              </MenuItem>
            ))}
          </Select>
          {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
        <TextField
          id="outlined-multiline-flexible"
          label="Reason for Variance Notes"
          multiline
          maxRows={6}
          value={note}
          onChange={(e) => setNote(e.target.value)}
          fullWidth
        />
      </Box>
    </MuiDialog>
  );
};

export default DeleteAssignmentDialog;
