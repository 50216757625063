import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import moment from "moment";

const ImportReport = ({ reports, selectedReport, handleImport,syncTimerOpen }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const [error, setError] = useState("");
  const Status= selectedReport.Status__c;
  const handleImportData = () => {
    if(selected) { 
      handleImport(selected, selectedReport.Id);
      setOpen(false);
      // api inttegration to import
      // reload data 
    } else {
      setError("Please select report to import");
    }
  };

  const reportOptions = reports.map((r) => ({ name: `${r.Name} - ${moment(r.Date__c).format("MM/DD/YYYY")}`, id: r.Id }));

  return (
    <>
      {/* <Button variant="contained" onClick={() => {setOpen(true)}}   sx={{ display: Status === "Completed" ? "none" : "inline-block" }}
      > */}
      <Button variant="contained" disabled= {syncTimerOpen > 0}  onClick={() => {setOpen(true)}}>
        Import
      </Button>
      {open && (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle>Import Report</DialogTitle>
          <DialogContent dividers>
            <DialogContentText gutterBottom>
              Importing tasks from a previous daily report will delete all existing project tasks on your current daily report. Do you want to proceed?
            </DialogContentText>
            <FormControl fullWidth>
              <InputLabel id="acr">Daily Report Date</InputLabel>
              <Select
                labelId="acr"
                id="acr-select"
                label="select report"
                value={selected}
                onChange={(e) => {
                  setError("");
                  setSelected(e.target.value);                
                }}
                error={error}
                required
              >
                {reportOptions.filter((f) => f.id !== selectedReport.Id).map((r) => (
                  <MenuItem key={r.id} value={r.id}>         
                    {r.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {setOpen(false)}}>Cancel</Button>
            <Button variant="contained" onClick={handleImportData}>Import</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ImportReport;
