import React from "react";
import { useLocation } from "react-router-dom";
const Login = () => {
  const {state} = useLocation();
  React.useEffect(() => {
    //console.log('state ',state);
    let loc = `/auth/login?route=inApp`
    if(state?.queryTo){
      loc += `&${state?.queryTo}`
    }
    window.location = loc;
  }, []);
  return (
    <>
      Login To Salesforce
    </>
  );
};
export default Login;
