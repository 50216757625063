import { fetchUrl } from "../../../Urls";
import axios from "axios";

export const fetchSchedulerData = async (query) => {
  return await axios
    .get(`${fetchUrl}/get_company_assignments${query}`)
    .then((res) => {
      if (res?.status === 200) {
        return res.data || {};
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchCompanyProjectsData = async (query) => {
  return await axios
    .get(`${fetchUrl}/get_active_projects${query}`)
    .then((res) => {
      if (res?.status === 200) {
        return res.data || {};
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const saveSchedulerEvent = async (query, saveObj) => {
  return await axios
    .post(`${fetchUrl}/recSave${query}`, { recObj: saveObj })
    .then((res) => {
      if (res?.status === 200) {
        return res.data || {};
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteSchedulerEvent = async (query) => {
  return await axios
    .delete(`${fetchUrl}/delete${query}`)
    .then((res) => {
      if (res?.status === 200) {
        return res.data || {};
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
