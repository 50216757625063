import { fetchUrl } from "../../../Urls";
import axios from "axios";

export const fetchLookaheadData = async (projectId, reqbody) => {
  return await axios
    .post(`${fetchUrl}/get_lookahead_data/${projectId}`, reqbody)
    .then((res) => {
      if (res?.status === 200) {
        return res.data || {};
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
export const fetchWBSData = async (projectId, reqbody) => {
  return await axios
    .post(`${fetchUrl}/get_wbs_data/${projectId}`, reqbody)
    .then((res) => {
      if (res?.status === 200) {
        return res.data || {};
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const saveSchedule = async(queryObj, saveObj) => {
  return await axios
  .post(`${fetchUrl}/recSave/Project_Task__c${queryObj}`, saveObj)
  .then((res) => {
    if (res?.status === 200) {
      return res.data || {};
    }
  })
  .catch((err) => {
    console.log(err);
    throw err;
  });
};

export const saveAssignmentsDataList = async(saveObj) => {
  return await axios
  .post(`${fetchUrl}/recSave/list/Project_Task__c`, saveObj)
  .then((res) => {
    if (res?.status === 200) {
      return res.data || {};
    }
  })
  .catch((err) => {
    console.log(err);
    throw err;
  });
};

export const deleteAsignments = async(recIds) => {
  return await axios
  .post(`${fetchUrl}/delete/Project_Task__c`, { recIds })
  .then((res) => {
    if (res?.status === 200) {
      return res.data || {};
    }
  })
  .catch((err) => {
    console.log(err);
    throw err;
  });
};
export const deleteProjectAsignments = async(resourceIds, projectId) => {
  return await axios
  .post(`${fetchUrl}/removeAssignments/${projectId}`,{ resourceIds })
  .then((res) => {
    if (res?.status === 200) {
      return res.data || {};
    }
  })
  .catch((err) => {
    console.log(err);
    throw err;
  });
};

export const fetchTags = async(projectId) => {
  return await axios.get(`${fetchUrl}/get_project_tags/${projectId}`)
  .then((res) => {
    if (res?.status === 200) {
      return res.data || [];
    }
  })
  .catch((err) => {
    console.log(err);
    throw err;
  });
};

export const fetchHolidays = async(projectId) => {
  return await axios.get(`${fetchUrl}/holidays`)
  .then((res) => {
    if (res?.status === 200) {
      return res.data || [];
    }
  })
  .catch((err) => {
    console.log(err);
    throw err;
  });
};

export const fetchCrews = async(projectId) => {
  return await axios.get(`${fetchUrl}/get_crew/${projectId}`)
  .then((res) => {
    if (res?.status === 200) {
      return res.data || [];
    }
  })
  .catch((err) => {
    console.log(err);
    throw err;
  });
};

export const saveTagsList = async(queryObj, saveObj) => {
  return await axios
  .post(`${fetchUrl}/recSave/list/Project_Tag__c${queryObj}`, saveObj)
  .then((res) => {
    if (res?.status === 200) {
      return res.data || {};
    }
  })
  .catch((err) => {
    console.log(err);
    throw err;
  });
};

export const fetchVarianceData = async() => {
  return await axios.post(`${fetchUrl}/picklist/valueObj/Project_Task__c`, { fields: ["Reason_for_Variance__c"]})
  .then((res) => {
    if (res?.status === 200) {
      return res.data || [];
    }
  })
  .catch((err) => {
    console.log(err);
    throw err;
  });
};

export const addProjResource = async(reqBody) => {
  return await axios
      .post(
        `${fetchUrl}/recSave/list/Project_Assignment__c`,
        reqBody
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return;
      });
};
