import { configureStore } from '@reduxjs/toolkit';
import ganttDataReducer from './pages/Gantt/services/GanttDataSlice';
import userDataReducer from './pages/Home/services/UserDataSlice';
import lookaheadDataReducer from './pages/Lookahead/services/LookaheadDataSlice';
import wbsDataReducer from "./pages/Lookahead/services/WbsDataSlice";

export const store = configureStore({
  reducer: {
    userData: userDataReducer,
    ganttData: ganttDataReducer,
    lookaheadData: lookaheadDataReducer,
    wbsData: wbsDataReducer
  }
});
