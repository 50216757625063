import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";

import { GetToken } from "../../api";
import { setUserData } from "../Home/services/UserDataSlice";

const Main = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let location = useLocation();

  const { userData = {} } = useSelector((state) => state.userData);

  async function getCreditials() {
    const data = await GetToken();
    return data ? data : {};
  }

  // check user auth
  const userAuthCheck = async () => {
    const response = await getCreditials();
    //console.log("data is ", response);
    const { data } = response;
    var pathName = location.pathname === "/" ? "/lookahead" : location.pathname;
    const queryObj = queryStringToObject(location.search);
    if(queryObj && queryObj.redirectTo && queryObj.redirectTo.trim() !== ''){
      pathName = '/'+queryObj.redirectTo;
    }
    //console.log('pathName ',pathName);
    if (data?.instanceUrl && data?.token) {
      if(data?.projectId){
        pathName += '?projectId='+data?.projectId;
      }
      dispatch(setUserData(data));
      navigate(pathName);
    } else {
      console.log("failed to login");
      let queryTo;
      if(queryObj && queryObj.projectId && queryObj.projectId.trim() !== ''){
        queryTo = 'projectId='+queryObj.projectId;
      }
      navigate('/login', { state: { queryTo: queryTo } });
    }
  };
  function queryStringToObject(queryString) {
    var obj;
    if(queryString && queryString.trim() != ''){
      obj ={};
      if (queryString.charAt(0) === '?') {
        queryString = queryString.substring(1);
      }
      var pairs = queryString.split('&');
      
      for (var i = 0; i < pairs.length; i++) {
        // Split the pair into key and value
        var pair = pairs[i].split('=');
        var key = decodeURIComponent(pair[0]);
        var value = decodeURIComponent(pair[1] || '');
        obj[key] = value;
      }
    }
    return obj;
  }

  useEffect(() => {
    if (isEmpty(userData)) {
      userAuthCheck();
    }
    return () => {
      console.log("*****unmounting index page**********");
    };
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Field Modules
          </Typography>
          {userData?.projectId && <>
            <Button
              color="inherit"
              onClick={() => {
                navigate("/gantt?projectId="+userData?.projectId);
              }}
              disabled={!userData?.instanceUrl || !userData?.projectId}
            >
              Gantt
            </Button>
            {/*<Button
            color="inherit"
            onClick={() => {
              navigate("/gantt-split");
            }}
            disabled={!userData?.instanceUrl}
          >
            Gantt Split
          </Button>*/}
            {/* <Button
            color="inherit"
            onClick={() => {
              navigate("/gantt-import");
            }}
            disabled={!userData?.instanceUrl}
          >
            Gantt Import
          </Button> */}
            {/*<Button
            color="inherit"
            onClick={() => {
              navigate("/scheduler2");
            }}
            disabled={!userData.instanceUrl}
          >
            Scheduler
          </Button>*/}
            <Button
              color="inherit"
              onClick={() => {
                navigate("/lookahead?projectId="+userData?.projectId);
              }}
              disabled={!userData.instanceUrl || !userData?.projectId}
            >
              Lookahead
            </Button>
            <Button
              color="inherit"
              onClick={() => {
                navigate("/daily-report?projectId="+userData?.projectId);
              }}
              disabled={!userData.instanceUrl || !userData?.projectId}
            >
              Daily Report
            </Button>
            <Button
              color="inherit"
              className="gant-nav-btn"
              onClick={() => {
                navigate("/resources?projectId="+userData?.projectId);
              }}
              disabled={!userData?.instanceUrl || !userData?.projectId}
            >
              Resources
            </Button>
            {/*<Button
            color="inherit"
            onClick={() => {
              navigate("/tasksbycrew");
            }}
            disabled={!userData.instanceUrl}
          >
            Tasks By Crew
          </Button>*/}
          </>}
        </Toolbar>
      </AppBar>
      <main className="main-wrapper">
        {userData &&
          userData.instanceUrl &&
          userData.token && <Outlet />}
        {!(
          userData &&
          userData.instanceUrl &&
          userData.token
        ) && <div>Authenticating...</div>}
      </main>
    </Box>
  );
};

export default Main;
