import React, { useEffect, useState } from "react";
import { Grid, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { Gantt, DateHelper, AjaxHelper, Toast } from "@bryntum/gantt";

import { Importer } from "./Importer";

// Project loader script location
const projectLoaderScript =
  "https://dev.bryntum.com/examples/gantt-vanilla/examples/msprojectimport/php/load.php";

const GanttImport = () => {
  const { userData = {} } = useSelector((state) => state.userData);

  const [loading, setLoading] = useState(false);

  const initialiseGantt = () => {
    const gantt = new Gantt({
      appendTo: "gantt-import-container",
      emptyText: "Drop a project file here to import it",
      startDate: DateHelper.add(
        DateHelper.parse(new Date(), "YYYY-MM-DD"),
        -20,
        "days"
      ),
      endDate: DateHelper.add(
        DateHelper.parse(new Date(), "YYYY-MM-DD"),
        +10,
        "days"
      ),

      subGridConfigs: {
        locked: {
          flex: 3,
        },
        normal: {
          flex: 4,
        },
      },

      features: {
        baselines: true,
        // A demo feature allowing dropping the file onto the Gantt element to trigger import
        fileDrop: true,
      },

      dependencyIdField: "sequenceNumber",

      columns: [
        { type: "name", field: "name", text: "Name", width: 250 },
        { type: "startdate" },
        { type: "duration" },
        { type: "addnew" },
      ],

      viewPreset: "weekAndDayLetter",

      tbar: [
        {
          type: "filepicker",
          ref: "input",
          buttonConfig: {
            text: "Pick a project file",
            icon: "b-fa-folder-open",
          },
          fileFieldConfig: {
            // Uncomment to only allow picking certain files
            // accept : '.mpp'
          },
          listeners: {
            change: ({ files }) => {
              sendBtn.disabled = files.length === 0;
            },
            clear: () => {
              sendBtn.disable();
            },
          },
        },
        {
          type: "button",
          ref: "sendBtn",
          text: "Import data",
          cls: "b-load-button b-blue",
          icon: "b-fa-file-import",
          disabled: true,
          onClick: "up.onImportButtonClick",
        },
        {
          type: "button",
          href: "sampleproject.mpp",
          target: "_blank",
          text: "Download a sample MPP file",
          icon: "b-fa-file-download",
        },
      ],

      onFileDrop({ file }) {
        this.importFile(file);
      },

      onImportButtonClick() {
        const { files } = input;

        if (files) {
          this.importFile(files[0]);
        }
      },

      async importFile(file) {
        const formData = new FormData();

        formData.append("mpp-file", file);

        gantt.maskBody("Importing project ...");

        try {
          const { parsedJson } = await AjaxHelper.post(
            projectLoaderScript,
            formData,
            { parseJson: true }
          );

          if (parsedJson.success && parsedJson.data) {
            const { project } = gantt;

            // Import the uploaded mpp-file data (will make a new project and assign it to the gantt)
            await importer.importData(parsedJson.data);

            // destroy old project
            project.destroy();

            // set the view start date to the loaded project start
            gantt.setStartDate(gantt.project.startDate);
            await gantt.scrollToDate(gantt.project.startDate, {
              block: "start",
            });

            input.clear();

            // remove "Importing project ..." mask
            gantt.unmaskBody();

            Toast.show("File imported successfully!");
          } else {
            onError(`Import error: ${parsedJson.msg}`);
          }
        } catch (error) {
          onError(`Import error: ${error.error?.message || "Import failed"}`);
        }
      },
    });

    const { input, sendBtn } = gantt.widgetMap;

    const importer = new Importer({
      // gantt panel reference
      gantt,
      // Columns that should be shown by the Gantt
      // if the imported file does not provide columns list
      defaultColumns: [
        { type: "name", field: "name", width: 250 },
        { type: "addnew" },
      ],
    });

    function onError(text) {
      gantt.unmaskBody();
      console.error(text);

      Toast.show({
        html: text,
        color: "b-red",
        style: "color:white",
        timeout: 3000,
      });
    }
  };

  useEffect(() => {
    if (
      userData &&
      userData.instanceUrl &&
      userData.token &&
      userData.projectId
    ) {
      console.log("mount gantt import ");
      initialiseGantt();
    }
  }, [userData]);

  return (
    <div>
      {loading && (
        <div className="gantt-loading-state">
          <CircularProgress />
        </div>
      )}

      <Grid container>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", height: `calc(100vh - 64px)` }}
          id="gantt-import-container"
        ></Grid>
      </Grid>
    </div>
  );
};

export default GanttImport;
