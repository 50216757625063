// dialy  report apis
import { fetchUrl } from "../../../Urls";
import axios from "axios";

export const fetchDailyReports = async (projectId) => {
   //let filqury = `Date__c >= TODAY`;
  return await axios
    .get(`${fetchUrl}/get_daily_report/${projectId}?`)
    .then((res) => {
      if (res?.status === 200) {
        return res.data || [];
      }
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const createNewReport = async (queryObj, saveObj) => {
  return await axios
    .post(`${fetchUrl}/recSave/Daily_Report__c${queryObj}`, saveObj)
    .then((res) => {
      if (res?.status === 200) {
        return res.data || {};
      }
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};
export const completeReport = async (reportId , totWorker) => {
  let reqBody = {Total_Workers__c : totWorker};
  return await axios
    .post(`${fetchUrl}/complete/report/${reportId}`,reqBody)
    .then((res) => {
      if (res?.status === 200) {
        return res || {};
      }
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const updateReportStatus = async (reportId, reason) => {
  return await axios
    .post(`${fetchUrl}/recSave/Daily_Report__c`, {
      recObj: { Id: reportId, Exception_Reason__c: reason, Status__c: "Draft" },
    })
    .then((res) => {
      if (res?.status === 200) {
        return res || {};
      }
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const cloneReportApi = (payload) => {
  return axios.post(`${fetchUrl}/clone-dailyreport`, payload);
};

export const importReportApi = (payload) => {
  return axios.post(`${fetchUrl}/import-dailyreport`, payload);
};
