import { filter, find, keys, get } from "lodash";

export const processResourcesByCrew = (data, allEvents, assignedEventsData) => {
  const result = {};
  keys(data).forEach(k => {
    if(data[k].length > 0) {
      result[k] = {
        name: k === 'projectResources' ? 'Unassigned': data[k][0].Crew__r.Name,
        resources: [],
        events: []
      }
      // push resources
      data[k].forEach(d => {
        result[k].resources.push({
          id: d.Contact__c,
          name: d.Contact__r.Name,
          role: d.Contact__r.Primary_Role__c
        });
      });

      // filter and push events
      const resourceIds = result[k].resources.map(r => r.id);
      const filteredEvents = filter(assignedEventsData, (d) => resourceIds.includes(d.Contact__c));
      filteredEvents.forEach(e => {
        result[k].events.push({
          id: e.Id,
          resourceId: e.Contact__c,
          name: get(find(allEvents, { Id: e.WBS__c}), 'WBS_Name__c', e.WBS__c),// get name from all tasks TBD
          startDate: e.Task_Date__c,
          duration: e.Scheduled_Hours__c,
          durationUnit: 'h'
        });
      });
    }
  });

  return result;

}
