import React from "react";
import { useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import moment from "moment";
import { Button } from "@mui/material";

const ReportsGrid = ({ reports, handleViewSelect }) => {
  const columns = useMemo(() => [
    // {
    //   accessorKey: "Id",
    //   header: "ID",
    // },
    {
      accessorKey: "Name",
      header: "Name",
      Cell: ({ row }) => (<Button sx={{ textTransform: "none" , padding:'2px'}} onClick={e => handleViewSelect(row.original)}>{row.original.Name}</Button>),
    },
    {
      accessorKey: "Id",
      header: "Day of week",
      Cell: ({ row }) => (moment(row.original.Date__c).format("dddd")),
    },
    {
      accessorKey: "Date__c",
      header: "Date",
      Cell: ({ row }) => (moment(row.original.Date__c).format("MM/DD/YYYY")),
    },
    // {
    //   accessorKey: "Project__r.Name",
    //   header: "Project",
    // },
    {
      accessorKey: "Status__c",
      header: "Status",
    },
  ]);

  const table = useMaterialReactTable({
    columns,
    data: reports,
    enableRowSelection: false, //enable some features
    enableColumnOrdering: false, //enable a feature for all columns
    enableGlobalFilter: false, //turn off a feature
    enableDensityToggle: false,
    enableColumnFilters: false,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    enableHiding: false,
    enablePagination: false,
    initialState: { showColumnFilters: true ,density: 'compact' },
    enableTopToolbar: false,
    enableBottomToolbar: false,
    muiTableBodyCellProps: ({ column }) => ({
      //conditionally style pinned columns
      sx: {
        padding: '2px',paddingLeft : '0.3rem'
      },
    }),
  });

  return <MaterialReactTable table={table} />;
};

export default ReportsGrid;
