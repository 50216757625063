import React, { useState } from "react";
import { Box, Tabs, Tab } from "@mui/material";

import "./resources.scss";
import ResourceTab from "./ResourceTab";
import CrewsTab from "./CrewsTab";

const ResourceTabs = (props) => {
  const {
    createdResources,
    crewGroups,
    handleDelete,
    handleCrewEdit,
    handleCrewDelete,
    selectedFromCrews,
    handleCrewCheckBox,
  } = props;

  return (
    <Box sx={{ width: "100%" }}>
      <CrewsTab
        handleCrewEdit={handleCrewEdit}
        handleCrewDelete={handleCrewDelete}
        crewResources={createdResources}
        crewGroups={crewGroups}
        handleDelete={handleDelete}
        selectedFromCrews={selectedFromCrews}
        handleCrewCheckBox={handleCrewCheckBox}
      />
      <ResourceTab
        resources={createdResources["projectResources"]}
        handleDelete={handleDelete}
        selectedFromCrews={selectedFromCrews}
        handleCrewCheckBox={handleCrewCheckBox}
      />
      <Box p={2}></Box>
    </Box>
  );
};

export default ResourceTabs;
