/**
 * Application configuration
 *
 * Please note that this example uses the Bryntum Scheduler Pro, which is licensed separately.
 */

import { DateHelper, ProjectModel } from "@bryntum/gantt";

const getProjectConfig = () => {
  return new ProjectModel({
    calendar: "allday",
    calendarsData: calendars,
    hoursPerDay: 8,
    daysPerWeek: 5,
    // daysPerMonth: 20,
    // skipNonWorkingTimeWhenSchedulingManually: true,
    resourceStore: {
      tree: true,
    },
  });
};

const hideCellMenuItems = {
  cut: false,
  copy: false,
  paste: false,
};

const colRenderer = (data, fieldName) => {
  const { record } = data;
  if (record.isLeaf) {
    record.readOnly = false;
  } else {
    record.readOnly = true;
  }
  return record[fieldName];
};

// Gantt configuration
const ganttConfig = {
  scrollTaskIntoViewOnCellClick: true,
  dependencyIdField: "wbsValue",
  flex: 1,
  rowHeight: 45,
  barMargin: 10,
  cls: "gantt-data-table-panel",
  viewPreset: {
    base: "weekAndDayLetter",
    id: "myhourAndDay",
  },
  fillTicks: true,
  subGridConfigs: {
    locked: {
      flex: 3,
      // maxWidth: 500,
    },
    normal: {
      // minWidth: 400,
      flex: 4,
    },
  },
  // columnLines: false,
  // rowLines: true,

  columns: [
    {
      id: "wbs-num",
      type: "wbs",
      text: "WBS",
      field: "wbsValue",
      readOnly: true,
      alwaysWrite: true,
      cellMenuItems: hideCellMenuItems,
    },
    {
      id: "wbs-name",
      type: "name",
      text: "Task Name",
      cellMenuItems: hideCellMenuItems,
      // renderer: (data) => colRenderer(data, "name"),
    },
    {
      id: "wbs-startDate",
      type: "startdate",
      text: "Start date",
      field: "startDate",
      cellMenuItems: hideCellMenuItems,
    },
    {
      id: "wbs-enddate",
      type: "enddate",
      text: "End date",
      field: "endDate",
      cellMenuItems: hideCellMenuItems,
    },
    {
      id: "wbs-duration",
      type: "duration",
      text: "Duration",
      readOnly: false,
      cellMenuItems: hideCellMenuItems,
    },
    {
      id: "wbs-percentdone",
      type: "percentdone",
      text: "% Completed",
      showCircle: false,
      readOnly: false,
      cellMenuItems: hideCellMenuItems,
    },
    {
      id: "wbs-effort",
      type: "effort",
      text: "Effort",
      readOnly: false,
      cellMenuItems: hideCellMenuItems,
    },
    {
      id: "wbs-actualHours",
      type: "actualHoursColumn",
      text: "Actual Hours",
      readOnly: true,
      cellMenuItems: hideCellMenuItems,
    },
    {
      id: "wbs-resourceassignment",
      type: "resourceassignment",
      text: "Assigned Resources",
      showAvatars: true,
      readOnly: true,
      cellMenuItems: hideCellMenuItems,
    },
    {
      id: "wbs-predecessor",
      type: "predecessor",
    },
    {
      id: "wbs-constraintType",
      type: "constrainttype",
    },
    {
      id: "wbs-constraintDate",
      type: "constraintdate",
    },
    {
      id: "add-new",
      type: "addnew",
    },
  ],
};

// region Label configs
const topLabel = {
    field: "name",
  },
  bottomLabel = {
    field: "startDate",
    renderer({ taskRecord }) {
      return DateHelper.format(taskRecord.startDate, "DD-MMM-YYYY");
    },
  };

// endregion

const ganttFeatures = {
  features: {
    // filterBar : {
    //   hidden : false,
    //   keyStrokeFilterDelay : 100
    // },
    filter: {
      pickerConfig: {
        fields: {},
      },
    },
    sort: "wbsValue",
    baselines: {
      disabled: true,
    },
    dependencyEdit: true,
    // filter: true,
    cellEdit: true,
    taskNonWorkingTime: true,
    labels: {
      top: null,
      bottom: null,
      before: null,
      after: topLabel,
    },
    columnLines: {
      disabled: true,
    },
    dependencies: {
      disabled: false,
    },
    parentArea: {
      disabled: true,
    },
    progressLine: {
      disabled: true,
    },
    rollups: {
      disabled: true,
    },
    rowReorder: {
      disabled: true,
      showGrip: false,
    },
    timeRanges: {
      showCurrentTimeLine: {
        name: DateHelper.format(new Date(), "YYYY-MM-DD"),
      },
    },
    fillHandle: false,
    taskEdit: {
      confirmDelete: false,
      items: {
        generalTab: {
          items: {
            // effort: {
            //   readOnly: true,
            // },
            // percentDone: {
            //   readOnly: true,
            // },
            duration: {
              readOnly: false,
            },
          },
        },
        predecessorsTab: true,
        successorsTab: true,
        resourcesTab: false,
        advancedTab: false,
        notesTab: true,
      },
    },
    taskMenu: {
      items: {
        add: {
          icon: "b-fa b-fa-add",
          disabled: true,
          // onItem({taskRecord}) {
          //   taskRecord.newTask = true;
          // }
          menu: {
            successor: false,
            predecessor: false
          }
        },
        deleteTask: {
          disabled: true,
        },
        convertToMilestone: {
          icon: "b-fa b-fa-diamond",
          disabled: true,
        },
        splitTask: false,
        indent: {
          icon: "b-fa b-fa-indent",
          disabled: true,
        },
        outdent: {
          icon: "b-fa b-fa-outdent",
          disabled: true,
        },
        linkTasks: false,
        unlinkTasks: false,
      },
      processItems({ items, taskRecord }) {
        if (!taskRecord.isLeaf) {
          delete items.deleteTask;
        }
      },
    },
  },
};

const getGanttConfig = () => {
  return {
    ...ganttConfig,
    startDate: DateHelper.add(
      DateHelper.parse(new Date(), "YYYY-MM-DD"),
      -20,
      "days"
    ),
  };
};

// Please note that this example uses the Bryntum Scheduler Pro, which is licensed separately.
// Scheduler Pro configuration
const schedulerConfig = {
  // project,
  minHeight: "5em",
  flex: 1,
  rowHeight: 30,
  barMargin: 10,
  eventColor: "gantt-green",
  useInitialAnimation: false,

  // dependenciesFeature: true,
  // percentBarFeature: true,
  features: {
    dependencies: true,
    percentBar: true,
    cellEdit: false,
    eventMenu: {
      items: {
        editEvent: false,
        copyEvent: false,
        cutEvent: false,
        deleteEvent: false,
        unassignEvent: false,
        splitEvent: false,
        renameSegment: false,
        eventColor: false,
      },
    },
  },

  subGridConfigs: {
    locked: {
      flex: 4,
    },
    normal: {
      flex: 8,
    },
  },

  columns: [
    {
      type: "resourceInfo",
      field: "name",
      text: "Resource",
      showEventCount: false,
      width: 230,
    },
    {
      text: "Role",
      field: "role",
      width: 100,
      editor: false,
      align: "left",
    },
    {
      text: "Assigned tasks",
      field: "events.length",
      width: 120,
      editor: false,
      align: "right",
      renderer: ({ value }) => `${value} task${value !== 1 ? "s" : ""}`,
    },
    {
      text: "Assigned work days",
      width: 160,
      editor: false,
      align: "right",
      renderer: ({ record }) =>
        record.events
          .map((task) => task.duration)
          .reduce((total, current) => {
            return total + current;
          }, 0) + " hours",
    },
  ],
};

const calendars = [
  {
    id: "allday",
    name: "All day",
    unspecifiedTimeIsWorking: false,
    intervals: [
      {
        name: "Available",
        recurrentStartDate: "every weekday at 09:00",
        recurrentEndDate: "every weekday at 17:00",
        isWorking: true,
      },
      // {
      //   recurrentStartDate: "at 09:00",
      //   recurrentEndDate: "at 17:00",
      //   isWorking: true,
      // },
      // {
      //   recurrentStartDate: "on Sat at 00:00",
      //   recurrentEndDate: "on Mon at 00:00",
      //   isWorking: false,
      // },
    ],
  },
];

const getShedulerConfig = () => {
  return {
    ...schedulerConfig,
    startDate: DateHelper.add(
      DateHelper.parse(new Date(), "YYYY-MM-DD"),
      -20,
      "days"
    ),
  };
};

export {
  getProjectConfig,
  getGanttConfig,
  getShedulerConfig,
  calendars,
  ganttFeatures,
};
