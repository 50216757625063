import React from "react";
import {
  Box,
  Typography,
  Tooltip,
  IconButton,
  Grid,
  Checkbox,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import "./resources.scss";
import DroppableBox from "../../components/drag-drop/DroppableBox";
import { getInitialsByWords } from "../../utils";
import { Draggable } from "react-beautiful-dnd";

import { SelectionCount } from "./index";

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const CrewsTab = ({
  crewResources,
  crewGroups,
  handleDelete,
  handleCrewEdit,
  handleCrewDelete,
  selectedFromCrews,
  handleCrewCheckBox,
}) => {
  return (
    <>
      <Grid container>
        {crewGroups.map((cg) => (
          <Grid
            item
            xs={12}
            key={cg.value}
            sx={{ marginBottom: "1rem", border: "1px solid #ccc" }}
          >
            <Typography
              variant="subtitle1"
              gutterBottom
              className="crew-group-box-heading"
            >
              {cg.label} {cg.accountableName && ` | ${cg.accountableName}`}
              <Tooltip arrow placement="top" title="Edit Crew Name">
                <IconButton
                  className="edit-btn"
                  size="small"
                  onClick={handleCrewEdit(cg)}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="top" title="Delete Crew">
                <IconButton
                  className="edit-btn"
                  size="small"
                  onClick={handleCrewDelete(cg)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Typography>
            <DroppableBox id={cg.value} additionalStyle={{minWidth: undefined , maxWidth : undefined , padding : 8}}>
              {crewResources[cg.value] &&
                crewResources[cg.value].length > 0 &&
                crewResources[cg.value].map((cr, index) => (
                  <div key={cr.Id} className="resource-div">
                    <Draggable
                      draggableId={`${cr.Contact__c}-${index}-${cr.Id}`}
                      crewId={`${cr.Id}`}
                      index={index}
                    >
                      {(provided, snapshot) => {
                        const shouldShowSelection =
                        snapshot.isDragging && selectedFromCrews.length > 1;
                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <>
                              <Box
                                key={cr.Id}
                                p={1}
                                className="resource-box"
                                sx={{
                                  background: selectedFromCrews.includes(
                                    cr.Contact__c
                                  )
                                    ? "#98f387"
                                    : "inherit",
                                }}
                              >
                                <Checkbox
                                  edge="start"
                                  tabIndex={-1}
                                  disableRipple
                                  sx={{
                                    padding: "0 6px",
                                    verticalAlign: "top",
                                  }}
                                  value={selectedFromCrews.includes(
                                    cr.Contact__c
                                  )}
                                  onChange={() =>
                                    handleCrewCheckBox(cr.Contact__c)
                                  }
                                />
                                <Typography
                                  variant="body1"
                                  className="resource-box-text"
                                >
                                  {cr.Contact__r.Name}
                                </Typography>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  sx={{
                                    display: "inline",
                                    background: "#ddd",
                                    padding: "2px 6px",
                                    borderRadius: "2px",
                                    marginLeft: "4px",
                                    verticalAlign: "top",
                                  }}
                                >
                                  {getInitialsByWords(
                                    cr.Contact__r.Primary_Role__c
                                  )}
                                  <span className="resource-group-actions">
                                    <Tooltip
                                      title="Delete"
                                      placement="top"
                                      arrow
                                    >
                                      <IconButton
                                        aria-label="delete"
                                        onClick={() => {
                                          handleDelete(cg.value, cr.Id);
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </span>
                                </Typography>
                              </Box>
                              {shouldShowSelection ? (
                                <SelectionCount>
                                  {selectedFromCrews.length}
                                </SelectionCount>
                              ) : (
                                ""
                              )}
                            </>
                          </div>
                        );
                      }}
                    </Draggable>
                  </div>
                ))}
            </DroppableBox>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default CrewsTab;
