import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Tooltip, IconButton } from "@mui/material";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";

const StyledBox = styled(Box)(() => ({
  display: "inline",
  position: "absolute",
  right: 0,
  top: "50%",
  transform: "translate(0, -50%)",
}));

const DailyReportToolbar = ({ handleAllTaskToggle }) => {
  return (
    <StyledBox>
      <Tooltip title="Expand">
        <IconButton onClick={() => handleAllTaskToggle(false)}>
          <KeyboardDoubleArrowDownIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Collapse">
        <IconButton onClick={() => handleAllTaskToggle(true)}>
          <KeyboardDoubleArrowUpIcon />
        </IconButton>
      </Tooltip>
    </StyledBox>
  );
};

export default DailyReportToolbar;
